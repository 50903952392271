@function get($color, $alpha: 1) {
    // If theme color, return CSS custom variable with same name.
    @if str-index("#{$color}", "theme-") == 1 {
        @return var(--#{$color});
    }

    // Check if color exists in map.
    @if map-has-key($colors, $color) == false {
        @error "Invalid color name: `#{$color}`.";
    }

    // Return color with alpha.
    $transparency: 1 - $alpha;
    @return transparentize(map-get($colors, $color), $transparency);
}

/* stylelint-disable color-no-hex, unit-disallowed-list */
$colors: (
    adobe-red: #f40f02,
    attention-border: #f6cb44,
    attention-fill: #ffe288,
    bookmark-border: #cbe4fc,
    bookmark-fill: #edf6ff,
    cta: #0c7fe8,
    critical-cta: #d3104c,
    debug: #eb4af4,
    disabled: #e5e5e5,
    divider-on-dark: #dddee2,
    divider-on-header: #d8d9db,
    divider: #ebebeb,
    error-border: #d3104c,
    error-fill: #fcf1f4,
    error-text: #d3104c,
    excerpt-background: #ebebed,
    folder: #f6ae2d,
    header-background: #f0efed,
    issuu-orange: #f26c5d,
    light-text-on-dark: rgb(255 255 255 / 0.4),
    light-text: #454545,
    menu-selection-background: #f5f4f2,
    main-background: #f9f9f9,
    message: #e6b428,
    message-border: #fbe5a9,
    message-fill: #fffdf9,
    microsoft-blue: #00a4ef,
    microsoft-green: #7fba00,
    microsoft-red: #f25022,
    microsoft-yellow: #ffb900,
    news-cta: #0c7fe8,
    news-fill: #f3f9ff,
    news-alt-fill: #f3fff9,
    news-alt-cta: #33a969,
    ok-green: #22b07d,
    overlay: rgb(0 0 0 / 0.32),
    // placeholder-text: #adadad,
    real-black: #000000,
    sbf-darker-blue: #253746,
    sbf-blue: #3c4e5c,
    sbf-dark-red: #8a1538,
    sbf-green: #14867f,
    sbf-red: #d3104c,
    selection-fill-on-dark: rgb(255 255 255 / 0.2),
    sidebar-background: #f0efed,
    small-button-fill: #ebebeb,
    swag: #33a994,
    swag-border: #d3eadf,
    swag-hover: #e8f4f0,
    swag-light-fill: #f6fcfa,
    swag-dark-fill: #eef9f5,
    // text: #000000,
    video: #1393cd,
    white: #ffffff,
    white-hover: #fafafb,
);
