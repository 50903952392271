/* stylelint-disable color-no-hex */
/* stylelint-disable value-keyword-case */
@use "@angular/material" as mat;

@include mat.core();

// Alter colors @ http://mcg.mbitson.com/#!?primary=%23d3104c&secondary=%2314867f&themename=sbf

$md-primary: (
    50 : #fae2ea,
    100 : #f2b7c9,
    200 : #e988a6,
    300 : #e05882,
    400 : #da3467,
    500 : #d3104c,
    600 : #ce0e45,
    700 : #c80c3c,
    800 : #c20933,
    900 : #b70524,
    A100 : #ffe1e5,
    A200 : #ffaeb8,
    A400 : #ff7b8b,
    A700 : #ff6275,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #e3f0f0,
    100 : #b9dbd9,
    200 : #8ac3bf,
    300 : #5baaa5,
    400 : #379892,
    500 : #14867f,
    600 : #127e77,
    700 : #0e736c,
    800 : #0b6962,
    900 : #06564f,
    A100 : #89fff3,
    A200 : #56ffee,
    A400 : #23ffe9,
    A700 : #09ffe6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$my-primary: mat.define-palette($md-primary);
$my-accent: mat.define-palette($md-secondary);

$my-theme: mat.define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
        )
    )
);

@include mat.all-component-themes($my-theme);
