@use "sass:color";

/* stylelint-disable color-no-hex */
$appThemes: (
    default: (
        action-panel-fill: #f5f5f5,
        "black": #000000,
        critical-cta: #d85757,
        cta: #0c7fe8,
        delete: #d85757,
        divider-on-dark: #dddee2,
        divider: #ebebeb,
        error: #d85757,
        header-fill: #f0efed,
        heading: #d3104c,
        input-border: #e5e5e5,
        input-error-border: #ffbbbb,
        item-selected-border: #d3eadf,
        item-selected-fill: #eef9f5,
        light-text: #454545,
        ok: #22b07d,
        permit-list-values: #575151,
        permit-list-heading: #C9C9C9,
        permit-list-background-id: #F9F9F9,
        permit-status-archiving: #fa9696,
        permit-status-completed: #d23a67,
        permit-status-expired: #efb90e,
        permit-status-ongoing: #11c358,
        permit-status-planned: #29d9ce,
        permit-status-prepared: #b14ad6,
        permit-status-preparing: #eb459f,
        permit-status-signing: #afeeae,
        placeholder-text: #adadad,
        plate-dark-fill: #fbfbfb,
        plate-fill: #ffffff,
        plate-hover-fill: color.scale(#ffffff, $lightness: -1.75%),
        primary: #d3104c,
        progress-bar-background: #e5e5e5,
        signature-border: #666666,
        swag: #33a994,
        text-inverted: #ffffff,
        text: #000000,
    )
);

@use "base" with ($themes: $appThemes);
