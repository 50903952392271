@use "modules/button";
@use "modules/color";
@use "modules/icon";
@use "modules/media";
@use "modules/scroll";
@use "modules/shadow";
@use "modules/text";
@use "modules/variables";

@mixin narrow($mobileStaticHeight: false) {
    @include _modal(narrow, $mobileStaticHeight);
}

@mixin wide($mobileStaticHeight: false) {
    @include _modal(wide, $mobileStaticHeight);
}

@mixin _content {
    @include media.mobile-layout {
        padding: 2rem 2rem;
    }

    @include media.desktop-layout {
        @include scroll.thin;
        overflow-y: scroll;

        padding: 4rem 5rem;
    }

    @include text.page-body;

    flex-grow: 1;

    text-align: left;
}

@mixin _action-panel {
    @include media.desktop-layout {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        padding: 1.5rem 3rem;

        background-color: color.get(theme-action-panel-fill);
        border-top: 0.1rem solid color.get(theme-divider);
    }

    @include media.mobile-layout {
        position: fixed;
        z-index: 30;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        height: 8rem;

        background-color: color.get(theme-action-panel-fill);
        border-top: 0.1rem solid color.get(theme-divider);

        ::ng-deep {

            button {
                width: auto;
            }
        }
    }

    /* stylelint-disable-next-line declaration-no-important */
    position: sticky !important;
    right: 0;
    bottom: 0;
    left: 0;

    flex-shrink: 0;
}

@mixin _modal($modal-type, $mobileStaticHeight) {
    @include media.desktop-layout {
        max-width: 80vw;
        max-height: 80vh;
    }

    @include media.mobile-layout {
        @if $mobileStaticHeight {
            height: 100vh;
        }
    }

    display: flex;
    flex-direction: column;

    @if $modal-type == narrow {
        @include media.desktop-layout {
            width: 50rem;
        }
    }
    @else if $modal-type == wide {
        @include media.desktop-layout {
            width: 72rem;
        }
    }
    @else {
        @error "Unknown modal type: \"#{$modal-type}\".";
    }

    // &.busy {
    //     @include icon.loader;
    //     position: relative;

    //     &::before {
    //         content: "";

    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //         bottom: 0;
    //         left: 0;

    //         background-color: black;

    //         opacity: 0.2rem;
    //     }
    // }

    &__content,
    .modal-content {
        @include _content;
    }

    &__actions,
    .modal-actions {
        @include _action-panel;
    }
}


/* Modal global styles */

.cdk-global-overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;
}

.ha-modal {
    @include shadow.bottom-plate;

    @include media.desktop-layout {
        overflow: hidden;

        max-height: 80vh;

        border-radius: 0.4rem;
    }

    @include media.mobile-layout {
        right: 0;
        bottom: 0;
        left: 0;

        max-height: 100vh;

        animation-name: slide-up;
        animation-duration: 200ms;
    }

    /* stylelint-disable-next-line declaration-no-important */
    position: fixed !important;
    z-index: 50;

    display: flex;
    flex-direction: column;
    overflow-y: auto;

    background-color: color.get(white);

    @keyframes slide-up {

        from {
            top: 100vh;
        }

        to {
            bottom: 0;
        }
    }
}

.ha-modal-backdrop {
    position: fixed;
    z-index: 40;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: color.get(theme-black);

    opacity: 0;

    animation-name: dim-in;
    animation-duration: 200ms;

    &.cdk-overlay-backdrop-showing {
        opacity: 0.4;
    }

    @keyframes dim-in {

        from {
            opacity: 0;
        }

        to {
            opacity: 0.4;
        }
    }
}
