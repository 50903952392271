@use "modules/color";
@use "modules/icon";
@use "modules/media";
@use "modules/scroll";

/* Font mixins */

@mixin body-font($size: null, $line-height: null, $weight: null, $color: null) {
    @include _font(body, $size, $line-height, $weight, $color);
}

@mixin heading-font($size: null, $line-height: null, $weight: null, $color: null) {
    @include _font(heading, $size, $line-height, $weight, $color);
}

@mixin page-body() {
    @include body-font(1.6rem, 2.4rem, 300, theme-text);
}


/* Heading mixins */

@mixin page-heading {
    @include h1;

    margin-bottom: 2rem;
}

@mixin h1 {
    @include heading-font(2.4rem, 3rem, 600, theme-heading);
}

@mixin h2 {
    @include heading-font(2rem, 2.6rem, 500, theme-heading);
}

@mixin sidebar-heading {
    @include heading-font(1.8rem, 2.4rem, 600, theme-text);
    margin-bottom: 2.5rem;
}

@mixin section-heading {
    @include heading-font(1.6rem, 2.2rem, 500, light-text);

    display: flex;
    flex-direction: row;
    align-items: baseline;

    width: 100%;
    margin-top: 4rem;
    margin-bottom: 3rem;

    &::after {
        content: "";

        flex-grow: 1;
        height: 0.1rem;
        margin-left: 1.5rem;

        background-color: color.get(divider-on-dark);
    }
}


/* Anchor mixins */

@mixin anchor($preserve-parent-font: false, $size: 1.6rem) {
    cursor: pointer;

    text-decoration: underline;

    @if $preserve-parent-font == false {
        @include body-font($size, 2.4rem, 300, theme-text);
    }
}

@mixin anchor-with-icon($icon, $icon-style, $icon-color: inherit, $size: 1.6rem, $align-center: false) {
    @include anchor($size: $size);
    @include icon.before($icon, $icon-style, $size, 1rem, 0, $icon-color);

    @if $align-center {
        display: block;

        text-align: center;
    }
}

@mixin anchor-label {
    @include heading-font(1.5rem, 2.2rem, 600, cta);

    margin-bottom: 0.7rem;
}


/* Alerts */

@mixin alert($alert-type, $icon-override: null) {
    @include body-font(1.4rem, 2rem, 400, theme-text);
    @include html-from-translation;

    position: relative;

    margin-top: 2.5rem;
    padding: 1.5rem 3rem 1.5rem 6rem;

    background-color: color.get(white);
    border: 0.1rem solid color.get(theme-input-border);
    border-radius: 0.3rem;
    border-left: 0.5rem solid transparent;

    text-align: left;

    &::before {
        position: absolute;
        top: 50%;
        left: 2rem;

        transform: translateY(-50%);
    }

    @if $alert-type == info {
        // @include icon.floating-before(info-circle, solid, 2.4rem, 2rem, 0, cta);

        border-left-color: color.get(cta);

        &::before {
            @include icon.here(info-circle, solid, 2rem);

            color: color.get(cta);
        }
    }
    @else if $alert-type == warning {
        // @include icon.floating-before(exclamation-circle, solid, 2.4rem, 2rem, 0, attention-border);

        border-left-color: color.get(attention-border);

        &::before {
            @include icon.here(exclamation-circle, solid, 2rem);

            color: color.get(attention-border);
        }
    }
    @else if $alert-type == error {
        // @include icon.floating-before(times-circle, solid, 2.4rem, 2rem, 0, error-border);

        border-left-color: color.get(theme-error);

        &::before {
            @include icon.here(times-circle, solid, 2rem);
            color: color.get(theme-error);
        }
    }
    @else if $alert-type == comment {
        // @include icon.floating-before(comment-quote, solid, 2.4rem, 2rem, 0, error-border);

        border-left-color: color.get(theme-swag);

        &::before {
            @include icon.here(comment-quote, solid, 2rem);
            color: color.get(theme-swag);
        }
    }
    @else {
        @error "Unknown alert type: \"#{$alert-type}\".";
    }

    @if $icon-override {
        @include icon.replace-before($icon-override, solid, 2rem);
    }
}

@mixin counter($color, $fill) {
    @include body-font(1.2rem, 2.2rem, 600);
    display: flex;
    align-content: center;
    justify-content: center;

    box-sizing: border-box;
    width: 2.2rem;
    height: 2.2rem;
    margin-left: 0.8rem;
    padding-right: 0.1rem;

    background-color: color.get($fill);
    border-radius: 50%;

    color: color.get($color);
}


/* Tooltip */

@mixin tooltip($position) {
    position: relative;

    &::after {
        @include heading-font(1.1rem, 1.4rem, 600);

        content: attr(data-tooltip);

        position: absolute;

        @if $position == right {
            right: -1rem;

            transform: translateX(100%);
        }
        @else if $position == top {
            top: -1rem;

            transform: translateY(-100%);
        }
        @else {
            @error "Unknown button position: \"#{$position}\".";
        }


        padding: 0.3rem 0.5rem;

        background-color: color.get(theme-cta);
        border-radius: 0.5rem;

        color: color.get(theme-text-inverted);
        opacity: 0;

        text-transform: uppercase;
        white-space: nowrap;

        transition: opacity 100ms ease-in-out;
    }

    &:hover {

        &::after {
            opacity: 1;
        }
    }
}


/* Form hints */

@mixin form-hint {
    @include media.mobile-layout {
        width: 100%;
        padding: 2rem 1.8rem 0 1.8rem;

        text-align: center;
    }

    @include body-font(1.4rem, 1.8rem, 300, light-text);

    padding: 2rem 0 0.5rem 0.3rem;

    font-style: italic;

    ::ng-deep em {
        color: color.get(theme-text);

        font-weight: 600;
    }
}

/* Overflow mixins */

@mixin max-lines($number-of-lines) {
    overflow: hidden;

    text-overflow: ellipsis;

    /* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix, order/properties-order */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $number-of-lines;
    /* stylelint-enable value-no-vendor-prefix */
}

/* Html formatting */

/* stylelint-disable no-descending-specificity */
@mixin html-from-translation($anchor-color: null) {

    ::ng-deep {

        em {
            font-weight: 600;
        }

        p {

            + p {
                margin-top: 1.6rem;
            }

            + h2 {
                margin-top: 2.4rem;
            }

            + h3 {
                margin-top: 2rem;
            }
        }

        h2 {
            @include heading-font(2rem, 2.6rem, 500, sbf-red);

            + p {
                margin-top: 1.4rem;
            }
        }

        a {
            @include anchor(true);

            @if $anchor-color {
                color: color.get($anchor-color);
            }
        }
    }
}
/* stylelint-enable no-descending-specificity */

/* stylelint-disable no-descending-specificity, selector-max-compound-selectors, selector-max-type */
@mixin html-from-editor {

    ::ng-deep {

        > p {
            @include body-font(1.6rem, 2.4rem, inherit);
            margin-top: 1.6rem;

            strong {
                font-weight: 600;
            }

            em {
                font-style: italic;
            }

            &:first-child {
                margin-top: 0;
            }
        }

        /* Heading level 2 */
        > h2 {
            @include heading-font(2rem, 2.6rem, 500, sbf-red);
        }

        /* Heading level 3 */
        > h3 {
            @include heading-font(1.7rem, 2.4rem, 600, theme-text);
        }

        /* --- BEGIN Adjacent element spacing --- */

        > p + h2,
        > ul + h2,
        > ol + h2 {
            margin-top: 2.4rem;
        }

        > p + h3,
        > ul + h3,
        > ol + h3 {
            margin-top: 2rem;
        }

        > h2 + h3 {
            margin-top: 1.4rem;
        }

        > h2 + p,
        > h3 + p {
            margin-top: 1.4rem;
        }

        /* --- END Adjacent element spacing --- */

        > img {
            max-width: 100%;
            height: auto;
        }

        > p a,
        > ul li a,
        > ol li a {
            @include anchor(true);
        }

        > .table-wrapper {
            @include media.mobile-layout {
                margin: 1.4rem -2rem 1.4rem 0;
            }

            @include media.desktop-layout {
                @include scroll.thin;
                margin: 1.4rem 0;
            }

            padding-right: 2rem; // Because negative margin
            overflow-x: auto;
            white-space: nowrap;

            > table {
                width: auto;
                min-width: 100%;
                border: 0.1rem solid color.get(theme-input-border);

                td,
                th {
                    padding: 0.8rem;
                    border: 0.1rem solid color.get(theme-input-border);
                }

                th {
                    font-weight: 600;
                }
            }
        }

        // Create locla mixin to deal with nesting of lists.
        // Consider ol nested inside an ul and vice versa.

        > ul {
            margin: 1rem 0;
            padding-left: 4.6rem;

            li {
                @include body-font(1.6rem, 2.4rem, inherit);

                position: relative;

                &::before {
                    @include heading-font(1.25em, 2.4rem, 600, sbf-red);

                    content: "•";

                    position: absolute;
                    left: -2.5rem;
                }
            }

            // Nested bullet lists.
            ul {
                margin: 0.4rem 0;

                // Nested bullets.
                li::before {
                    content: "◦";

                    font-size: 2.2rem;
                }
            }

            // Nested numbered lists.
            ol {

                & > li {

                    &::before {
                        content: none;
                    }
                }
            }
        }

        > ol {
            margin: 1rem 0;
            padding-left: 3.8rem;

            list-style-type: decimal;

            li {
                @include body-font(1.6rem, 2.4rem, inherit);

                padding-left: 0.8rem;

                &::marker {
                    @include heading-font(0.9em, $weight: 600, $color: sbf-red);
                }
            }

            // Nested numbered lists.
            ol {
                margin: 0.4rem 0;

                list-style-type: lower-alpha;

                ol {
                    margin: 0.4rem 0;

                    list-style-type: lower-roman;
                }
            }

            // Nested bullet lists.
            ul {

                & > li {
                    padding-left: 0;

                    list-style: none;
                }
            }
        }

        // List item margins and line heights, regardless of list type.
        > ol,
        > ul {

            li {
                // margin-bottom: 1.5rem;

                & + li {
                    margin-top: 0.6rem;
                }

                strong {
                    font-weight: 600;
                }

                em {
                    font-style: italic;
                }
            }

            ul,
            ol {
                padding-left: 2.4rem;

                li + li {
                    margin-top: 0.4rem;
                }
            }
        }

        > i,
        > em {
            font-style: italic;
        }
    }
}
/* stylelint-enable selector-max-compound-selectors, selector-max-type */

/* Private mixins */

@mixin _font($type, $size, $line-height: null, $weight: null, $color: null) {
    @if unitless($size) { @error "Size value \"#{$size}\" is not in rem."; }

    @if $type == heading {
        font-family: "Poppins", sans-serif;
    }
    @else if $type == body {
        font-family: "Open Sans", sans-serif;
    }

    font-size: $size;

    @if $weight {
        font-weight: $weight;
    }

    @if $line-height {
        @if unitless($line-height) { @error "Line height value \"#{$line-height}\" is not in rem."; }
        line-height: $line-height;
    }

    @if $color {
        color: color.get($color);
    }
}
