@use "modules/color";
@use "modules/icon";
@use "modules/media";
@use "modules/shadow";
@use "modules/text";
@use "modules/variables";

/* Button mixins */
@mixin regular($button-type, $no-stretch: false) {
    @include _regular-button($button-type, null, null, $no-stretch);
}

@mixin regular-icon($button-type, $icon, $icon-style, $no-stretch: false) {
    @include _regular-button($button-type, $icon, $icon-style, $no-stretch);
}

@mixin small {
    @include _small-button(null, null);
}

@mixin small-icon($icon, $icon-style) {
    @include _small-button($icon, $icon-style);
}

@mixin toolbar {
    @include _toolbar-button(null);
}

@mixin toolbar-with-icon($icon, $icon-style: null, $icon-color: null) {
    @include _toolbar-button($icon, $icon-style, $icon-color);
}

@mixin loading {
    @include icon.replace-after(spinner-third, regular);

    pointer-events: none;

    opacity: 0.7;

    &::after {
        animation-name: spin;
        animation-delay: -100ms;
        animation-duration: 750ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}

/* Desktop action row */
@mixin desktop-action-row {
    @include media.desktop-layout {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        margin-top: 4rem;
        padding-top: 4rem;

        border-top: 0.1rem solid color.get(divider-on-dark);
    }
}

/* Mobile panel */
@mixin mobile-panel {
    @include media.mobile-layout {
        position: fixed;
        z-index: 30;
        right: 0;
        bottom: 0;
        left: 0;

        align-items: center;
        justify-content: space-between;

        height: 8rem;

        background-color: color.get(white);
        border-top: solid 0.5rem color.get(sidebar-background);

        animation-name: slide-up;
        animation-duration: 500ms;

        ::ng-deep {

            button {
                width: auto;
            }
        }
    }

    @include media.desktop-layout {
        margin-top: 3rem;
    }

    display: flex;
    flex-direction: row;
    @keyframes slide-up {

        from {
            bottom: -8rem;
        }

        to {
            bottom: 0;
        }
    }
}

@mixin floating-mobile($color, $icon, $active-icon: null) {
    @include media.mobile-layout {
        @include icon.before($icon, regular, 2rem, 0, 0, white);

        @include shadow.plate();

        position: fixed;
        z-index: 14;
        right: 1rem;
        bottom: variables.get(mobile-menu-height) + 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 5rem;
        height: 5rem;

        background-color: color.get($color);
        border: solid 0.2rem color.get($color);
        border-radius: 50%;

        &.active {
            @include media.mobile-layout {
                display: none;
            }

            @include icon.replace-before($active-icon, regular, 2rem, $color);

            background-color: color.get(white);
        }
    }
}

/// Styles a button in a mobile panel. This mixin will only be applied to mobile layout.
///
/// @param {string} $position
///     The button's position in the panel: __left__, __center__ or __right__.
/// @param {string} $button-type
///     The button's display type: _primary_, _critical_, __secondary__ or __disabled__.
/// @param {string} $icon
///     The button's icon if the default is not desirable.
@mixin mobile-panel-button($position, $button-type, $icon: null) {
    @include media.mobile-layout {
        @include text.heading-font(1.6rem, 2.2rem, 500);
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;

        cursor: pointer;
        @if $position == left {
            //$icon = $icon or 'long-arrow-left'
            @include icon.before(($icon or long-arrow-left), regular, 1.6rem, 1.7rem);
            padding-left: 3rem;
        } @else if $position == right {
            @include icon.after(($icon or long-arrow-right), regular, 1.6rem, 1.7rem);
            padding-right: 3rem;
        } @else if $position == center {
            @include icon.before(($icon or times), regular, 1.6rem, 1.7rem);
            margin: auto;
        } @else {
            @error "Unknown button position: \"#{$position}\".";
        }
        @if $button-type == primary {
            color: color.get(cta);
        } @else if $button-type == critical {
            color: color.get(theme-critical-cta);
        } @else if $button-type == secondary {
            color: color.get(light-text);
        } @else if $button-type == disabled {
            pointer-events: none;

            color: color.get(disabled);
        } @else {
            @error "Unknown button type: \"#{$button-type}\".";
        }
    }
}

/* Internal button mixins */
@mixin _regular-button($button-type, $icon, $icon-style, $no-stretch) {
    @include _general-button-properties;
    @include media.desktop-layout {
        @include text.heading-font(1.4rem, 1.8rem, 500);
        height: 4.8rem;
    }
    @include media.mobile-layout {
        @include text.heading-font(1.5rem, 2.2rem, 500);
        height: 5.8rem;
    }
    @if $icon != null {
        @include icon.after($icon, $icon-style, 1.6rem, 1.5rem);
        position: relative;
    }
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;

    @if $no-stretch == false {
        @include media.mobile-layout {
            width: 100%;
        }
    }
    @if $button-type == primary {
        @include shadow.button;
        @include _button-hover;
        padding: 0 3rem;

        background-color: color.get(theme-cta);

        color: color.get(white);

        &:active {
            padding: 0 3rem;

            color: color.get(white, 0.9);
        }
    } @else if $button-type == critical {
        @include shadow.button;
        @include _button-hover;
        padding: 0 3rem;

        background-color: color.get(theme-critical-cta);

        color: color.get(white);

        &:active {
            padding: 0 3rem;

            color: color.get(white, 0.9);
        }
    } @else if $button-type == secondary {
        @include shadow.button;
        @include _button-hover;
        padding: 0 3rem;

        background-color: color.get(white);

        color: color.get(theme-text);
    } @else if $button-type == disabled {
        // Remove this button type and use .disabled instead.
        padding: 0 3rem;

        background-color: color.get(disabled);
        box-shadow: none;

        pointer-events: none;

        color: color.get(white);
    } @else if $button-type == borderless {
        margin: 0 2rem;
        padding: 0 1rem;

        color: color.get(light-text);

        text-decoration: underline;

        &:hover {
            color: color.get(theme-text);
        }
    } @else {
        @error "Unknown button type #{$button-type}.";
    }

    &.disabled {
        background-color: color.get(disabled);
        box-shadow: none;

        pointer-events: none;

        color: color.get(white);
    }

    &::after {
        display: flex;
        flex-direction: row;
        justify-content: center;

        width: 1.8rem;
    }
}

@mixin _button-hover {

    &:hover {
        position: relative;
        z-index: 1;

        &::before {
            content: "";

            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-color: color.get(theme-button-hover);
            border-radius: inherit;
            // opacity: 0.08;
        }
    }
}

@mixin _toolbar-button($icon: null, $icon-style: null, $icon-color: null) {
    @include _general-button-properties;
    @include text.heading-font(1.2rem, 1.6rem, 400, theme-text);

    @if $icon != null {
        @include icon.before($icon, $icon-style, 1.3rem, 0.7rem);
        position: relative;

        @if $icon-color != null {
            @include icon.replace-before($color: $icon-color);
        }
    }

    height: 3.6rem;
    padding: 0 1rem;

    background-color: color.get(theme-plate-fill);
    border: 0.1rem solid color.get(theme-input-border);
    border-radius: 0.3rem;

    &:hover {
        background-color: color.get(theme-plate-hover-fill);
    }
}

@mixin _small-button($icon, $icon-style) {
    @include _general-button-properties;
    @include text.body-font(1.6rem, 2.2rem, 300, theme-text);

    @if $icon != null {
        @include icon.after($icon, $icon-style, 1.6rem, 1rem);
        position: relative;
    }

    height: 4.2rem;
    padding: 0 1.5rem;

    background-color: color.get(small-button-fill);
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;

    color: color.get(theme-text);
}

@mixin _general-button-properties {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    max-width: 100%;

    cursor: pointer;

    white-space: nowrap;
    user-select: none;
}
