/* stylelint-disable unit-disallowed-list */

/* Responsive media queries */

@mixin this-up($min-width) {
    @if unit($min-width) != "px" { @error $px-validation-message; }

    @media screen and (min-width: $min-width) {
        @content;
    }
}

@mixin this-down($max-width) {
    @if unit($max-width) != "px" { @error $px-validation-message; }

    @media screen and (max-width: $max-width) {
        @content;
    }
}

@mixin between($min-width, $max-width) {
    @if unit($min-width) != "px" or unit($max-width) != "px" { @error $px-validation-message; }

    @media screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}

@mixin desktop-layout {
    @media screen and (min-width: $desktop-layout-min) {
        @content;
    }
}

@mixin mobile-layout {
    @media screen and (max-width: $mobile-layout-max) {
        @content;
    }
}

@mixin mobile-layout-portrait {
    @media screen and (max-width: $mobile-layout-max) and (orientation: portrait) {
        @content;
    }
}

@mixin mobile-layout-landscape {
    @media screen and (max-width: $mobile-layout-max) and (orientation: landscape) {
        @content;
    }
}

@mixin sidebar-visible {
    @media screen and (min-width: $sidebar-visible-min) {
        @content;
    }
}

@mixin sidebar-hidden {
    @media screen and (min-width: $desktop-layout-min)  and (max-width: $sidebar-hidden-max) {
        @content;
    }
}

@mixin hide-on-desktop {
    @include desktop-layout {
        display: none;
    }
}

@mixin hide-on-mobile {
    @include mobile-layout {
        display: none;
    }
}

/* Breakpoint variables */

$mobile-layout-max: 819px;
$desktop-layout-min: 820px;
$sidebar-hidden-max: 1276px;
$sidebar-visible-min: 1277px;

/* Validation messages */

$px-validation-message: "Value must be specified in pixels.";
