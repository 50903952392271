* {
    margin: 0;
    padding: 0;

    margin-block-end: 0;

    margin-block-start: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

*::-ms-clear {
    display: none;
}

html {
    box-sizing: border-box;

    /* This makes 1rem = 10px */
    font-size: 62.5%;
}

:focus {
    outline: none;
}

ul {
    list-style: none;
}

a {
    color: inherit;

    text-decoration: none;
}

button {
    background-color: transparent;
    border: none;
    outline: 0;

    text-transform: none;

    &:focus {
        outline: none;
    }
}

/* stylelint-disable-next-line font-weight-notation */
strong { font-weight: unset; }
em { font-style: unset; }

mark {
    background-color: transparent;

    color: inherit;
}

hr {
    border-style: solid;
    border-width: 0;
}

// table {
//     border-collapse: collapse;
//     border-spacing: 0;

//     th,
//     td {
//         text-align: left;
//     }
// }


table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;

    border: 0;

    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-align: inherit;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
