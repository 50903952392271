@use "modules/media";
@use "modules/color";

@mixin splash-screen() {
    position: absolute;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: linear-gradient(180deg, color.get(sbf-red) 0%, color.get(sbf-dark-red) 100%);

    &__logo {
        flex-basis: 90%;
        width: 70%;
        max-width: 30rem;

        background-image: url("/assets/images/logos/splash-white.svg");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__bottom-logo {
        flex-basis: 10%;
        width: 50%;
        max-width: 21.4rem;

        background-image: url("/assets/images/logos/heta-arbeten-white.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.angular-loading {
    @include media.mobile-layout {
        @include splash-screen();

        display: none;
    }

    display: none;
}
