@use "fonts";
@use "reset";
@use "material";
@use "splash-screen";
@use "modules/media";
@use "modules/text";
@use "modules/color";
@use "modules/modal";
@use "modules/button";

/* Theming map delivered from each application. */
$themes: () !default;

@media screen and (prefers-reduced-motion: reduce) {

    html {

        --scroll-behavior: auto;
        scroll-behavior: auto;
    }
}

html {
    // Create theme variables (CSS custom properties) from theming map.
    @each $themeName, $themeMap in $themes {
        &.theme-#{$themeName} {
            @each $colorName, $colorValue in $themeMap {

                --theme-#{$colorName}: #{$colorValue};
            }
        }
    }

    --scroll-behavior: smooth;
    scroll-behavior: smooth;
}


body {
    overflow-x: hidden;
    overflow-y: scroll;

    min-height: 100vh;

    background-color: color.get(main-background);

    color: color.get(debug);

    font-size: 1.6rem;
    word-break: break-word;

    &::after {
        @include media.desktop-layout {
            content: "";

            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;

            height: 0.3rem;

            background-color: color.get(theme-primary);
        }
    }
}

.no-scroll {
    overflow: hidden;
}

hr {
    width: 100%;
    height: 0.1rem;

    background-color: color.get(divider);
}

textarea {
    @include text.body-font(1.6rem, 2.4rem, 600, theme-text);

    padding: 2rem;
    resize: none;

    border: 0.1rem solid color.get(theme-input-border);
    border-radius: 0.4rem;
}

sup {
    position: relative;
    top: -0.3em;

    margin-right: 0.1em;

    font-size: 0.7em;
    vertical-align: top;
}

.noscript {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 100vh;

    text-align: center;

    &__section {
        width: 60rem;
        max-width: 80vw;

        &__heading {
            @include text.h2;
        }

        &__text {
            @include text.page-body;
        }

        & + & {
            margin-top: 4rem;
        }
    }
}

.signature-pad-canvas {
    /* stylelint-disable declaration-no-important */
    @include media.mobile-layout {
        border-width: 0.1rem 0 !important;
    }

    @include media.desktop-layout {
        border-width: 0.1rem !important;
    }

    border-color: color.get(theme-signature-border) !important;
    border-style: dashed !important;
    /* stylelint-enable declaration-no-important */
}

.button-loading-directive-loading {
    @include button.loading;
}
