/* --- Open Sans --- */

/* Open Sans 300 (Light) */
@font-face {
    font-family: "Open Sans";
    font-weight: 300;
    font-style: normal;
    src:
        local(""),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-300.woff2") format("woff2"),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans 300 (Light) Italic */
@font-face {
    font-family: "Open Sans";
    font-weight: 300;
    font-style: italic;
    src:
        local(""),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-300italic.woff2") format("woff2"),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans 400 (Regular) */
@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    src:
        local(""),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-regular.woff2") format("woff2"),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans 400 (Regular) Italic */
@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: italic;
    src:
        local(""),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-italic.woff2") format("woff2"),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans 600 (SemiBold) */
@font-face {
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    src:
        local(""),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-600.woff2") format("woff2"),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans 600 (SemiBold) Italic */
@font-face {
    font-family: "Open Sans";
    font-weight: 600;
    font-style: italic;
    src:
        local(""),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-600italic.woff2") format("woff2"),
        url("/assets/fonts/open-sans/open-sans-v18-latin_cyrillic-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* --- Poppins --- */

// Poppins 200 (ExtraLight)
@font-face {
    font-family: "Poppins";
    font-weight: 200;
    font-style: normal;
    src:
        url("/assets/fonts/poppins/poppins-v15-latin-200.woff2") format("woff2"),
        url("/assets/fonts/poppins/poppins-v15-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Poppins 300 (Light)
@font-face {
    font-family: "Poppins";
    font-weight: 300;
    font-style: normal;
    src:
        url("/assets/fonts/poppins/poppins-v15-latin-300.woff2") format("woff2"),
        url("/assets/fonts/poppins/poppins-v15-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Poppins 400 (Regular)
@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-style: normal;
    src:
        url("/assets/fonts/poppins/poppins-v15-latin-regular.woff2") format("woff2"),
        url("/assets/fonts/poppins/poppins-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Poppins 500 (Medium)
@font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-style: normal;
    src:
        url("/assets/fonts/poppins/poppins-v15-latin-500.woff2") format("woff2"),
        url("/assets/fonts/poppins/poppins-v15-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Poppins 600 (Semi-bold)
@font-face {
    font-family: "Poppins";
    font-weight: 600;
    font-style: normal;
    src:
        url("/assets/fonts/poppins/poppins-v15-latin-600.woff2") format("woff2"),
        url("/assets/fonts/poppins/poppins-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Poppins 700 (Bold)
@font-face {
    font-family: "Poppins";
    font-weight: 700;
    font-style: normal;
    src:
        url("/assets/fonts/poppins/poppins-v15-latin-700.woff2") format("woff2"),
        url("/assets/fonts/poppins/poppins-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* --- Geometria --- */

// Geometria 700 (Bold)
@font-face {
    font-family: "Geometria";
    font-weight: 700;
    font-style: normal;
    src:
        url("/assets/fonts/geometria/Geometria-Bold.woff2") format("woff2"),
        url("/assets/fonts/geometria/Geometria-Bold.woff") format("woff");
}


/* --- Password  --- */

// @font-face {
//     font-family: "Password";
//     font-weight: 600;
//     font-style: normal;
//     src:
//         url("/assets/fonts/password/password.woff2") format("woff2"),
//         url("/assets/fonts/password/password.woff") format("woff");
// }


/* --- Font Awesome --- */

// Font Awesome Solid.
@font-face {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    font-style: normal;
    src:
        url("/assets/fonts/font-awesome/fa-solid-900.woff2") format("woff2"),
        url("/assets/fonts/font-awesome/fa-solid-900.ttf") format("truetype");

    font-display: block;
}

// Font Awesome Regular.
@font-face {
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    font-style: normal;
    src:
        url("/assets/fonts/font-awesome/fa-regular-400.woff2") format("woff2"),
        url("/assets/fonts/font-awesome/fa-regular-400.ttf") format("truetype");

    font-display: block;
}

// Font Awesome Light.
@font-face {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    font-style: normal;
    src:
        url("/assets/fonts/font-awesome/fa-light-300.woff2") format("woff2"),
        url("/assets/fonts/font-awesome/fa-light-300.ttf") format("truetype");

    font-display: block;
}

// Font Awesome Brands
@font-face {
    font-family: "Font Awesome 6 Brands";
    font-weight: 400;
    font-style: normal;
    src:
        url("/assets/fonts/font-awesome/fa-brands-400.woff2") format("woff2"),
        url("/assets/fonts/font-awesome/fa-brands-400.ttf") format("truetype");

    font-display: block;
}
