@use "modules/color";

/* Mixins */

@mixin here($name, $style, $size) {
    @include _icon($name, $style, $size);
}

@mixin before($name, $style, $size: 1.6rem, $margin-to-text: 0, $height-adjustment: 0, $color: inherit) {

    &::before {
        @include _icon($name, $style, $size);
        margin-right: $margin-to-text;

        transform: translateY($height-adjustment);

        @if $color != inherit {
            color: color.get($color);
        }
    }
}

@mixin after($name, $style, $size: 1.6rem, $margin-to-text: 0, $height-adjustment: 0, $color: inherit) {

    &::after {
        @include _icon($name, $style, $size);
        margin-left: $margin-to-text;

        transform: translateY($height-adjustment);

        @if $color != inherit {
            color: color.get($color);
        }
    }
}

@mixin floating-before($name, $style, $size: 1.6rem, $text-padding-left: 0, $height-adjustment: 0, $color: inherit) {
    position: relative;

    display: flex;

    padding-left: $text-padding-left;

    &::before {
        @include _icon($name, $style, $size);

        position: absolute;
        align-self: center;
        left: 0;

        transform: translateY($height-adjustment);

        @if $color != inherit {
            color: color.get($color);
        }
    }
}

@mixin floating-after($name, $style, $size: 1.6rem, $text-padding-right: 0, $height-adjustment: 0, $color: inherit) {
    position: relative;

    display: flex;

    padding-right: $text-padding-right;

    &::after {
        @include _icon($name, $style, $size);

        position: absolute;
        align-self: center;
        right: 0;

        transform: translateY($height-adjustment);

        @if $color != inherit {
            color: color.get($color);
        }
    }
}

@mixin loader {
    @include after(spinner-third, light, 1.6rem, 1rem, 0, theme-cta);

    &::after {
        color: color.get(theme-cta);

        animation-name: rotate;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes rotate {

        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

@mixin replace-before($name: null, $style: null, $size: null, $color: null) {

    &::before {
        @if $name {
            content: _get-icon-content($name);
        }

        @if $style {
            @include _set-style($style);
        }

        @if $size {
            font-size: $size;
        }

        @if $color {
            color: color.get($color);
        }
    }
}

@mixin replace-after($name: null, $style: null, $size: null, $color: null) {

    &::after {
        @if $name {
            /* stylelint-disable-next-line declaration-no-important */
            content: _get-icon-content($name) !important;
        }

        @if $style {
            @include _set-style($style);
        }

        @if $size {
            font-size: $size;
        }

        @if $color {
            color: color.get($color);
        }
    }
}

@mixin generate-node-icon-classes {
    @each $icon in $node-icons {
        .node-icon-#{$icon}::before {
            /* stylelint-disable-next-line declaration-no-important */
            content: _get-icon-content($icon) !important;
        }
    }
}

@mixin _icon($name, $style, $size) {
    @include _set-style($style);

    content: _get-icon-content($name);

    display: inline-block;

    font-size: $size;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    /* stylelint-disable-next-line declaration-no-important */
    text-decoration: none !important;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
}

@function _get-icon-content($name) {
    @return map-get($icons, icon-#{$name});
}

@mixin _set-style($style) {
    /* stylelint-disable font-family-no-missing-generic-family-keyword */
    @if $style == solid {
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
    }
    @else if $style == regular {
        font-family: "Font Awesome 6 Pro";
        font-weight: 400;
    }
    @else if $style == light {
        font-family: "Font Awesome 6 Pro";
        font-weight: 300;
    }
    @else if $style == brands {
        font-family: "Font Awesome 6 Brands";
        font-weight: 400;
    }
    /* stylelint-enable font-family-no-missing-generic-family-keyword */
}

/* animations */
@mixin antimate($style: ring, $speed: 2.5s) {
    @keyframes ring {

        0% {
            transform: rotate(-15deg);
        }

        2% {
            transform: rotate(15deg);
        }

        4%,
        12% {
            transform: rotate(-18deg);
        }

        6%,
        14% {
            transform: rotate(18deg);
        }

        8% {
            transform: rotate(-22deg);
        }

        10% {
            transform: rotate(22deg);
        }

        16% {
            transform: rotate(-12deg);
        }

        18% {
            transform: rotate(12deg);
        }

        20%,
        100% {
            transform: rotate(0deg);
        }
    }

    animation: ring $speed ease infinite;
}

/* Variables */

$node-icons: (
    "buildings",
    "industry-windows",
    "mountain",
    "truck-medical",
    "user-helmet-safety",
    "wrench",
);

/* stylelint-disable unit-disallowed-list */
$icons: (
    icon-00: "\e467",
    icon-0: "\30",
    icon-100: "\e41c",
    icon-1: "\31",
    icon-2: "\32",
    icon-360-degrees: "\e2dc",
    icon-3: "\33",
    icon-42-group: "\e080",
    icon-4: "\34",
    icon-500px: "\f26e",
    icon-5: "\35",
    icon-6: "\36",
    icon-7: "\37",
    icon-8: "\38",
    icon-9: "\39",
    icon-a: "\41",
    icon-abacus: "\f640",
    icon-accent-grave: "\60",
    icon-accessible-icon: "\f368",
    icon-accusoft: "\f369",
    icon-acorn: "\f6ae",
    icon-ad: "\f641",
    icon-add: "\2b",
    icon-address-book: "\f2b9",
    icon-address-card: "\f2bb",
    icon-adjust: "\f042",
    icon-adn: "\f170",
    icon-adversal: "\f36a",
    icon-affiliatetheme: "\f36b",
    icon-air-conditioner: "\f8f4",
    icon-air-freshener: "\f5d0",
    icon-airbnb: "\f834",
    icon-airplay: "\e089",
    icon-alarm-clock: "\f34e",
    icon-alarm-exclamation: "\f843",
    icon-alarm-plus: "\f844",
    icon-alarm-snooze: "\f845",
    icon-album-circle-plus: "\e48c",
    icon-album-circle-user: "\e48d",
    icon-album-collection-circle-plus: "\e48e",
    icon-album-collection-circle-user: "\e48f",
    icon-album-collection: "\f8a0",
    icon-album: "\f89f",
    icon-algolia: "\f36c",
    icon-alicorn: "\f6b0",
    icon-alien-8bit: "\f8f6",
    icon-alien-monster: "\f8f6",
    icon-alien: "\f8f5",
    icon-align-center: "\f037",
    icon-align-justify: "\f039",
    icon-align-left: "\f036",
    icon-align-right: "\f038",
    icon-align-slash: "\f846",
    icon-alipay: "\f642",
    icon-allergies: "\f461",
    icon-alt: "\e08a",
    icon-amazon-pay: "\f42c",
    icon-amazon: "\f270",
    icon-ambulance: "\f0f9",
    icon-american-sign-language-interpreting: "\f2a3",
    icon-amilia: "\f36d",
    icon-amp-guitar: "\f8a1",
    icon-ampersand: "\26",
    icon-analytics: "\f643",
    icon-anchor-circle-check: "\e4aa",
    icon-anchor-circle-exclamation: "\e4ab",
    icon-anchor-circle-xmark: "\e4ac",
    icon-anchor-lock: "\e4ad",
    icon-anchor: "\f13d",
    icon-android: "\f17b",
    icon-angel: "\f779",
    icon-angellist: "\f209",
    icon-angle-90: "\e08d",
    icon-angle-double-down: "\f103",
    icon-angle-double-left: "\f100",
    icon-angle-double-right: "\f101",
    icon-angle-double-up: "\f102",
    icon-angle-down: "\f107",
    icon-angle-left: "\f104",
    icon-angle-right: "\f105",
    icon-angle-up: "\f106",
    icon-angle: "\e08c",
    icon-angles-down: "\f103",
    icon-angles-left: "\f100",
    icon-angles-right: "\f101",
    icon-angles-up: "\f102",
    icon-angry: "\f556",
    icon-angrycreative: "\f36e",
    icon-angular: "\f420",
    icon-ankh: "\f644",
    icon-apartment: "\e468",
    icon-aperture: "\e2df",
    icon-apostrophe: "\27",
    icon-app-store-ios: "\f370",
    icon-app-store: "\f36f",
    icon-apper: "\f371",
    icon-apple-alt: "\f5d1",
    icon-apple-core: "\e08f",
    icon-apple-crate: "\f6b1",
    icon-apple-pay: "\f415",
    icon-apple-whole: "\f5d1",
    icon-apple: "\f179",
    icon-archive: "\f187",
    icon-archway: "\f557",
    icon-area-chart: "\f1fe",
    icon-arrow-alt-circle-down: "\f358",
    icon-arrow-alt-circle-left: "\f359",
    icon-arrow-alt-circle-right: "\f35a",
    icon-arrow-alt-circle-up: "\f35b",
    icon-arrow-alt-down: "\f354",
    icon-arrow-alt-from-bottom: "\f346",
    icon-arrow-alt-from-left: "\f347",
    icon-arrow-alt-from-right: "\f348",
    icon-arrow-alt-from-top: "\f349",
    icon-arrow-alt-left: "\f355",
    icon-arrow-alt-right: "\f356",
    icon-arrow-alt-square-down: "\f350",
    icon-arrow-alt-square-left: "\f351",
    icon-arrow-alt-square-right: "\f352",
    icon-arrow-alt-square-up: "\f353",
    icon-arrow-alt-to-bottom: "\f34a",
    icon-arrow-alt-to-left: "\f34b",
    icon-arrow-alt-to-right: "\f34c",
    icon-arrow-alt-to-top: "\f34d",
    icon-arrow-alt-up: "\f357",
    icon-arrow-circle-down: "\f0ab",
    icon-arrow-circle-left: "\f0a8",
    icon-arrow-circle-right: "\f0a9",
    icon-arrow-circle-up: "\f0aa",
    icon-arrow-down-1-9: "\f162",
    icon-arrow-down-9-1: "\f886",
    icon-arrow-down-a-z: "\f15d",
    icon-arrow-down-arrow-up: "\f883",
    icon-arrow-down-big-small: "\f88c",
    icon-arrow-down-from-dotted-line: "\e090",
    icon-arrow-down-from-line: "\f345",
    icon-arrow-down-left-and-arrow-up-right-to-center: "\e092",
    icon-arrow-down-left: "\e091",
    icon-arrow-down-long: "\f175",
    icon-arrow-down-right: "\e093",
    icon-arrow-down-short-wide: "\f884",
    icon-arrow-down-small-big: "\f88d",
    icon-arrow-down-square-triangle: "\f889",
    icon-arrow-down-to-arc: "\e4ae",
    icon-arrow-down-to-bracket: "\e094",
    icon-arrow-down-to-dotted-line: "\e095",
    icon-arrow-down-to-line: "\f33d",
    icon-arrow-down-to-square: "\e096",
    icon-arrow-down-triangle-square: "\f888",
    icon-arrow-down-up-across-line: "\e4af",
    icon-arrow-down-up-lock: "\e4b0",
    icon-arrow-down-wide-short: "\f160",
    icon-arrow-down-z-a: "\f881",
    icon-arrow-down: "\f063",
    icon-arrow-from-bottom: "\f342",
    icon-arrow-from-left: "\f343",
    icon-arrow-from-right: "\f344",
    icon-arrow-from-top: "\f345",
    icon-arrow-left-from-line: "\f344",
    icon-arrow-left-long-to-line: "\e3d4",
    icon-arrow-left-long: "\f177",
    icon-arrow-left-rotate: "\f0e2",
    icon-arrow-left-to-line: "\f33e",
    icon-arrow-left: "\f060",
    icon-arrow-pointer: "\f245",
    icon-arrow-right-arrow-left: "\f0ec",
    icon-arrow-right-from-arc: "\e4b1",
    icon-arrow-right-from-bracket: "\f08b",
    icon-arrow-right-from-file: "\f56e",
    icon-arrow-right-from-line: "\f343",
    icon-arrow-right-long-to-line: "\e3d5",
    icon-arrow-right-long: "\f178",
    icon-arrow-right-rotate: "\f01e",
    icon-arrow-right-to-arc: "\e4b2",
    icon-arrow-right-to-bracket: "\f090",
    icon-arrow-right-to-city: "\e4b3",
    icon-arrow-right-to-file: "\f56f",
    icon-arrow-right-to-line: "\f340",
    icon-arrow-right: "\f061",
    icon-arrow-rotate-back: "\f0e2",
    icon-arrow-rotate-backward: "\f0e2",
    icon-arrow-rotate-forward: "\f01e",
    icon-arrow-rotate-left: "\f0e2",
    icon-arrow-rotate-right: "\f01e",
    icon-arrow-square-down: "\f339",
    icon-arrow-square-left: "\f33a",
    icon-arrow-square-right: "\f33b",
    icon-arrow-square-up: "\f33c",
    icon-arrow-to-bottom: "\f33d",
    icon-arrow-to-left: "\f33e",
    icon-arrow-to-right: "\f340",
    icon-arrow-to-top: "\f341",
    icon-arrow-trend-down: "\e097",
    icon-arrow-trend-up: "\e098",
    icon-arrow-turn-down-left: "\e2e1",
    icon-arrow-turn-down-right: "\e3d6",
    icon-arrow-turn-down: "\f149",
    icon-arrow-turn-right: "\f064",
    icon-arrow-turn-up: "\f148",
    icon-arrow-up-1-9: "\f163",
    icon-arrow-up-9-1: "\f887",
    icon-arrow-up-a-z: "\f15e",
    icon-arrow-up-arrow-down: "\e099",
    icon-arrow-up-big-small: "\f88e",
    icon-arrow-up-from-arc: "\e4b4",
    icon-arrow-up-from-bracket: "\e09a",
    icon-arrow-up-from-dotted-line: "\e09b",
    icon-arrow-up-from-ground-water: "\e4b5",
    icon-arrow-up-from-line: "\f342",
    icon-arrow-up-from-square: "\e09c",
    icon-arrow-up-from-water-pump: "\e4b6",
    icon-arrow-up-left-from-circle: "\e09e",
    icon-arrow-up-left: "\e09d",
    icon-arrow-up-long: "\f176",
    icon-arrow-up-right-and-arrow-down-left-from-center: "\e0a0",
    icon-arrow-up-right-dots: "\e4b7",
    icon-arrow-up-right-from-square: "\f08e",
    icon-arrow-up-right: "\e09f",
    icon-arrow-up-short-wide: "\f885",
    icon-arrow-up-small-big: "\f88f",
    icon-arrow-up-square-triangle: "\f88b",
    icon-arrow-up-to-dotted-line: "\e0a1",
    icon-arrow-up-to-line: "\f341",
    icon-arrow-up-triangle-square: "\f88a",
    icon-arrow-up-wide-short: "\f161",
    icon-arrow-up-z-a: "\f882",
    icon-arrow-up: "\f062",
    icon-arrows-alt-h: "\f337",
    icon-arrows-alt-v: "\f338",
    icon-arrows-alt: "\f0b2",
    icon-arrows-cross: "\e0a2",
    icon-arrows-down-to-line: "\e4b8",
    icon-arrows-down-to-people: "\e4b9",
    icon-arrows-from-dotted-line: "\e0a3",
    icon-arrows-from-line: "\e0a4",
    icon-arrows-h: "\f07e",
    icon-arrows-left-right-to-line: "\e4ba",
    icon-arrows-left-right: "\f07e",
    icon-arrows-maximize: "\f31d",
    icon-arrows-minimize: "\e0a5",
    icon-arrows-repeat-1: "\f366",
    icon-arrows-repeat: "\f364",
    icon-arrows-retweet: "\f361",
    icon-arrows-rotate: "\f021",
    icon-arrows-spin: "\e4bb",
    icon-arrows-split-up-and-left: "\e4bc",
    icon-arrows-to-circle: "\e4bd",
    icon-arrows-to-dot: "\e4be",
    icon-arrows-to-dotted-line: "\e0a6",
    icon-arrows-to-eye: "\e4bf",
    icon-arrows-to-line: "\e0a7",
    icon-arrows-turn-right: "\e4c0",
    icon-arrows-turn-to-dots: "\e4c1",
    icon-arrows-up-down-left-right: "\f047",
    icon-arrows-up-down: "\f07d",
    icon-arrows-up-to-line: "\e4c2",
    icon-arrows-v: "\f07d",
    icon-arrows: "\f047",
    icon-artstation: "\f77a",
    icon-asl-interpreting: "\f2a3",
    icon-assistive-listening-systems: "\f2a2",
    icon-asterisk: "\2a",
    icon-asymmetrik: "\f372",
    icon-at: "\40",
    icon-atlas: "\f558",
    icon-atlassian: "\f77b",
    icon-atom-alt: "\f5d3",
    icon-atom-simple: "\f5d3",
    icon-atom: "\f5d2",
    icon-audible: "\f373",
    icon-audio-description-slash: "\e0a8",
    icon-audio-description: "\f29e",
    icon-austral-sign: "\e0a9",
    icon-automobile: "\f1b9",
    icon-autoprefixer: "\f41c",
    icon-avianex: "\f374",
    icon-aviato: "\f421",
    icon-avocado: "\e0aa",
    icon-award-simple: "\e0ab",
    icon-award: "\f559",
    icon-aws: "\f375",
    icon-axe-battle: "\f6b3",
    icon-axe: "\f6b2",
    icon-b: "\42",
    icon-baby-carriage: "\f77d",
    icon-baby: "\f77c",
    icon-backpack: "\f5d4",
    icon-backspace: "\f55a",
    icon-backward-fast: "\f049",
    icon-backward-step: "\f048",
    icon-backward: "\f04a",
    icon-bacon: "\f7e5",
    icon-bacteria: "\e059",
    icon-bacterium: "\e05a",
    icon-badge-check: "\f336",
    icon-badge-dollar: "\f645",
    icon-badge-percent: "\f646",
    icon-badge-sheriff: "\f8a2",
    icon-badge: "\f335",
    icon-badger-honey: "\f6b4",
    icon-badminton: "\e33a",
    icon-bag-shopping: "\f290",
    icon-bagel: "\e3d7",
    icon-bags-shopping: "\f847",
    icon-baguette: "\e3d8",
    icon-bahai: "\f666",
    icon-baht-sign: "\e0ac",
    icon-balance-scale-left: "\f515",
    icon-balance-scale-right: "\f516",
    icon-balance-scale: "\f24e",
    icon-ball-pile: "\f77e",
    icon-balloon: "\e2e3",
    icon-balloons: "\e2e4",
    icon-ballot-check: "\f733",
    icon-ballot: "\f732",
    icon-ban-bug: "\f7f9",
    icon-ban-parking: "\f616",
    icon-ban-smoking: "\f54d",
    icon-ban: "\f05e",
    icon-banana: "\e2e5",
    icon-band-aid: "\f462",
    icon-bandage: "\f462",
    icon-bandcamp: "\f2d5",
    icon-bangladeshi-taka-sign: "\e2e6",
    icon-banjo: "\f8a3",
    icon-bank: "\f19c",
    icon-bar-chart: "\f080",
    icon-barcode-alt: "\f463",
    icon-barcode-read: "\f464",
    icon-barcode-scan: "\f465",
    icon-barcode: "\f02a",
    icon-barn-silo: "\f864",
    icon-bars-filter: "\e0ad",
    icon-bars-progress: "\f828",
    icon-bars-sort: "\e0ae",
    icon-bars-staggered: "\f550",
    icon-bars: "\f0c9",
    icon-baseball-ball: "\f433",
    icon-baseball-bat-ball: "\f432",
    icon-baseball: "\f433",
    icon-basket-shopping-simple: "\e0af",
    icon-basket-shopping: "\f291",
    icon-basketball-ball: "\f434",
    icon-basketball-hoop: "\f435",
    icon-basketball: "\f434",
    icon-bat: "\f6b5",
    icon-bath: "\f2cd",
    icon-bathtub: "\f2cd",
    icon-battery-0: "\f244",
    icon-battery-1: "\e0b1",
    icon-battery-2: "\f243",
    icon-battery-3: "\f242",
    icon-battery-4: "\f241",
    icon-battery-5: "\f240",
    icon-battery-bolt: "\f376",
    icon-battery-car: "\f5df",
    icon-battery-empty: "\f244",
    icon-battery-exclamation: "\e0b0",
    icon-battery-full: "\f240",
    icon-battery-half: "\f242",
    icon-battery-low: "\e0b1",
    icon-battery-quarter: "\f243",
    icon-battery-slash: "\f377",
    icon-battery-three-quarters: "\f241",
    icon-battery: "\f240",
    icon-battle-net: "\f835",
    icon-bed-alt: "\f8f7",
    icon-bed-bunk: "\f8f8",
    icon-bed-empty: "\f8f9",
    icon-bed-front: "\f8f7",
    icon-bed-pulse: "\f487",
    icon-bed: "\f236",
    icon-bee: "\e0b2",
    icon-beer-foam: "\e0b3",
    icon-beer-mug-empty: "\f0fc",
    icon-beer-mug: "\e0b3",
    icon-beer: "\f0fc",
    icon-behance-square: "\f1b5",
    icon-behance: "\f1b4",
    icon-bell-concierge: "\f562",
    icon-bell-exclamation: "\f848",
    icon-bell-on: "\f8fa",
    icon-bell-plus: "\f849",
    icon-bell-school-slash: "\f5d6",
    icon-bell-school: "\f5d5",
    icon-bell-slash: "\f1f6",
    icon-bell: "\f0f3",
    icon-bells: "\f77f",
    icon-bench-tree: "\e2e7",
    icon-betamax: "\f8a4",
    icon-bezier-curve: "\f55b",
    icon-bible: "\f647",
    icon-bicycle: "\f206",
    icon-biking-mountain: "\f84b",
    icon-biking: "\f84a",
    icon-bilibili: "\e3d9",
    icon-billboard: "\e5cd",
    icon-bimobject: "\f378",
    icon-binary-circle-check: "\e33c",
    icon-binary-lock: "\e33d",
    icon-binary-slash: "\e33e",
    icon-binary: "\e33b",
    icon-binoculars: "\f1e5",
    icon-biohazard: "\f780",
    icon-bird: "\e469",
    icon-birthday-cake: "\f1fd",
    icon-bitbucket: "\f171",
    icon-bitcoin-sign: "\e0b4",
    icon-bitcoin: "\f379",
    icon-bity: "\f37a",
    icon-black-tie: "\f27e",
    icon-blackberry: "\f37b",
    icon-blackboard: "\f51b",
    icon-blanket-fire: "\e3da",
    icon-blanket: "\f498",
    icon-blender-phone: "\f6b6",
    icon-blender: "\f517",
    icon-blind: "\f29d",
    icon-blinds-open: "\f8fc",
    icon-blinds-raised: "\f8fd",
    icon-blinds: "\f8fb",
    icon-block-brick-fire: "\e3dc",
    icon-block-brick: "\e3db",
    icon-block-question: "\e3dd",
    icon-block-quote: "\e0b5",
    icon-block: "\e46a",
    icon-blog: "\f781",
    icon-blogger-b: "\f37d",
    icon-blogger: "\f37c",
    icon-blueberries: "\e2e8",
    icon-bluetooth-b: "\f294",
    icon-bluetooth: "\f293",
    icon-bold: "\f032",
    icon-bolt-auto: "\e0b6",
    icon-bolt-lightning: "\e0b7",
    icon-bolt-slash: "\e0b8",
    icon-bolt: "\f0e7",
    icon-bomb: "\f1e2",
    icon-bone-break: "\f5d8",
    icon-bone: "\f5d7",
    icon-bong: "\f55c",
    icon-book-alt: "\f5d9",
    icon-book-arrow-right: "\e0b9",
    icon-book-arrow-up: "\e0ba",
    icon-book-atlas: "\f558",
    icon-book-bible: "\f647",
    icon-book-blank: "\f5d9",
    icon-book-bookmark: "\e0bb",
    icon-book-circle-arrow-right: "\e0bc",
    icon-book-circle-arrow-up: "\e0bd",
    icon-book-circle: "\e0ff",
    icon-book-copy: "\e0be",
    icon-book-dead: "\f6b7",
    icon-book-font: "\e0bf",
    icon-book-heart: "\f499",
    icon-book-journal-whills: "\f66a",
    icon-book-law: "\e0c1",
    icon-book-medical: "\f7e6",
    icon-book-open-alt: "\e0c0",
    icon-book-open-cover: "\e0c0",
    icon-book-open-reader: "\f5da",
    icon-book-open: "\f518",
    icon-book-quran: "\f687",
    icon-book-reader: "\f5da",
    icon-book-section: "\e0c1",
    icon-book-skull: "\f6b7",
    icon-book-sparkles: "\f6b8",
    icon-book-spells: "\f6b8",
    icon-book-tanakh: "\f827",
    icon-book-user: "\f7e7",
    icon-book: "\f02d",
    icon-bookmark-circle: "\e100",
    icon-bookmark-slash: "\e0c2",
    icon-bookmark: "\f02e",
    icon-books-medical: "\f7e8",
    icon-books: "\f5db",
    icon-boombox: "\f8a5",
    icon-boot-heeled: "\e33f",
    icon-boot: "\f782",
    icon-booth-curtain: "\f734",
    icon-bootstrap: "\f836",
    icon-border-all: "\f84c",
    icon-border-bottom-right: "\f854",
    icon-border-bottom: "\f84d",
    icon-border-center-h: "\f89c",
    icon-border-center-v: "\f89d",
    icon-border-inner: "\f84e",
    icon-border-left: "\f84f",
    icon-border-none: "\f850",
    icon-border-outer: "\f851",
    icon-border-right: "\f852",
    icon-border-style-alt: "\f854",
    icon-border-style: "\f853",
    icon-border-top-left: "\f853",
    icon-border-top: "\f855",
    icon-bore-hole: "\e4c3",
    icon-bots: "\e340",
    icon-bottle-droplet: "\e4c4",
    icon-bottle-water: "\e4c5",
    icon-bow-arrow: "\f6b9",
    icon-bowl-chopsticks-noodles: "\e2ea",
    icon-bowl-chopsticks: "\e2e9",
    icon-bowl-food: "\e4c6",
    icon-bowl-hot: "\f823",
    icon-bowl-rice: "\e2eb",
    icon-bowl-salad: "\f81e",
    icon-bowl-scoop: "\e3de",
    icon-bowl-scoops: "\e3df",
    icon-bowl-shaved-ice: "\e3de",
    icon-bowl-soft-serve: "\e46b",
    icon-bowl-spoon: "\e3e0",
    icon-bowling-ball-pin: "\e0c3",
    icon-bowling-ball: "\f436",
    icon-bowling-pins: "\f437",
    icon-box-alt: "\f49a",
    icon-box-archive: "\f187",
    icon-box-ballot: "\f735",
    icon-box-check: "\f467",
    icon-box-circle-check: "\e0c4",
    icon-box-dollar: "\f4a0",
    icon-box-fragile: "\f49b",
    icon-box-full: "\f49c",
    icon-box-heart: "\f49d",
    icon-box-open-full: "\f49c",
    icon-box-open: "\f49e",
    icon-box-taped: "\f49a",
    icon-box-tissue: "\e05b",
    icon-box-up: "\f49f",
    icon-box-usd: "\f4a0",
    icon-box: "\f466",
    icon-boxes-alt: "\f468",
    icon-boxes-packing: "\e4c7",
    icon-boxes-stacked: "\f468",
    icon-boxes: "\f468",
    icon-boxing-glove: "\f438",
    icon-bracket-curly-left: "\7b",
    icon-bracket-curly-right: "\7d",
    icon-bracket-curly: "\7b",
    icon-bracket-left: "\5b",
    icon-bracket-round-right: "\29",
    icon-bracket-round: "\28",
    icon-bracket-square-right: "\5d",
    icon-bracket-square: "\5b",
    icon-bracket: "\5b",
    icon-brackets-curly: "\f7ea",
    icon-brackets-round: "\e0c5",
    icon-brackets-square: "\f7e9",
    icon-brackets: "\f7e9",
    icon-braille: "\f2a1",
    icon-brain-arrow-curved-right: "\f677",
    icon-brain-circuit: "\e0c6",
    icon-brain: "\f5dc",
    icon-brake-warning: "\e0c7",
    icon-brazilian-real-sign: "\e46c",
    icon-bread-loaf: "\f7eb",
    icon-bread-slice-butter: "\e3e1",
    icon-bread-slice: "\f7ec",
    icon-bridge-circle-check: "\e4c9",
    icon-bridge-circle-exclamation: "\e4ca",
    icon-bridge-circle-xmark: "\e4cb",
    icon-bridge-lock: "\e4cc",
    icon-bridge-suspension: "\e4cd",
    icon-bridge-water: "\e4ce",
    icon-bridge: "\e4c8",
    icon-briefcase-arrow-right: "\e2f2",
    icon-briefcase-blank: "\e0c8",
    icon-briefcase-clock: "\f64a",
    icon-briefcase-medical: "\f469",
    icon-briefcase: "\f0b1",
    icon-brightness-low: "\e0ca",
    icon-brightness: "\e0c9",
    icon-bring-forward: "\f856",
    icon-bring-front: "\f857",
    icon-broadcast-tower: "\f519",
    icon-broccoli: "\e3e2",
    icon-broom-ball: "\f458",
    icon-broom-wide: "\e5d1",
    icon-broom: "\f51a",
    icon-browser: "\f37e",
    icon-browsers: "\e0cb",
    icon-brush: "\f55d",
    icon-btc: "\f15a",
    icon-bucket: "\e4cf",
    icon-buffer: "\f837",
    icon-bug-slash: "\e490",
    icon-bug: "\f188",
    icon-bugs: "\e4d0",
    icon-building-circle-arrow-right: "\e4d1",
    icon-building-circle-check: "\e4d2",
    icon-building-circle-exclamation: "\e4d3",
    icon-building-circle-xmark: "\e4d4",
    icon-building-columns: "\f19c",
    icon-building-flag: "\e4d5",
    icon-building-lock: "\e4d6",
    icon-building-ngo: "\e4d7",
    icon-building-shield: "\e4d8",
    icon-building-un: "\e4d9",
    icon-building-user: "\e4da",
    icon-building-wheat: "\e4db",
    icon-building: "\f1ad",
    icon-buildings: "\e0cc",
    icon-bullhorn: "\f0a1",
    icon-bullseye-arrow: "\f648",
    icon-bullseye-pointer: "\f649",
    icon-bullseye: "\f140",
    icon-buoy-mooring: "\e5b6",
    icon-buoy: "\e5b5",
    icon-burger-cheese: "\f7f1",
    icon-burger-fries: "\e0cd",
    icon-burger-glass: "\e0ce",
    icon-burger-lettuce: "\e3e3",
    icon-burger-soda: "\f858",
    icon-burger: "\f805",
    icon-burn: "\f46a",
    icon-buromobelexperte: "\f37f",
    icon-burrito: "\f7ed",
    icon-burst: "\e4dc",
    icon-bus-alt: "\f55e",
    icon-bus-school: "\f5dd",
    icon-bus-simple: "\f55e",
    icon-bus: "\f207",
    icon-business-front: "\e45c",
    icon-business-time: "\f64a",
    icon-butter: "\e3e4",
    icon-buy-n-large: "\f8a6",
    icon-buysellads: "\f20d",
    icon-c: "\43",
    icon-cab: "\f1ba",
    icon-cabin: "\e46d",
    icon-cabinet-filing: "\f64b",
    icon-cable-car: "\f7da",
    icon-cactus: "\f8a7",
    icon-cake-candles: "\f1fd",
    icon-cake-slice: "\e3e5",
    icon-cake: "\f1fd",
    icon-calculator-alt: "\f64c",
    icon-calculator-simple: "\f64c",
    icon-calculator: "\f1ec",
    icon-calendar-alt: "\f073",
    icon-calendar-arrow-down: "\e0d0",
    icon-calendar-arrow-up: "\e0d1",
    icon-calendar-check: "\f274",
    icon-calendar-circle-exclamation: "\e46e",
    icon-calendar-circle-minus: "\e46f",
    icon-calendar-circle-plus: "\e470",
    icon-calendar-circle-user: "\e471",
    icon-calendar-circle: "\e102",
    icon-calendar-clock: "\e0d2",
    icon-calendar-day: "\f783",
    icon-calendar-days: "\f073",
    icon-calendar-download: "\e0d0",
    icon-calendar-edit: "\f333",
    icon-calendar-exclamation: "\f334",
    icon-calendar-heart: "\e0d3",
    icon-calendar-image: "\e0d4",
    icon-calendar-lines-pen: "\e472",
    icon-calendar-lines: "\e0d5",
    icon-calendar-minus: "\f272",
    icon-calendar-note: "\e0d5",
    icon-calendar-pen: "\f333",
    icon-calendar-plus: "\f271",
    icon-calendar-range: "\e0d6",
    icon-calendar-star: "\f736",
    icon-calendar-time: "\e0d2",
    icon-calendar-times: "\f273",
    icon-calendar-upload: "\e0d1",
    icon-calendar-week: "\f784",
    icon-calendar-xmark: "\f273",
    icon-calendar: "\f133",
    icon-calendars: "\e0d7",
    icon-camcorder: "\f8a8",
    icon-camera-alt: "\f030",
    icon-camera-cctv: "\f8ac",
    icon-camera-circle: "\e103",
    icon-camera-home: "\f8fe",
    icon-camera-movie: "\f8a9",
    icon-camera-polaroid: "\f8aa",
    icon-camera-retro: "\f083",
    icon-camera-rotate: "\e0d8",
    icon-camera-security: "\f8fe",
    icon-camera-slash: "\e0d9",
    icon-camera-viewfinder: "\e0da",
    icon-camera-web-slash: "\f833",
    icon-camera-web: "\f832",
    icon-camera: "\f030",
    icon-campfire: "\f6ba",
    icon-campground: "\f6bb",
    icon-can-food: "\e3e6",
    icon-canadian-maple-leaf: "\f785",
    icon-cancel: "\f05e",
    icon-candle-holder: "\f6bc",
    icon-candy-bar: "\e3e8",
    icon-candy-cane: "\f786",
    icon-candy-corn: "\f6bd",
    icon-candy: "\e3e7",
    icon-cannabis: "\f55f",
    icon-capsules: "\f46b",
    icon-car-alt: "\f5de",
    icon-car-battery: "\f5df",
    icon-car-bolt: "\e341",
    icon-car-building: "\f859",
    icon-car-bump: "\f5e0",
    icon-car-burst: "\f5e1",
    icon-car-bus: "\f85a",
    icon-car-circle-bolt: "\e342",
    icon-car-crash: "\f5e1",
    icon-car-garage: "\f5e2",
    icon-car-mechanic: "\f5e3",
    icon-car-mirrors: "\e343",
    icon-car-on: "\e4dd",
    icon-car-rear: "\f5de",
    icon-car-side-bolt: "\e344",
    icon-car-side: "\f5e4",
    icon-car-tilt: "\f5e5",
    icon-car-tunnel: "\e4de",
    icon-car-wash: "\f5e6",
    icon-car-wrench: "\f5e3",
    icon-car: "\f1b9",
    icon-caravan-alt: "\e000",
    icon-caravan-simple: "\e000",
    icon-caravan: "\f8ff",
    icon-card-club: "\e3e9",
    icon-card-diamond: "\e3ea",
    icon-card-heart: "\e3eb",
    icon-card-spade: "\e3ec",
    icon-cards-blank: "\e4df",
    icon-cards: "\e3ed",
    icon-caret-circle-down: "\f32d",
    icon-caret-circle-left: "\f32e",
    icon-caret-circle-right: "\f330",
    icon-caret-circle-up: "\f331",
    icon-caret-down: "\f0d7",
    icon-caret-left: "\f0d9",
    icon-caret-right: "\f0da",
    icon-caret-square-down: "\f150",
    icon-caret-square-left: "\f191",
    icon-caret-square-right: "\f152",
    icon-caret-square-up: "\f151",
    icon-caret-up: "\f0d8",
    icon-carriage-baby: "\f77d",
    icon-carrot: "\f787",
    icon-cars: "\f85b",
    icon-cart-arrow-down: "\f218",
    icon-cart-arrow-up: "\e3ee",
    icon-cart-circle-arrow-down: "\e3ef",
    icon-cart-circle-arrow-up: "\e3f0",
    icon-cart-circle-check: "\e3f1",
    icon-cart-circle-exclamation: "\e3f2",
    icon-cart-circle-plus: "\e3f3",
    icon-cart-circle-xmark: "\e3f4",
    icon-cart-flatbed-boxes: "\f475",
    icon-cart-flatbed-empty: "\f476",
    icon-cart-flatbed-suitcase: "\f59d",
    icon-cart-flatbed: "\f474",
    icon-cart-minus: "\e0db",
    icon-cart-plus: "\f217",
    icon-cart-shopping-fast: "\e0dc",
    icon-cart-shopping: "\f07a",
    icon-cart-xmark: "\e0dd",
    icon-cash-register: "\f788",
    icon-cassette-betamax: "\f8a4",
    icon-cassette-tape: "\f8ab",
    icon-cassette-vhs: "\f8ec",
    icon-castle: "\e0de",
    icon-cat-space: "\e001",
    icon-cat: "\f6be",
    icon-cauldron: "\f6bf",
    icon-cc-amazon-pay: "\f42d",
    icon-cc-amex: "\f1f3",
    icon-cc-apple-pay: "\f416",
    icon-cc-diners-club: "\f24c",
    icon-cc-discover: "\f1f2",
    icon-cc-jcb: "\f24b",
    icon-cc-mastercard: "\f1f1",
    icon-cc-paypal: "\f1f4",
    icon-cc-stripe: "\f1f5",
    icon-cc-visa: "\f1f0",
    icon-cctv: "\f8ac",
    icon-cedi-sign: "\e0df",
    icon-cent-sign: "\e3f5",
    icon-centercode: "\f380",
    icon-centos: "\f789",
    icon-certificate: "\f0a3",
    icon-chain-broken: "\f127",
    icon-chain-horizontal-slash: "\e1cc",
    icon-chain-horizontal: "\e1cb",
    icon-chain-slash: "\f127",
    icon-chain: "\f0c1",
    icon-chair-office: "\f6c1",
    icon-chair: "\f6c0",
    icon-chalkboard-teacher: "\f51c",
    icon-chalkboard-user: "\f51c",
    icon-chalkboard: "\f51b",
    icon-champagne-glass: "\f79e",
    icon-champagne-glasses: "\f79f",
    icon-charging-station: "\f5e7",
    icon-chart-area: "\f1fe",
    icon-chart-bar: "\f080",
    icon-chart-bullet: "\e0e1",
    icon-chart-candlestick: "\e0e2",
    icon-chart-column: "\e0e3",
    icon-chart-gantt: "\e0e4",
    icon-chart-line-down: "\f64d",
    icon-chart-line-up: "\e0e5",
    icon-chart-line: "\f201",
    icon-chart-mixed: "\f643",
    icon-chart-network: "\f78a",
    icon-chart-pie-alt: "\f64e",
    icon-chart-pie-simple: "\f64e",
    icon-chart-pie: "\f200",
    icon-chart-pyramid: "\e0e6",
    icon-chart-radar: "\e0e7",
    icon-chart-scatter-3d: "\e0e8",
    icon-chart-scatter-bubble: "\e0e9",
    icon-chart-scatter: "\f7ee",
    icon-chart-simple-horizontal: "\e474",
    icon-chart-simple: "\e473",
    icon-chart-tree-map: "\e0ea",
    icon-chart-user: "\f6a3",
    icon-chart-waterfall: "\e0eb",
    icon-check-circle: "\f058",
    icon-check-double: "\f560",
    icon-check-square: "\f14a",
    icon-check-to-slot: "\f772",
    icon-check: "\f00c",
    icon-cheese-swiss: "\f7f0",
    icon-cheese: "\f7ef",
    icon-cheeseburger: "\f7f1",
    icon-cherries: "\e0ec",
    icon-chess-bishop-alt: "\f43b",
    icon-chess-bishop-piece: "\f43b",
    icon-chess-bishop: "\f43a",
    icon-chess-board: "\f43c",
    icon-chess-clock-alt: "\f43e",
    icon-chess-clock-flip: "\f43e",
    icon-chess-clock: "\f43d",
    icon-chess-king-alt: "\f440",
    icon-chess-king-piece: "\f440",
    icon-chess-king: "\f43f",
    icon-chess-knight-alt: "\f442",
    icon-chess-knight-piece: "\f442",
    icon-chess-knight: "\f441",
    icon-chess-pawn-alt: "\f444",
    icon-chess-pawn-piece: "\f444",
    icon-chess-pawn: "\f443",
    icon-chess-queen-alt: "\f446",
    icon-chess-queen-piece: "\f446",
    icon-chess-queen: "\f445",
    icon-chess-rook-alt: "\f448",
    icon-chess-rook-piece: "\f448",
    icon-chess-rook: "\f447",
    icon-chess: "\f439",
    icon-chestnut: "\e3f6",
    icon-chevron-circle-down: "\f13a",
    icon-chevron-circle-left: "\f137",
    icon-chevron-circle-right: "\f138",
    icon-chevron-circle-up: "\f139",
    icon-chevron-double-down: "\f322",
    icon-chevron-double-left: "\f323",
    icon-chevron-double-right: "\f324",
    icon-chevron-double-up: "\f325",
    icon-chevron-down: "\f078",
    icon-chevron-left: "\f053",
    icon-chevron-right: "\f054",
    icon-chevron-square-down: "\f329",
    icon-chevron-square-left: "\f32a",
    icon-chevron-square-right: "\f32b",
    icon-chevron-square-up: "\f32c",
    icon-chevron-up: "\f077",
    icon-chevrons-down: "\f322",
    icon-chevrons-left: "\f323",
    icon-chevrons-right: "\f324",
    icon-chevrons-up: "\f325",
    icon-child-combatant: "\e4e0",
    icon-child-dress: "\e59c",
    icon-child-reaching: "\e59d",
    icon-child-rifle: "\e4e0",
    icon-child: "\f1ae",
    icon-children: "\e4e1",
    icon-chimney: "\f78b",
    icon-chocolate-bar: "\e3e8",
    icon-chopsticks: "\e3f7",
    icon-chrome: "\f268",
    icon-chromecast: "\f838",
    icon-church: "\f51d",
    icon-circle-0: "\e0ed",
    icon-circle-1: "\e0ee",
    icon-circle-2: "\e0ef",
    icon-circle-3: "\e0f0",
    icon-circle-4: "\e0f1",
    icon-circle-5: "\e0f2",
    icon-circle-6: "\e0f3",
    icon-circle-7: "\e0f4",
    icon-circle-8: "\e0f5",
    icon-circle-9: "\e0f6",
    icon-circle-a: "\e0f7",
    icon-circle-ampersand: "\e0f8",
    icon-circle-arrow-down-left: "\e0f9",
    icon-circle-arrow-down-right: "\e0fa",
    icon-circle-arrow-down: "\f0ab",
    icon-circle-arrow-left: "\f0a8",
    icon-circle-arrow-right: "\f0a9",
    icon-circle-arrow-up-left: "\e0fb",
    icon-circle-arrow-up-right: "\e0fc",
    icon-circle-arrow-up: "\f0aa",
    icon-circle-b: "\e0fd",
    icon-circle-bolt: "\e0fe",
    icon-circle-book-open: "\e0ff",
    icon-circle-bookmark: "\e100",
    icon-circle-c: "\e101",
    icon-circle-calendar: "\e102",
    icon-circle-camera: "\e103",
    icon-circle-caret-down: "\f32d",
    icon-circle-caret-left: "\f32e",
    icon-circle-caret-right: "\f330",
    icon-circle-caret-up: "\f331",
    icon-circle-check: "\f058",
    icon-circle-chevron-down: "\f13a",
    icon-circle-chevron-left: "\f137",
    icon-circle-chevron-right: "\f138",
    icon-circle-chevron-up: "\f139",
    icon-circle-d: "\e104",
    icon-circle-dashed: "\e105",
    icon-circle-divide: "\e106",
    icon-circle-dollar-to-slot: "\f4b9",
    icon-circle-dollar: "\f2e8",
    icon-circle-dot: "\f192",
    icon-circle-down-left: "\e107",
    icon-circle-down-right: "\e108",
    icon-circle-down: "\f358",
    icon-circle-e: "\e109",
    icon-circle-ellipsis-vertical: "\e10b",
    icon-circle-ellipsis: "\e10a",
    icon-circle-envelope: "\e10c",
    icon-circle-euro: "\e5ce",
    icon-circle-exclamation-check: "\e10d",
    icon-circle-exclamation: "\f06a",
    icon-circle-f: "\e10e",
    icon-circle-g: "\e10f",
    icon-circle-h: "\f47e",
    icon-circle-half-stroke: "\f042",
    icon-circle-half: "\e110",
    icon-circle-heart: "\f4c7",
    icon-circle-i: "\e111",
    icon-circle-info: "\f05a",
    icon-circle-j: "\e112",
    icon-circle-k: "\e113",
    icon-circle-l: "\e114",
    icon-circle-left: "\f359",
    icon-circle-location-arrow: "\f602",
    icon-circle-m: "\e115",
    icon-circle-microphone-lines: "\e117",
    icon-circle-microphone: "\e116",
    icon-circle-minus: "\f056",
    icon-circle-n: "\e118",
    icon-circle-nodes: "\e4e2",
    icon-circle-notch: "\f1ce",
    icon-circle-o: "\e119",
    icon-circle-p: "\e11a",
    icon-circle-parking: "\f615",
    icon-circle-pause: "\f28b",
    icon-circle-phone-flip: "\e11c",
    icon-circle-phone-hangup: "\e11d",
    icon-circle-phone: "\e11b",
    icon-circle-play: "\f144",
    icon-circle-plus: "\f055",
    icon-circle-q: "\e11e",
    icon-circle-quarter-stroke: "\e5d3",
    icon-circle-quarter: "\e11f",
    icon-circle-quarters: "\e3f8",
    icon-circle-question: "\f059",
    icon-circle-r: "\e120",
    icon-circle-radiation: "\f7ba",
    icon-circle-right: "\f35a",
    icon-circle-s: "\e121",
    icon-circle-small: "\e122",
    icon-circle-sort-down: "\e031",
    icon-circle-sort-up: "\e032",
    icon-circle-sort: "\e030",
    icon-circle-star: "\e123",
    icon-circle-sterling: "\e5cf",
    icon-circle-stop: "\f28d",
    icon-circle-t: "\e124",
    icon-circle-three-quarters-stroke: "\e5d4",
    icon-circle-three-quarters: "\e125",
    icon-circle-trash: "\e126",
    icon-circle-u: "\e127",
    icon-circle-up-left: "\e128",
    icon-circle-up-right: "\e129",
    icon-circle-up: "\f35b",
    icon-circle-user: "\f2bd",
    icon-circle-v: "\e12a",
    icon-circle-video: "\e12b",
    icon-circle-w: "\e12c",
    icon-circle-waveform-lines: "\e12d",
    icon-circle-x: "\e12e",
    icon-circle-xmark: "\f057",
    icon-circle-y: "\e12f",
    icon-circle-yen: "\e5d0",
    icon-circle-z: "\e130",
    icon-circle: "\f111",
    icon-citrus-slice: "\e2f5",
    icon-citrus: "\e2f4",
    icon-city: "\f64f",
    icon-clapperboard-play: "\e132",
    icon-clapperboard: "\e131",
    icon-clarinet: "\f8ad",
    icon-claw-marks: "\f6c2",
    icon-clinic-medical: "\f7f2",
    icon-clipboard-check: "\f46c",
    icon-clipboard-list-check: "\f737",
    icon-clipboard-list: "\f46d",
    icon-clipboard-medical: "\e133",
    icon-clipboard-prescription: "\f5e8",
    icon-clipboard-question: "\e4e3",
    icon-clipboard-user: "\f7f3",
    icon-clipboard: "\f328",
    icon-clock-desk: "\e134",
    icon-clock-eight-thirty: "\e346",
    icon-clock-eight: "\e345",
    icon-clock-eleven-thirty: "\e348",
    icon-clock-eleven: "\e347",
    icon-clock-five-thirty: "\e34a",
    icon-clock-five: "\e349",
    icon-clock-four-thirty: "\e34b",
    icon-clock-four: "\f017",
    icon-clock-nine-thirty: "\e34d",
    icon-clock-nine: "\e34c",
    icon-clock-one-thirty: "\e34f",
    icon-clock-one: "\e34e",
    icon-clock-rotate-left: "\f1da",
    icon-clock-seven-thirty: "\e351",
    icon-clock-seven: "\e350",
    icon-clock-six-thirty: "\e353",
    icon-clock-six: "\e352",
    icon-clock-ten-thirty: "\e355",
    icon-clock-ten: "\e354",
    icon-clock-three-thirty: "\e357",
    icon-clock-three: "\e356",
    icon-clock-twelve-thirty: "\e359",
    icon-clock-twelve: "\e358",
    icon-clock-two-thirty: "\e35b",
    icon-clock-two: "\e35a",
    icon-clock: "\f017",
    icon-clone: "\f24d",
    icon-close: "\f00d",
    icon-closed-captioning-slash: "\e135",
    icon-closed-captioning: "\f20a",
    icon-clothes-hanger: "\e136",
    icon-cloud-arrow-down: "\f0ed",
    icon-cloud-arrow-up: "\f0ee",
    icon-cloud-bolt-moon: "\f76d",
    icon-cloud-bolt-sun: "\f76e",
    icon-cloud-bolt: "\f76c",
    icon-cloud-check: "\e35c",
    icon-cloud-download-alt: "\f0ed",
    icon-cloud-download: "\f0ed",
    icon-cloud-drizzle: "\f738",
    icon-cloud-exclamation: "\e491",
    icon-cloud-fog: "\f74e",
    icon-cloud-hail-mixed: "\f73a",
    icon-cloud-hail: "\f739",
    icon-cloud-meatball: "\f73b",
    icon-cloud-minus: "\e35d",
    icon-cloud-moon-rain: "\f73c",
    icon-cloud-moon: "\f6c3",
    icon-cloud-music: "\f8ae",
    icon-cloud-plus: "\e35e",
    icon-cloud-question: "\e492",
    icon-cloud-rain: "\f73d",
    icon-cloud-rainbow: "\f73e",
    icon-cloud-showers-heavy: "\f740",
    icon-cloud-showers-water: "\e4e4",
    icon-cloud-showers: "\f73f",
    icon-cloud-slash: "\e137",
    icon-cloud-sleet: "\f741",
    icon-cloud-snow: "\f742",
    icon-cloud-sun-rain: "\f743",
    icon-cloud-sun: "\f6c4",
    icon-cloud-upload-alt: "\f0ee",
    icon-cloud-upload: "\f0ee",
    icon-cloud-word: "\e138",
    icon-cloud-xmark: "\e35f",
    icon-cloud: "\f0c2",
    icon-cloudflare: "\e07d",
    icon-clouds-moon: "\f745",
    icon-clouds-sun: "\f746",
    icon-clouds: "\f744",
    icon-cloudscale: "\f383",
    icon-cloudsmith: "\f384",
    icon-cloudversify: "\f385",
    icon-clover: "\e139",
    icon-club: "\f327",
    icon-cmplid: "\e360",
    icon-cny: "\f157",
    icon-cocktail: "\f561",
    icon-coconut: "\e2f6",
    icon-code-branch: "\f126",
    icon-code-commit: "\f386",
    icon-code-compare: "\e13a",
    icon-code-fork: "\e13b",
    icon-code-merge: "\f387",
    icon-code-pull-request-closed: "\e3f9",
    icon-code-pull-request-draft: "\e3fa",
    icon-code-pull-request: "\e13c",
    icon-code-simple: "\e13d",
    icon-code: "\f121",
    icon-codepen: "\f1cb",
    icon-codiepie: "\f284",
    icon-coffee-bean: "\e13e",
    icon-coffee-beans: "\e13f",
    icon-coffee-pot: "\e002",
    icon-coffee-togo: "\f6c5",
    icon-coffee: "\f0f4",
    icon-coffin-cross: "\e051",
    icon-coffin: "\f6c6",
    icon-cog: "\f013",
    icon-cogs: "\f085",
    icon-coin-blank: "\e3fb",
    icon-coin-front: "\e3fc",
    icon-coin-vertical: "\e3fd",
    icon-coin: "\f85c",
    icon-coins: "\f51e",
    icon-colon-sign: "\e140",
    icon-colon: "\3a",
    icon-columns-3: "\e361",
    icon-columns: "\f0db",
    icon-comet: "\e003",
    icon-comma: "\2c",
    icon-command: "\e142",
    icon-comment-alt-arrow-down: "\e1db",
    icon-comment-alt-arrow-up: "\e1dc",
    icon-comment-alt-captions: "\e1de",
    icon-comment-alt-check: "\f4a2",
    icon-comment-alt-dollar: "\f650",
    icon-comment-alt-dots: "\f4a3",
    icon-comment-alt-edit: "\f4a4",
    icon-comment-alt-exclamation: "\f4a5",
    icon-comment-alt-image: "\e1e0",
    icon-comment-alt-lines: "\f4a6",
    icon-comment-alt-medical: "\f7f4",
    icon-comment-alt-minus: "\f4a7",
    icon-comment-alt-music: "\f8af",
    icon-comment-alt-plus: "\f4a8",
    icon-comment-alt-quote: "\e1e4",
    icon-comment-alt-slash: "\f4a9",
    icon-comment-alt-smile: "\f4aa",
    icon-comment-alt-text: "\e1e6",
    icon-comment-alt-times: "\f4ab",
    icon-comment-alt: "\f27a",
    icon-comment-arrow-down: "\e143",
    icon-comment-arrow-up-right: "\e145",
    icon-comment-arrow-up: "\e144",
    icon-comment-captions: "\e146",
    icon-comment-check: "\f4ac",
    icon-comment-code: "\e147",
    icon-comment-dollar: "\f651",
    icon-comment-dots: "\f4ad",
    icon-comment-edit: "\f4ae",
    icon-comment-exclamation: "\f4af",
    icon-comment-heart: "\e5c8",
    icon-comment-image: "\e148",
    icon-comment-lines: "\f4b0",
    icon-comment-medical: "\f7f5",
    icon-comment-middle-alt: "\e1e1",
    icon-comment-middle-top-alt: "\e1e2",
    icon-comment-middle-top: "\e14a",
    icon-comment-middle: "\e149",
    icon-comment-minus: "\f4b1",
    icon-comment-music: "\f8b0",
    icon-comment-pen: "\f4ae",
    icon-comment-plus: "\f4b2",
    icon-comment-question: "\e14b",
    icon-comment-quote: "\e14c",
    icon-comment-slash: "\f4b3",
    icon-comment-smile: "\f4b4",
    icon-comment-sms: "\f7cd",
    icon-comment-text: "\e14d",
    icon-comment-times: "\f4b5",
    icon-comment-xmark: "\f4b5",
    icon-comment: "\f075",
    icon-commenting: "\f4ad",
    icon-comments-alt-dollar: "\f652",
    icon-comments-alt: "\f4b6",
    icon-comments-dollar: "\f653",
    icon-comments-question-check: "\e14f",
    icon-comments-question: "\e14e",
    icon-comments: "\f086",
    icon-compact-disc: "\f51f",
    icon-compass-drafting: "\f568",
    icon-compass-slash: "\f5e9",
    icon-compass: "\f14e",
    icon-compress-alt: "\f422",
    icon-compress-arrows-alt: "\f78c",
    icon-compress-arrows: "\e0a5",
    icon-compress-wide: "\f326",
    icon-compress: "\f066",
    icon-computer-classic: "\f8b1",
    icon-computer-mouse-scrollwheel: "\f8cd",
    icon-computer-mouse: "\f8cc",
    icon-computer-speaker: "\f8b2",
    icon-computer: "\e4e5",
    icon-concierge-bell: "\f562",
    icon-confluence: "\f78d",
    icon-connectdevelop: "\f20e",
    icon-construction: "\f85d",
    icon-contact-book: "\f2b9",
    icon-contact-card: "\f2bb",
    icon-container-storage: "\f4b7",
    icon-contao: "\f26d",
    icon-conveyor-belt-alt: "\f46f",
    icon-conveyor-belt-boxes: "\f46f",
    icon-conveyor-belt-empty: "\e150",
    icon-conveyor-belt: "\f46e",
    icon-cookie-bite: "\f564",
    icon-cookie: "\f563",
    icon-copy: "\f0c5",
    icon-copyright: "\f1f9",
    icon-corn: "\f6c7",
    icon-corner: "\e3fe",
    icon-cotton-bureau: "\f89e",
    icon-couch-small: "\f4cc",
    icon-couch: "\f4b8",
    icon-cow: "\f6c8",
    icon-cowbell-circle-plus: "\f8b4",
    icon-cowbell-more: "\f8b4",
    icon-cowbell: "\f8b3",
    icon-cpanel: "\f388",
    icon-crab: "\e3ff",
    icon-crate-apple: "\f6b1",
    icon-crate-empty: "\e151",
    icon-creative-commons-by: "\f4e7",
    icon-creative-commons-nc-eu: "\f4e9",
    icon-creative-commons-nc-jp: "\f4ea",
    icon-creative-commons-nc: "\f4e8",
    icon-creative-commons-nd: "\f4eb",
    icon-creative-commons-pd-alt: "\f4ed",
    icon-creative-commons-pd: "\f4ec",
    icon-creative-commons-remix: "\f4ee",
    icon-creative-commons-sa: "\f4ef",
    icon-creative-commons-sampling-plus: "\f4f1",
    icon-creative-commons-sampling: "\f4f0",
    icon-creative-commons-share: "\f4f2",
    icon-creative-commons-zero: "\f4f3",
    icon-creative-commons: "\f25e",
    icon-credit-card-alt: "\f09d",
    icon-credit-card-blank: "\f389",
    icon-credit-card-front: "\f38a",
    icon-credit-card: "\f09d",
    icon-creemee: "\e400",
    icon-cricket-bat-ball: "\f449",
    icon-cricket: "\f449",
    icon-critical-role: "\f6c9",
    icon-croissant: "\f7f6",
    icon-crop-alt: "\f565",
    icon-crop-simple: "\f565",
    icon-crop: "\f125",
    icon-cross: "\f654",
    icon-crosshairs-simple: "\e59f",
    icon-crosshairs: "\f05b",
    icon-crow: "\f520",
    icon-crown: "\f521",
    icon-crutch: "\f7f7",
    icon-crutches: "\f7f8",
    icon-cruzeiro-sign: "\e152",
    icon-crystal-ball: "\e362",
    icon-css3-alt: "\f38b",
    icon-css3: "\f13c",
    icon-cube: "\f1b2",
    icon-cubes-stacked: "\e4e6",
    icon-cubes: "\f1b3",
    icon-cucumber: "\e401",
    icon-cup-straw-swoosh: "\e364",
    icon-cup-straw: "\e363",
    icon-cup-togo: "\f6c5",
    icon-cupcake: "\e402",
    icon-curling-stone: "\f44a",
    icon-curling: "\f44a",
    icon-custard: "\e403",
    icon-cut: "\f0c4",
    icon-cutlery: "\f2e7",
    icon-cuttlefish: "\f38c",
    icon-d-and-d-beyond: "\f6ca",
    icon-d-and-d: "\f38d",
    icon-d: "\44",
    icon-dagger: "\f6cb",
    icon-dailymotion: "\e052",
    icon-dash: "\e404",
    icon-dashboard: "\f624",
    icon-dashcube: "\f210",
    icon-database: "\f1c0",
    icon-deaf: "\f2a4",
    icon-deafness: "\f2a4",
    icon-debug: "\f7f9",
    icon-dedent: "\f03b",
    icon-deer-rudolph: "\f78f",
    icon-deer: "\f78e",
    icon-deezer: "\e077",
    icon-delete-left: "\f55a",
    icon-delete-right: "\e154",
    icon-delicious: "\f1a5",
    icon-democrat: "\f747",
    icon-deploydog: "\f38e",
    icon-deskpro: "\f38f",
    icon-desktop-alt: "\f390",
    icon-desktop-arrow-down: "\e155",
    icon-desktop-code: "\e165",
    icon-desktop-medical: "\e166",
    icon-desktop-slash: "\e2fa",
    icon-desktop: "\f390",
    icon-dev: "\f6cc",
    icon-deviantart: "\f1bd",
    icon-dewpoint: "\f748",
    icon-dharmachakra: "\f655",
    icon-dhl: "\f790",
    icon-diagnoses: "\f470",
    icon-diagram-cells: "\e475",
    icon-diagram-lean-canvas: "\e156",
    icon-diagram-nested: "\e157",
    icon-diagram-next: "\e476",
    icon-diagram-predecessor: "\e477",
    icon-diagram-previous: "\e478",
    icon-diagram-project: "\f542",
    icon-diagram-sankey: "\e158",
    icon-diagram-subtask: "\e479",
    icon-diagram-successor: "\e47a",
    icon-diagram-venn: "\e15a",
    icon-dial-high: "\e15c",
    icon-dial-low: "\e15d",
    icon-dial-max: "\e15e",
    icon-dial-med-high: "\e15b",
    icon-dial-med-low: "\e160",
    icon-dial-med: "\e15f",
    icon-dial-min: "\e161",
    icon-dial-off: "\e162",
    icon-dial: "\e15b",
    icon-diamond-exclamation: "\e405",
    icon-diamond-half-stroke: "\e5b8",
    icon-diamond-half: "\e5b7",
    icon-diamond-turn-right: "\f5eb",
    icon-diamond: "\f219",
    icon-diaspora: "\f791",
    icon-dice-d10: "\f6cd",
    icon-dice-d12: "\f6ce",
    icon-dice-d20: "\f6cf",
    icon-dice-d4: "\f6d0",
    icon-dice-d6: "\f6d1",
    icon-dice-d8: "\f6d2",
    icon-dice-five: "\f523",
    icon-dice-four: "\f524",
    icon-dice-one: "\f525",
    icon-dice-six: "\f526",
    icon-dice-three: "\f527",
    icon-dice-two: "\f528",
    icon-dice: "\f522",
    icon-digg: "\f1a6",
    icon-digging: "\f85e",
    icon-digital-ocean: "\f391",
    icon-digital-tachograph: "\f566",
    icon-diploma: "\f5ea",
    icon-directions: "\f5eb",
    icon-disc-drive: "\f8b5",
    icon-discord: "\f392",
    icon-discourse: "\f393",
    icon-disease: "\f7fa",
    icon-display-arrow-down: "\e164",
    icon-display-code: "\e165",
    icon-display-medical: "\e166",
    icon-display-slash: "\e2fa",
    icon-display: "\e163",
    icon-distribute-spacing-horizontal: "\e365",
    icon-distribute-spacing-vertical: "\e366",
    icon-ditto: "\22",
    icon-divide: "\f529",
    icon-dizzy: "\f567",
    icon-dna: "\f471",
    icon-do-not-enter: "\f5ec",
    icon-dochub: "\f394",
    icon-docker: "\f395",
    icon-dog-leashed: "\f6d4",
    icon-dog: "\f6d3",
    icon-dollar-circle: "\f2e8",
    icon-dollar-sign: "\24",
    icon-dollar-square: "\f2e9",
    icon-dollar: "\24",
    icon-dolly-box: "\f472",
    icon-dolly-empty: "\f473",
    icon-dolly-flatbed-alt: "\f475",
    icon-dolly-flatbed-empty: "\f476",
    icon-dolly-flatbed: "\f474",
    icon-dolly: "\f472",
    icon-dolphin: "\e168",
    icon-donate: "\f4b9",
    icon-dong-sign: "\e169",
    icon-donut: "\e406",
    icon-door-closed: "\f52a",
    icon-door-open: "\f52b",
    icon-dot-circle: "\f192",
    icon-doughnut: "\e406",
    icon-dove: "\f4ba",
    icon-down-from-dotted-line: "\e407",
    icon-down-from-line: "\f349",
    icon-down-left-and-up-right-to-center: "\f422",
    icon-down-left: "\e16a",
    icon-down-long: "\f309",
    icon-down-right: "\e16b",
    icon-down-to-bracket: "\e4e7",
    icon-down-to-dotted-line: "\e408",
    icon-down-to-line: "\f34a",
    icon-down: "\f354",
    icon-download: "\f019",
    icon-draft2digital: "\f396",
    icon-drafting-compass: "\f568",
    icon-dragon: "\f6d5",
    icon-draw-circle: "\f5ed",
    icon-draw-polygon: "\f5ee",
    icon-draw-square: "\f5ef",
    icon-dreidel: "\f792",
    icon-dribbble-square: "\f397",
    icon-dribbble: "\f17d",
    icon-drivers-license: "\f2c2",
    icon-drone-alt: "\f860",
    icon-drone-front: "\f860",
    icon-drone: "\f85f",
    icon-dropbox: "\f16b",
    icon-droplet-degree: "\f748",
    icon-droplet-percent: "\f750",
    icon-droplet-slash: "\f5c7",
    icon-droplet: "\f043",
    icon-drum-steelpan: "\f56a",
    icon-drum: "\f569",
    icon-drumstick-bite: "\f6d7",
    icon-drumstick: "\f6d6",
    icon-drupal: "\f1a9",
    icon-dryer-alt: "\f862",
    icon-dryer-heat: "\f862",
    icon-dryer: "\f861",
    icon-duck: "\f6d8",
    icon-dumbbell: "\f44b",
    icon-dumpster-fire: "\f794",
    icon-dumpster: "\f793",
    icon-dungeon: "\f6d9",
    icon-dyalog: "\f399",
    icon-e: "\45",
    icon-ear-deaf: "\f2a4",
    icon-ear-listen: "\f2a2",
    icon-ear-muffs: "\f795",
    icon-ear: "\f5f0",
    icon-earlybirds: "\f39a",
    icon-earth-africa: "\f57c",
    icon-earth-america: "\f57d",
    icon-earth-americas: "\f57d",
    icon-earth-asia: "\f57e",
    icon-earth-europe: "\f7a2",
    icon-earth-oceania: "\e47b",
    icon-earth: "\f57d",
    icon-ebay: "\f4f4",
    icon-eclipse-alt: "\f74a",
    icon-eclipse: "\f749",
    icon-edge-legacy: "\e078",
    icon-edge: "\f282",
    icon-edit: "\f044",
    icon-egg-fried: "\f7fc",
    icon-egg: "\f7fb",
    icon-eggplant: "\e16c",
    icon-eject: "\f052",
    icon-elementor: "\f430",
    icon-elephant: "\f6da",
    icon-elevator: "\e16d",
    icon-ellipsis-h-alt: "\f39b",
    icon-ellipsis-h: "\f141",
    icon-ellipsis-stroke-vertical: "\f39c",
    icon-ellipsis-stroke: "\f39b",
    icon-ellipsis-v-alt: "\f39c",
    icon-ellipsis-v: "\f142",
    icon-ellipsis-vertical: "\f142",
    icon-ellipsis: "\f141",
    icon-ello: "\f5f1",
    icon-ember: "\f423",
    icon-empire: "\f1d1",
    icon-empty-set: "\f656",
    icon-engine-exclamation: "\f5f2",
    icon-engine-warning: "\f5f2",
    icon-engine: "\e16e",
    icon-envelope-badge: "\e16f",
    icon-envelope-circle-check: "\e4e8",
    icon-envelope-circle: "\e10c",
    icon-envelope-dot: "\e16f",
    icon-envelope-open-dollar: "\f657",
    icon-envelope-open-text: "\f658",
    icon-envelope-open: "\f2b6",
    icon-envelope-square: "\f199",
    icon-envelope: "\f0e0",
    icon-envelopes-bulk: "\f674",
    icon-envelopes: "\e170",
    icon-envira: "\f299",
    icon-equals: "\3d",
    icon-eraser: "\f12d",
    icon-erlang: "\f39d",
    icon-escalator: "\e171",
    icon-ethereum: "\f42e",
    icon-ethernet: "\f796",
    icon-etsy: "\f2d7",
    icon-eur: "\f153",
    icon-euro-sign: "\f153",
    icon-euro: "\f153",
    icon-evernote: "\f839",
    icon-exchange-alt: "\f362",
    icon-exchange: "\f0ec",
    icon-exclamation-circle: "\f06a",
    icon-exclamation-square: "\f321",
    icon-exclamation-triangle: "\f071",
    icon-exclamation: "\21",
    icon-expand-alt: "\f424",
    icon-expand-arrows-alt: "\f31e",
    icon-expand-arrows: "\f31d",
    icon-expand-wide: "\f320",
    icon-expand: "\f065",
    icon-expeditedssl: "\f23e",
    icon-exploding-head: "\e2fe",
    icon-explosion: "\e4e9",
    icon-external-link-alt: "\f35d",
    icon-external-link-square-alt: "\f360",
    icon-external-link-square: "\f14c",
    icon-external-link: "\f08e",
    icon-eye-dropper-empty: "\f1fb",
    icon-eye-dropper-full: "\e172",
    icon-eye-dropper-half: "\e173",
    icon-eye-dropper: "\f1fb",
    icon-eye-evil: "\f6db",
    icon-eye-low-vision: "\f2a8",
    icon-eye-slash: "\f070",
    icon-eye: "\f06e",
    icon-eyedropper: "\f1fb",
    icon-eyes: "\e367",
    icon-f: "\46",
    icon-face-angry-horns: "\e368",
    icon-face-angry: "\f556",
    icon-face-anguished: "\e369",
    icon-face-anxious-sweat: "\e36a",
    icon-face-astonished: "\e36b",
    icon-face-awesome: "\e409",
    icon-face-beam-hand-over-mouth: "\e47c",
    icon-face-clouds: "\e47d",
    icon-face-confounded: "\e36c",
    icon-face-confused: "\e36d",
    icon-face-cowboy-hat: "\e36e",
    icon-face-diagonal-mouth: "\e47e",
    icon-face-disappointed: "\e36f",
    icon-face-disguise: "\e370",
    icon-face-dizzy: "\f567",
    icon-face-dotted: "\e47f",
    icon-face-downcast-sweat: "\e371",
    icon-face-drooling: "\e372",
    icon-face-exhaling: "\e480",
    icon-face-explode: "\e2fe",
    icon-face-expressionless: "\e373",
    icon-face-eyes-xmarks: "\e374",
    icon-face-fearful: "\e375",
    icon-face-flushed: "\f579",
    icon-face-frown-open: "\f57a",
    icon-face-frown-slight: "\e376",
    icon-face-frown: "\f119",
    icon-face-glasses: "\e377",
    icon-face-grimace: "\f57f",
    icon-face-grin-beam-sweat: "\f583",
    icon-face-grin-beam: "\f582",
    icon-face-grin-hearts: "\f584",
    icon-face-grin-squint-tears: "\f586",
    icon-face-grin-squint: "\f585",
    icon-face-grin-stars: "\f587",
    icon-face-grin-tears: "\f588",
    icon-face-grin-tongue-squint: "\f58a",
    icon-face-grin-tongue-wink: "\f58b",
    icon-face-grin-tongue: "\f589",
    icon-face-grin-wide: "\f581",
    icon-face-grin-wink: "\f58c",
    icon-face-grin: "\f580",
    icon-face-hand-over-mouth: "\e378",
    icon-face-hand-peeking: "\e481",
    icon-face-hand-yawn: "\e379",
    icon-face-head-bandage: "\e37a",
    icon-face-holding-back-tears: "\e482",
    icon-face-hushed: "\e37b",
    icon-face-icicles: "\e37c",
    icon-face-kiss-beam: "\f597",
    icon-face-kiss-closed-eyes: "\e37d",
    icon-face-kiss-wink-heart: "\f598",
    icon-face-kiss: "\f596",
    icon-face-laugh-beam: "\f59a",
    icon-face-laugh-squint: "\f59b",
    icon-face-laugh-wink: "\f59c",
    icon-face-laugh: "\f599",
    icon-face-lying: "\e37e",
    icon-face-mask: "\e37f",
    icon-face-meh-blank: "\f5a4",
    icon-face-meh: "\f11a",
    icon-face-melting: "\e483",
    icon-face-monocle: "\e380",
    icon-face-nauseated: "\e381",
    icon-face-nose-steam: "\e382",
    icon-face-party: "\e383",
    icon-face-pensive: "\e384",
    icon-face-persevering: "\e385",
    icon-face-pleading: "\e386",
    icon-face-pouting: "\e387",
    icon-face-raised-eyebrow: "\e388",
    icon-face-relieved: "\e389",
    icon-face-rolling-eyes: "\f5a5",
    icon-face-sad-cry: "\f5b3",
    icon-face-sad-sweat: "\e38a",
    icon-face-sad-tear: "\f5b4",
    icon-face-saluting: "\e484",
    icon-face-scream: "\e38b",
    icon-face-shush: "\e38c",
    icon-face-sleeping: "\e38d",
    icon-face-sleepy: "\e38e",
    icon-face-smile-beam: "\f5b8",
    icon-face-smile-halo: "\e38f",
    icon-face-smile-hearts: "\e390",
    icon-face-smile-horns: "\e391",
    icon-face-smile-plus: "\f5b9",
    icon-face-smile-relaxed: "\e392",
    icon-face-smile-tear: "\e393",
    icon-face-smile-tongue: "\e394",
    icon-face-smile-upside-down: "\e395",
    icon-face-smile-wink: "\f4da",
    icon-face-smile: "\f118",
    icon-face-smiling-hands: "\e396",
    icon-face-smirking: "\e397",
    icon-face-spiral-eyes: "\e485",
    icon-face-sunglasses: "\e398",
    icon-face-surprise: "\f5c2",
    icon-face-swear: "\e399",
    icon-face-thermometer: "\e39a",
    icon-face-thinking: "\e39b",
    icon-face-tired: "\f5c8",
    icon-face-tissue: "\e39c",
    icon-face-tongue-money: "\e39d",
    icon-face-tongue-sweat: "\e39e",
    icon-face-unamused: "\e39f",
    icon-face-viewfinder: "\e2ff",
    icon-face-vomit: "\e3a0",
    icon-face-weary: "\e3a1",
    icon-face-woozy: "\e3a2",
    icon-face-worried: "\e3a3",
    icon-face-zany: "\e3a4",
    icon-face-zipper: "\e3a5",
    icon-facebook-f: "\f39e",
    icon-facebook-messenger: "\f39f",
    icon-facebook-square: "\f082",
    icon-facebook: "\f09a",
    icon-falafel: "\e40a",
    icon-family-dress: "\e301",
    icon-family-pants: "\e302",
    icon-family: "\e300",
    icon-fan-table: "\e004",
    icon-fan: "\f863",
    icon-fantasy-flight-games: "\f6dc",
    icon-farm: "\f864",
    icon-fast-backward: "\f049",
    icon-fast-forward: "\f050",
    icon-faucet-drip: "\e006",
    icon-faucet: "\e005",
    icon-fax: "\f1ac",
    icon-feather-alt: "\f56b",
    icon-feather-pointed: "\f56b",
    icon-feather: "\f52d",
    icon-fedex: "\f797",
    icon-fedora: "\f798",
    icon-feed: "\f09e",
    icon-female: "\f182",
    icon-fence: "\e303",
    icon-ferris-wheel: "\e174",
    icon-ferry: "\e4ea",
    icon-field-hockey-stick-ball: "\f44c",
    icon-field-hockey: "\f44c",
    icon-fighter-jet: "\f0fb",
    icon-figma: "\f799",
    icon-file-alt: "\f15c",
    icon-file-archive: "\f1c6",
    icon-file-arrow-down: "\f56d",
    icon-file-arrow-up: "\f574",
    icon-file-audio: "\f1c7",
    icon-file-award: "\f5f3",
    icon-file-binary: "\e175",
    icon-file-caret-down: "\e429",
    icon-file-caret-up: "\e42a",
    icon-file-certificate: "\f5f3",
    icon-file-chart-column: "\f659",
    icon-file-chart-line: "\f659",
    icon-file-chart-pie: "\f65a",
    icon-file-check: "\f316",
    icon-file-circle-check: "\e5a0",
    icon-file-circle-exclamation: "\e4eb",
    icon-file-circle-info: "\e493",
    icon-file-circle-minus: "\e4ed",
    icon-file-circle-plus: "\e494",
    icon-file-circle-question: "\e4ef",
    icon-file-circle-xmark: "\e5a1",
    icon-file-clipboard: "\f0ea",
    icon-file-code: "\f1c9",
    icon-file-contract: "\f56c",
    icon-file-csv: "\f6dd",
    icon-file-dashed-line: "\f877",
    icon-file-download: "\f56d",
    icon-file-edit: "\f31c",
    icon-file-excel: "\f1c3",
    icon-file-exclamation: "\f31a",
    icon-file-export: "\f56e",
    icon-file-heart: "\e176",
    icon-file-image: "\f1c5",
    icon-file-import: "\f56f",
    icon-file-invoice-dollar: "\f571",
    icon-file-invoice: "\f570",
    icon-file-lines: "\f15c",
    icon-file-lock: "\e3a6",
    icon-file-magnifying-glass: "\f865",
    icon-file-medical-alt: "\f478",
    icon-file-medical: "\f477",
    icon-file-minus: "\f318",
    icon-file-music: "\f8b6",
    icon-file-pdf: "\f1c1",
    icon-file-pen: "\f31c",
    icon-file-plus-minus: "\e177",
    icon-file-plus: "\f319",
    icon-file-powerpoint: "\f1c4",
    icon-file-prescription: "\f572",
    icon-file-search: "\f865",
    icon-file-shield: "\e4f0",
    icon-file-signature: "\f573",
    icon-file-slash: "\e3a7",
    icon-file-spreadsheet: "\f65b",
    icon-file-text: "\f15c",
    icon-file-times: "\f317",
    icon-file-upload: "\f574",
    icon-file-user: "\f65c",
    icon-file-video: "\f1c8",
    icon-file-waveform: "\f478",
    icon-file-word: "\f1c2",
    icon-file-xmark: "\f317",
    icon-file-zipper: "\f1c6",
    icon-file: "\f15b",
    icon-files-medical: "\f7fd",
    icon-files: "\e178",
    icon-fill-drip: "\f576",
    icon-fill: "\f575",
    icon-film-alt: "\f3a0",
    icon-film-canister: "\f8b7",
    icon-film-cannister: "\f8b7",
    icon-film-simple: "\f3a0",
    icon-film-slash: "\e179",
    icon-film: "\f008",
    icon-films: "\e17a",
    icon-filter-circle-dollar: "\f662",
    icon-filter-circle-xmark: "\e17b",
    icon-filter-list: "\e17c",
    icon-filter-slash: "\e17d",
    icon-filter: "\f0b0",
    icon-filters: "\e17e",
    icon-fingerprint: "\f577",
    icon-fire-alt: "\f7e4",
    icon-fire-burner: "\e4f1",
    icon-fire-extinguisher: "\f134",
    icon-fire-flame-curved: "\f7e4",
    icon-fire-flame-simple: "\f46a",
    icon-fire-flame: "\f6df",
    icon-fire-hydrant: "\e17f",
    icon-fire-smoke: "\f74b",
    icon-fire: "\f06d",
    icon-firefox-browser: "\e007",
    icon-firefox: "\f269",
    icon-fireplace: "\f79a",
    icon-firewall: "\e3dc",
    icon-first-aid: "\f479",
    icon-first-order-alt: "\f50a",
    icon-first-order: "\f2b0",
    icon-firstdraft: "\f3a1",
    icon-fish-bones: "\e304",
    icon-fish-cooked: "\f7fe",
    icon-fish-fins: "\e4f2",
    icon-fish: "\f578",
    icon-fishing-rod: "\e3a8",
    icon-fist-raised: "\f6de",
    icon-flag-alt: "\f74c",
    icon-flag-checkered: "\f11e",
    icon-flag-pennant: "\f456",
    icon-flag-swallowtail: "\f74c",
    icon-flag-usa: "\f74d",
    icon-flag: "\f024",
    icon-flame: "\f6df",
    icon-flashlight: "\f8b8",
    icon-flask-poison: "\f6e0",
    icon-flask-potion: "\f6e1",
    icon-flask-round-poison: "\f6e0",
    icon-flask-round-potion: "\f6e1",
    icon-flask-vial: "\e4f3",
    icon-flask: "\f0c3",
    icon-flatbread-stuffed: "\e40c",
    icon-flatbread: "\e40b",
    icon-flickr: "\f16e",
    icon-flipboard: "\f44d",
    icon-floppy-disk-circle-arrow-right: "\e180",
    icon-floppy-disk-circle-xmark: "\e181",
    icon-floppy-disk-pen: "\e182",
    icon-floppy-disk-times: "\e181",
    icon-floppy-disk: "\f0c7",
    icon-floppy-disks: "\e183",
    icon-florin-sign: "\e184",
    icon-flower-daffodil: "\f800",
    icon-flower-tulip: "\f801",
    icon-flower: "\f7ff",
    icon-flushed: "\f579",
    icon-flute: "\f8b9",
    icon-flux-capacitor: "\f8ba",
    icon-fly: "\f417",
    icon-flying-disc: "\e3a9",
    icon-fog: "\f74e",
    icon-folder-arrow-down: "\e053",
    icon-folder-arrow-up: "\e054",
    icon-folder-blank: "\f07b",
    icon-folder-bookmark: "\e186",
    icon-folder-closed: "\e185",
    icon-folder-cog: "\e187",
    icon-folder-download: "\e053",
    icon-folder-gear: "\e187",
    icon-folder-grid: "\e188",
    icon-folder-heart: "\e189",
    icon-folder-image: "\e18a",
    icon-folder-magnifying-glass: "\e18b",
    icon-folder-medical: "\e18c",
    icon-folder-minus: "\f65d",
    icon-folder-music: "\e18d",
    icon-folder-open: "\f07c",
    icon-folder-plus: "\f65e",
    icon-folder-search: "\e18b",
    icon-folder-times: "\f65f",
    icon-folder-tree: "\f802",
    icon-folder-upload: "\e054",
    icon-folder-user: "\e18e",
    icon-folder-xmark: "\f65f",
    icon-folder: "\f07b",
    icon-folders: "\f660",
    icon-fondue-pot: "\e40d",
    icon-font-awesome-alt: "\f35c",
    icon-font-awesome-flag: "\f2b4",
    icon-font-awesome-logo-full: "\f2b4",
    icon-font-awesome: "\f2b4",
    icon-font-case: "\f866",
    icon-font: "\f031",
    icon-fonticons-fi: "\f3a2",
    icon-fonticons: "\f280",
    icon-football-ball: "\f44e",
    icon-football-helmet: "\f44f",
    icon-football: "\f44e",
    icon-fork-knife: "\f2e6",
    icon-fork: "\f2e3",
    icon-forklift: "\f47a",
    icon-fort-awesome-alt: "\f3a3",
    icon-fort-awesome: "\f286",
    icon-fort: "\e486",
    icon-forumbee: "\f211",
    icon-forward-fast: "\f050",
    icon-forward-step: "\f051",
    icon-forward: "\f04e",
    icon-foursquare: "\f180",
    icon-fragile: "\f4bb",
    icon-frame: "\e495",
    icon-franc-sign: "\e18f",
    icon-free-code-camp: "\f2c5",
    icon-freebsd: "\f3a4",
    icon-french-fries: "\f803",
    icon-frog: "\f52e",
    icon-frosty-head: "\f79b",
    icon-frown-open: "\f57a",
    icon-frown: "\f119",
    icon-fulcrum: "\f50b",
    icon-function: "\f661",
    icon-funnel-dollar: "\f662",
    icon-futbol-ball: "\f1e3",
    icon-futbol: "\f1e3",
    icon-g: "\47",
    icon-galactic-republic: "\f50c",
    icon-galactic-senate: "\f50d",
    icon-galaxy: "\e008",
    icon-gallery-thumbnails: "\e3aa",
    icon-game-board-alt: "\f868",
    icon-game-board-simple: "\f868",
    icon-game-board: "\f867",
    icon-game-console-handheld-crank: "\e5b9",
    icon-game-console-handheld: "\f8bb",
    icon-gamepad-alt: "\e5a2",
    icon-gamepad-modern: "\e5a2",
    icon-gamepad: "\f11b",
    icon-garage-car: "\e00a",
    icon-garage-open: "\e00b",
    icon-garage: "\e009",
    icon-garlic: "\e40e",
    icon-gas-pump-slash: "\f5f4",
    icon-gas-pump: "\f52f",
    icon-gauge-circle-bolt: "\e496",
    icon-gauge-circle-minus: "\e497",
    icon-gauge-circle-plus: "\e498",
    icon-gauge-high: "\f625",
    icon-gauge-low: "\f627",
    icon-gauge-max: "\f626",
    icon-gauge-med: "\f624",
    icon-gauge-min: "\f628",
    icon-gauge-simple-high: "\f62a",
    icon-gauge-simple-low: "\f62c",
    icon-gauge-simple-max: "\f62b",
    icon-gauge-simple-med: "\f629",
    icon-gauge-simple-min: "\f62d",
    icon-gauge-simple: "\f629",
    icon-gauge: "\f624",
    icon-gave-dandy: "\e409",
    icon-gavel: "\f0e3",
    icon-gbp: "\f154",
    icon-gear: "\f013",
    icon-gears: "\f085",
    icon-gem: "\f3a5",
    icon-genderless: "\f22d",
    icon-get-pocket: "\f265",
    icon-gg-circle: "\f261",
    icon-gg: "\f260",
    icon-ghost: "\f6e2",
    icon-gif: "\e190",
    icon-gift-card: "\f663",
    icon-gift: "\f06b",
    icon-gifts: "\f79c",
    icon-gingerbread-man: "\f79d",
    icon-git-alt: "\f841",
    icon-git-square: "\f1d2",
    icon-git: "\f1d3",
    icon-github-alt: "\f113",
    icon-github-square: "\f092",
    icon-github: "\f09b",
    icon-gitkraken: "\f3a6",
    icon-gitlab-square: "\e5ae",
    icon-gitlab: "\f296",
    icon-gitter: "\f426",
    icon-glass-champagne: "\f79e",
    icon-glass-cheers: "\f79f",
    icon-glass-citrus: "\f869",
    icon-glass-empty: "\e191",
    icon-glass-half-empty: "\e192",
    icon-glass-half-full: "\e192",
    icon-glass-half: "\e192",
    icon-glass-martini-alt: "\f57b",
    icon-glass-martini: "\f000",
    icon-glass-water-droplet: "\e4f5",
    icon-glass-water: "\e4f4",
    icon-glass-whiskey-rocks: "\f7a1",
    icon-glass-whiskey: "\f7a0",
    icon-glass: "\f804",
    icon-glasses-alt: "\f5f5",
    icon-glasses-round: "\f5f5",
    icon-glasses: "\f530",
    icon-glide-g: "\f2a6",
    icon-glide: "\f2a5",
    icon-globe-africa: "\f57c",
    icon-globe-americas: "\f57d",
    icon-globe-asia: "\f57e",
    icon-globe-europe: "\f7a2",
    icon-globe-oceania: "\e47b",
    icon-globe-snow: "\f7a3",
    icon-globe-stand: "\f5f6",
    icon-globe: "\f0ac",
    icon-glove-boxing: "\f438",
    icon-goal-net: "\e3ab",
    icon-gofore: "\f3a7",
    icon-golang: "\e40f",
    icon-golf-ball-tee: "\f450",
    icon-golf-ball: "\f450",
    icon-golf-club: "\f451",
    icon-golf-flag-hole: "\e3ac",
    icon-goodreads-g: "\f3a9",
    icon-goodreads: "\f3a8",
    icon-google-drive: "\f3aa",
    icon-google-pay: "\e079",
    icon-google-play: "\f3ab",
    icon-google-plus-g: "\f0d5",
    icon-google-plus-square: "\f0d4",
    icon-google-plus: "\f2b3",
    icon-google-wallet: "\f1ee",
    icon-google: "\f1a0",
    icon-gopuram: "\f664",
    icon-graduation-cap: "\f19d",
    icon-gramophone: "\f8bd",
    icon-grapes: "\e306",
    icon-grate-droplet: "\e194",
    icon-grate: "\e193",
    icon-gratipay: "\f184",
    icon-grav: "\f2d6",
    icon-greater-than-equal: "\f532",
    icon-greater-than: "\3e",
    icon-grid-2-plus: "\e197",
    icon-grid-2: "\e196",
    icon-grid-3: "\e195",
    icon-grid-4: "\e198",
    icon-grid-5: "\e199",
    icon-grid-dividers: "\e3ad",
    icon-grid-horizontal: "\e307",
    icon-grid: "\e195",
    icon-grill-fire: "\e5a4",
    icon-grill-hot: "\e5a5",
    icon-grill: "\e5a3",
    icon-grimace: "\f57f",
    icon-grin-alt: "\f581",
    icon-grin-beam-sweat: "\f583",
    icon-grin-beam: "\f582",
    icon-grin-hearts: "\f584",
    icon-grin-squint-tears: "\f586",
    icon-grin-squint: "\f585",
    icon-grin-stars: "\f587",
    icon-grin-tears: "\f588",
    icon-grin-tongue-squint: "\f58a",
    icon-grin-tongue-wink: "\f58b",
    icon-grin-tongue: "\f589",
    icon-grin-wink: "\f58c",
    icon-grin: "\f580",
    icon-grip-dots-vertical: "\e411",
    icon-grip-dots: "\e410",
    icon-grip-horizontal: "\f58d",
    icon-grip-lines-vertical: "\f7a5",
    icon-grip-lines: "\f7a4",
    icon-grip-vertical: "\f58e",
    icon-grip: "\f58d",
    icon-gripfire: "\f3ac",
    icon-group-arrows-rotate: "\e4f6",
    icon-grunt: "\f3ad",
    icon-guarani-sign: "\e19a",
    icon-guilded: "\e07e",
    icon-guitar-electric: "\f8be",
    icon-guitar: "\f7a6",
    icon-guitars: "\f8bf",
    icon-gulp: "\f3ae",
    icon-gun-slash: "\e19c",
    icon-gun-squirt: "\e19d",
    icon-gun: "\e19b",
    icon-h-square: "\f0fd",
    icon-h1: "\f313",
    icon-h2: "\f314",
    icon-h3: "\f315",
    icon-h4: "\f86a",
    icon-h5: "\e412",
    icon-h6: "\e413",
    icon-h: "\48",
    icon-hacker-news-square: "\f3af",
    icon-hacker-news: "\f1d4",
    icon-hackerrank: "\f5f7",
    icon-hamburger: "\f805",
    icon-hammer-crash: "\e414",
    icon-hammer-war: "\f6e4",
    icon-hammer: "\f6e3",
    icon-hamsa: "\f665",
    icon-hand-back-fist: "\f255",
    icon-hand-back-point-down: "\e19e",
    icon-hand-back-point-left: "\e19f",
    icon-hand-back-point-ribbon: "\e1a0",
    icon-hand-back-point-right: "\e1a1",
    icon-hand-back-point-up: "\e1a2",
    icon-hand-dots: "\f461",
    icon-hand-fingers-crossed: "\e1a3",
    icon-hand-fist: "\f6de",
    icon-hand-heart: "\f4bc",
    icon-hand-holding-box: "\f47b",
    icon-hand-holding-dollar: "\f4c0",
    icon-hand-holding-droplet: "\f4c1",
    icon-hand-holding-hand: "\e4f7",
    icon-hand-holding-heart: "\f4be",
    icon-hand-holding-magic: "\f6e5",
    icon-hand-holding-medical: "\e05c",
    icon-hand-holding-seedling: "\f4bf",
    icon-hand-holding-skull: "\e1a4",
    icon-hand-holding-usd: "\f4c0",
    icon-hand-holding-water: "\f4c1",
    icon-hand-holding: "\f4bd",
    icon-hand-horns: "\e1a9",
    icon-hand-lizard: "\f258",
    icon-hand-love: "\e1a5",
    icon-hand-middle-finger: "\f806",
    icon-hand-paper: "\f256",
    icon-hand-peace: "\f25b",
    icon-hand-point-down: "\f0a7",
    icon-hand-point-left: "\f0a5",
    icon-hand-point-ribbon: "\e1a6",
    icon-hand-point-right: "\f0a4",
    icon-hand-point-up: "\f0a6",
    icon-hand-pointer: "\f25a",
    icon-hand-receiving: "\f47c",
    icon-hand-rock: "\f255",
    icon-hand-scissors: "\f257",
    icon-hand-sparkles: "\e05d",
    icon-hand-spock: "\f259",
    icon-hand-wave: "\e1a7",
    icon-hand: "\f256",
    icon-handcuffs: "\e4f8",
    icon-hands-american-sign-language-interpreting: "\f2a3",
    icon-hands-asl-interpreting: "\f2a3",
    icon-hands-bound: "\e4f9",
    icon-hands-bubbles: "\e05e",
    icon-hands-clapping: "\e1a8",
    icon-hands-heart: "\f4c3",
    icon-hands-helping: "\f4c4",
    icon-hands-holding-child: "\e4fa",
    icon-hands-holding-circle: "\e4fb",
    icon-hands-holding-diamond: "\f47c",
    icon-hands-holding-dollar: "\f4c5",
    icon-hands-holding-heart: "\f4c3",
    icon-hands-holding: "\f4c2",
    icon-hands-praying: "\f684",
    icon-hands-usd: "\f4c5",
    icon-hands-wash: "\e05e",
    icon-hands: "\f2a7",
    icon-handshake-alt-slash: "\e05f",
    icon-handshake-alt: "\f4c6",
    icon-handshake-angle: "\f4c4",
    icon-handshake-simple-slash: "\e05f",
    icon-handshake-simple: "\f4c6",
    icon-handshake-slash: "\e060",
    icon-handshake: "\f2b5",
    icon-hanukiah: "\f6e6",
    icon-hard-drive: "\f0a0",
    icon-hard-hat: "\f807",
    icon-hard-of-hearing: "\f2a4",
    icon-hashnode: "\e499",
    icon-hashtag-lock: "\e415",
    icon-hashtag: "\23",
    icon-hat-chef: "\f86b",
    icon-hat-cowboy-side: "\f8c1",
    icon-hat-cowboy: "\f8c0",
    icon-hat-hard: "\f807",
    icon-hat-santa: "\f7a7",
    icon-hat-winter: "\f7a8",
    icon-hat-witch: "\f6e7",
    icon-hat-wizard: "\f6e8",
    icon-haykal: "\f666",
    icon-hdd: "\f0a0",
    icon-head-side-brain: "\f808",
    icon-head-side-cough-slash: "\e062",
    icon-head-side-cough: "\e061",
    icon-head-side-goggles: "\f6ea",
    icon-head-side-headphones: "\f8c2",
    icon-head-side-heart: "\e1aa",
    icon-head-side-mask: "\e063",
    icon-head-side-medical: "\f809",
    icon-head-side-virus: "\e064",
    icon-head-side: "\f6e9",
    icon-head-vr: "\f6ea",
    icon-header: "\f1dc",
    icon-heading: "\f1dc",
    icon-headphones-alt: "\f58f",
    icon-headphones-simple: "\f58f",
    icon-headphones: "\f025",
    icon-headset: "\f590",
    icon-heart-broken: "\f7a9",
    icon-heart-circle-bolt: "\e4fc",
    icon-heart-circle-check: "\e4fd",
    icon-heart-circle-exclamation: "\e4fe",
    icon-heart-circle-minus: "\e4ff",
    icon-heart-circle-plus: "\e500",
    icon-heart-circle-xmark: "\e501",
    icon-heart-circle: "\f4c7",
    icon-heart-crack: "\f7a9",
    icon-heart-half-alt: "\e1ac",
    icon-heart-half-stroke: "\e1ac",
    icon-heart-half: "\e1ab",
    icon-heart-music-camera-bolt: "\f86d",
    icon-heart-pulse: "\f21e",
    icon-heart-rate: "\f5f8",
    icon-heart-square: "\f4c8",
    icon-heart: "\f004",
    icon-heartbeat: "\f21e",
    icon-heat: "\e00c",
    icon-helicopter-symbol: "\e502",
    icon-helicopter: "\f533",
    icon-helmet-battle: "\f6eb",
    icon-helmet-safety: "\f807",
    icon-helmet-un: "\e503",
    icon-hexagon-check: "\e416",
    icon-hexagon-divide: "\e1ad",
    icon-hexagon-exclamation: "\e417",
    icon-hexagon-image: "\e504",
    icon-hexagon-minus: "\f307",
    icon-hexagon-plus: "\f300",
    icon-hexagon-vertical-nft-slanted: "\e506",
    icon-hexagon-vertical-nft: "\e505",
    icon-hexagon-xmark: "\f2ee",
    icon-hexagon: "\f312",
    icon-high-definition: "\e1ae",
    icon-highlighter-line: "\e1af",
    icon-highlighter: "\f591",
    icon-hiking: "\f6ec",
    icon-hill-avalanche: "\e507",
    icon-hill-rockslide: "\e508",
    icon-hippo: "\f6ed",
    icon-hips: "\f452",
    icon-hire-a-helper: "\f3b0",
    icon-history: "\f1da",
    icon-hive: "\e07f",
    icon-hockey-mask: "\f6ee",
    icon-hockey-puck: "\f453",
    icon-hockey-stick-puck: "\e3ae",
    icon-hockey-sticks: "\f454",
    icon-holly-berry: "\f7aa",
    icon-home-alt: "\f015",
    icon-home-blank: "\e487",
    icon-home-heart: "\f4c9",
    icon-home-lg-alt: "\f015",
    icon-home-lg: "\e3af",
    icon-home-user: "\e1b0",
    icon-home: "\f015",
    icon-honey-pot: "\e418",
    icon-hood-cloak: "\f6ef",
    icon-hooli: "\f427",
    icon-horizontal-rule: "\f86c",
    icon-hornbill: "\f592",
    icon-horse-head: "\f7ab",
    icon-horse-saddle: "\f8c3",
    icon-horse: "\f6f0",
    icon-hose-reel: "\e41a",
    icon-hose: "\e419",
    icon-hospital-alt: "\f0f8",
    icon-hospital-symbol: "\f47e",
    icon-hospital-user: "\f80d",
    icon-hospital-wide: "\f0f8",
    icon-hospital: "\f0f8",
    icon-hospitals: "\f80e",
    icon-hot-tub-person: "\f593",
    icon-hot-tub: "\f593",
    icon-hotdog: "\f80f",
    icon-hotel: "\f594",
    icon-hotjar: "\f3b1",
    icon-hourglass-1: "\f251",
    icon-hourglass-2: "\f252",
    icon-hourglass-3: "\f253",
    icon-hourglass-clock: "\e41b",
    icon-hourglass-empty: "\f254",
    icon-hourglass-end: "\f253",
    icon-hourglass-half: "\f252",
    icon-hourglass-start: "\f251",
    icon-hourglass: "\f254",
    icon-house-blank: "\e487",
    icon-house-building: "\e1b1",
    icon-house-chimney-blank: "\e3b0",
    icon-house-chimney-crack: "\f6f1",
    icon-house-chimney-heart: "\e1b2",
    icon-house-chimney-medical: "\f7f2",
    icon-house-chimney-user: "\e065",
    icon-house-chimney-window: "\e00d",
    icon-house-chimney: "\e3af",
    icon-house-circle-check: "\e509",
    icon-house-circle-exclamation: "\e50a",
    icon-house-circle-xmark: "\e50b",
    icon-house-crack: "\e3b1",
    icon-house-damage: "\f6f1",
    icon-house-day: "\e00e",
    icon-house-fire: "\e50c",
    icon-house-flag: "\e50d",
    icon-house-flood-water-circle-arrow-right: "\e50f",
    icon-house-flood-water: "\e50e",
    icon-house-flood: "\f74f",
    icon-house-heart: "\f4c9",
    icon-house-laptop: "\e066",
    icon-house-leave: "\e00f",
    icon-house-lock: "\e510",
    icon-house-medical-circle-check: "\e511",
    icon-house-medical-circle-exclamation: "\e512",
    icon-house-medical-circle-xmark: "\e513",
    icon-house-medical-flag: "\e514",
    icon-house-medical: "\e3b2",
    icon-house-night: "\e010",
    icon-house-person-arrive: "\e011",
    icon-house-person-depart: "\e00f",
    icon-house-person-leave: "\e00f",
    icon-house-person-return: "\e011",
    icon-house-return: "\e011",
    icon-house-signal: "\e012",
    icon-house-tree: "\e1b3",
    icon-house-tsunami: "\e515",
    icon-house-turret: "\e1b4",
    icon-house-user: "\e1b0",
    icon-house-water: "\f74f",
    icon-house-window: "\e3b3",
    icon-house: "\f015",
    icon-houzz: "\f27c",
    icon-hryvnia-sign: "\f6f2",
    icon-hryvnia: "\f6f2",
    icon-html5: "\f13b",
    icon-hubspot: "\f3b2",
    icon-humidity: "\f750",
    icon-hundred-points: "\e41c",
    icon-hurricane: "\f751",
    icon-hyphen: "\2d",
    icon-i-cursor: "\f246",
    icon-i: "\49",
    icon-ice-cream: "\f810",
    icon-ice-skate: "\f7ac",
    icon-icicles: "\f7ad",
    icon-icons-alt: "\f86e",
    icon-icons: "\f86d",
    icon-id-badge: "\f2c1",
    icon-id-card-alt: "\f47f",
    icon-id-card-clip: "\f47f",
    icon-id-card: "\f2c2",
    icon-ideal: "\e013",
    icon-igloo: "\f7ae",
    icon-ils: "\f20b",
    icon-image-landscape: "\e1b5",
    icon-image-polaroid-user: "\e1b6",
    icon-image-polaroid: "\f8c4",
    icon-image-portrait: "\f3e0",
    icon-image-slash: "\e1b7",
    icon-image-user: "\e1b8",
    icon-image: "\f03e",
    icon-images-user: "\e1b9",
    icon-images: "\f302",
    icon-imdb: "\f2d8",
    icon-inbox-arrow-down: "\f310",
    icon-inbox-arrow-up: "\f311",
    icon-inbox-full: "\e1ba",
    icon-inbox-in: "\f310",
    icon-inbox-out: "\f311",
    icon-inbox: "\f01c",
    icon-inboxes: "\e1bb",
    icon-indent: "\f03c",
    icon-indian-rupee-sign: "\e1bc",
    icon-indian-rupee: "\e1bc",
    icon-industry-alt: "\f3b3",
    icon-industry-windows: "\f3b3",
    icon-industry: "\f275",
    icon-infinity: "\f534",
    icon-info-circle: "\f05a",
    icon-info-square: "\f30f",
    icon-info: "\f129",
    icon-inhaler: "\f5f9",
    icon-innosoft: "\e080",
    icon-input-numeric: "\e1bd",
    icon-input-pipe: "\e1be",
    icon-input-text: "\e1bf",
    icon-inr: "\e1bc",
    icon-instagram-square: "\e055",
    icon-instagram: "\f16d",
    icon-instalod: "\e081",
    icon-institution: "\f19c",
    icon-integral: "\f667",
    icon-intercom: "\f7af",
    icon-internet-explorer: "\f26b",
    icon-interrobang: "\e5ba",
    icon-intersection: "\f668",
    icon-inventory: "\f480",
    icon-invision: "\f7b0",
    icon-ioxhost: "\f208",
    icon-island-tree-palm: "\f811",
    icon-island-tropical: "\f811",
    icon-italic: "\f033",
    icon-itch-io: "\f83a",
    icon-itunes-note: "\f3b5",
    icon-itunes: "\f3b4",
    icon-j: "\4a",
    icon-jack-o-lantern: "\f30e",
    icon-jar-wheat: "\e517",
    icon-jar: "\e516",
    icon-java: "\f4e4",
    icon-jedi-order: "\f50e",
    icon-jedi: "\f669",
    icon-jenkins: "\f3b6",
    icon-jet-fighter-up: "\e518",
    icon-jet-fighter: "\f0fb",
    icon-jira: "\f7b1",
    icon-joget: "\f3b7",
    icon-joint: "\f595",
    icon-joomla: "\f1aa",
    icon-journal-whills: "\f66a",
    icon-joystick: "\f8c5",
    icon-jpy: "\f157",
    icon-js-square: "\f3b9",
    icon-js: "\f3b8",
    icon-jsfiddle: "\f1cc",
    icon-jug-detergent: "\e519",
    icon-jug: "\f8c6",
    icon-k: "\4b",
    icon-kaaba: "\f66b",
    icon-kaggle: "\f5fa",
    icon-kazoo: "\f8c7",
    icon-kerning: "\f86f",
    icon-key-skeleton-left-right: "\e3b4",
    icon-key-skeleton: "\f6f3",
    icon-key: "\f084",
    icon-keybase: "\f4f5",
    icon-keyboard-brightness-low: "\e1c1",
    icon-keyboard-brightness: "\e1c0",
    icon-keyboard-down: "\e1c2",
    icon-keyboard-left: "\e1c3",
    icon-keyboard: "\f11c",
    icon-keycdn: "\f3ba",
    icon-keynote: "\f66c",
    icon-khanda: "\f66d",
    icon-kickstarter-k: "\f3bc",
    icon-kickstarter: "\f3bb",
    icon-kidneys: "\f5fb",
    icon-kip-sign: "\e1c4",
    icon-kiss-beam: "\f597",
    icon-kiss-wink-heart: "\f598",
    icon-kiss: "\f596",
    icon-kit-medical: "\f479",
    icon-kitchen-set: "\e51a",
    icon-kite: "\f6f4",
    icon-kiwi-bird: "\f535",
    icon-kiwi-fruit: "\e30c",
    icon-knife-kitchen: "\f6f5",
    icon-knife: "\f2e4",
    icon-korvue: "\f42f",
    icon-krw: "\f159",
    icon-l: "\4c",
    icon-lacrosse-stick-ball: "\e3b6",
    icon-lacrosse-stick: "\e3b5",
    icon-ladder-water: "\f5c5",
    icon-lambda: "\f66e",
    icon-lamp-desk: "\e014",
    icon-lamp-floor: "\e015",
    icon-lamp-street: "\e1c5",
    icon-lamp: "\f4ca",
    icon-land-mine-on: "\e51b",
    icon-landmark-alt: "\f752",
    icon-landmark-dome: "\f752",
    icon-landmark-flag: "\e51c",
    icon-landmark: "\f66f",
    icon-landscape: "\e1b5",
    icon-language: "\f1ab",
    icon-laptop-arrow-down: "\e1c6",
    icon-laptop-code: "\f5fc",
    icon-laptop-file: "\e51d",
    icon-laptop-house: "\e066",
    icon-laptop-medical: "\f812",
    icon-laptop-mobile: "\f87a",
    icon-laptop-slash: "\e1c7",
    icon-laptop: "\f109",
    icon-laravel: "\f3bd",
    icon-lari-sign: "\e1c8",
    icon-lasso-sparkles: "\e1c9",
    icon-lasso: "\f8c8",
    icon-lastfm-square: "\f203",
    icon-lastfm: "\f202",
    icon-laugh-beam: "\f59a",
    icon-laugh-squint: "\f59b",
    icon-laugh-wink: "\f59c",
    icon-laugh: "\f599",
    icon-layer-group-minus: "\f5fe",
    icon-layer-group-plus: "\f5ff",
    icon-layer-group: "\f5fd",
    icon-layer-minus: "\f5fe",
    icon-layer-plus: "\f5ff",
    icon-leaf-heart: "\f4cb",
    icon-leaf-maple: "\f6f6",
    icon-leaf-oak: "\f6f7",
    icon-leaf: "\f06c",
    icon-leafy-green: "\e41d",
    icon-leanpub: "\f212",
    icon-left-from-line: "\f348",
    icon-left-long-to-line: "\e41e",
    icon-left-long: "\f30a",
    icon-left-right: "\f337",
    icon-left-to-line: "\f34b",
    icon-left: "\f355",
    icon-legal: "\f0e3",
    icon-lemon: "\f094",
    icon-less-than-equal: "\f537",
    icon-less-than: "\3c",
    icon-less: "\f41d",
    icon-level-down-alt: "\f3be",
    icon-level-down: "\f149",
    icon-level-up-alt: "\f3bf",
    icon-level-up: "\f148",
    icon-life-ring: "\f1cd",
    icon-light-ceiling: "\e016",
    icon-light-emergency-on: "\e420",
    icon-light-emergency: "\e41f",
    icon-light-switch-off: "\e018",
    icon-light-switch-on: "\e019",
    icon-light-switch: "\e017",
    icon-lightbulb-cfl-on: "\e5a7",
    icon-lightbulb-cfl: "\e5a6",
    icon-lightbulb-dollar: "\f670",
    icon-lightbulb-exclamation-on: "\e1ca",
    icon-lightbulb-exclamation: "\f671",
    icon-lightbulb-on: "\f672",
    icon-lightbulb-slash: "\f673",
    icon-lightbulb: "\f0eb",
    icon-lights-holiday: "\f7b2",
    icon-line-chart: "\f201",
    icon-line-columns: "\f870",
    icon-line-height: "\f871",
    icon-line: "\f3c0",
    icon-lines-leaning: "\e51e",
    icon-link-horizontal-slash: "\e1cc",
    icon-link-horizontal: "\e1cb",
    icon-link-simple-slash: "\e1ce",
    icon-link-simple: "\e1cd",
    icon-link-slash: "\f127",
    icon-link: "\f0c1",
    icon-linkedin-in: "\f0e1",
    icon-linkedin: "\f08c",
    icon-linode: "\f2b8",
    icon-linux: "\f17c",
    icon-lips: "\f600",
    icon-lira-sign: "\f195",
    icon-list-1-2: "\f0cb",
    icon-list-alt: "\f022",
    icon-list-check: "\f0ae",
    icon-list-dots: "\f0ca",
    icon-list-dropdown: "\e1cf",
    icon-list-music: "\f8c9",
    icon-list-numeric: "\f0cb",
    icon-list-ol: "\f0cb",
    icon-list-radio: "\e1d0",
    icon-list-squares: "\f03a",
    icon-list-timeline: "\e1d1",
    icon-list-tree: "\e1d2",
    icon-list-ul: "\f0ca",
    icon-list: "\f03a",
    icon-litecoin-sign: "\e1d3",
    icon-loader: "\e1d4",
    icon-lobster: "\e421",
    icon-location-arrow: "\f124",
    icon-location-check: "\f606",
    icon-location-circle: "\f602",
    icon-location-crosshairs-slash: "\f603",
    icon-location-crosshairs: "\f601",
    icon-location-dot-slash: "\f605",
    icon-location-dot: "\f3c5",
    icon-location-exclamation: "\f608",
    icon-location-minus: "\f609",
    icon-location-pen: "\f607",
    icon-location-pin-lock: "\e51f",
    icon-location-pin-slash: "\f60c",
    icon-location-pin: "\f041",
    icon-location-plus: "\f60a",
    icon-location-question: "\f60b",
    icon-location-slash: "\f603",
    icon-location-smile: "\f60d",
    icon-location-xmark: "\f60e",
    icon-location: "\f601",
    icon-lock-a: "\e422",
    icon-lock-alt: "\f30d",
    icon-lock-hashtag: "\e423",
    icon-lock-keyhole-open: "\f3c2",
    icon-lock-keyhole: "\f30d",
    icon-lock-open-alt: "\f3c2",
    icon-lock-open: "\f3c1",
    icon-lock: "\f023",
    icon-locust: "\e520",
    icon-lollipop: "\e424",
    icon-lollypop: "\e424",
    icon-long-arrow-alt-down: "\f309",
    icon-long-arrow-alt-left: "\f30a",
    icon-long-arrow-alt-right: "\f30b",
    icon-long-arrow-alt-up: "\f30c",
    icon-long-arrow-down: "\f175",
    icon-long-arrow-left: "\f177",
    icon-long-arrow-right: "\f178",
    icon-long-arrow-up: "\f176",
    icon-loveseat: "\f4cc",
    icon-low-vision: "\f2a8",
    icon-luchador-mask: "\f455",
    icon-luchador: "\f455",
    icon-luggage-cart: "\f59d",
    icon-lungs-virus: "\e067",
    icon-lungs: "\f604",
    icon-lyft: "\f3c3",
    icon-m: "\4d",
    icon-mace: "\f6f8",
    icon-magento: "\f3c4",
    icon-magic-wand-sparkles: "\e2ca",
    icon-magic: "\f0d0",
    icon-magnet: "\f076",
    icon-magnifying-glass-arrow-right: "\e521",
    icon-magnifying-glass-chart: "\e522",
    icon-magnifying-glass-dollar: "\f688",
    icon-magnifying-glass-location: "\f689",
    icon-magnifying-glass-minus: "\f010",
    icon-magnifying-glass-plus: "\f00e",
    icon-magnifying-glass: "\f002",
    icon-mail-bulk: "\f674",
    icon-mail-forward: "\f064",
    icon-mail-reply-all: "\f122",
    icon-mail-reply: "\f3e5",
    icon-mailbox-flag-up: "\e5bb",
    icon-mailbox: "\f813",
    icon-mailchimp: "\f59e",
    icon-maki-roll: "\e48b",
    icon-makizushi: "\e48b",
    icon-male: "\f183",
    icon-manat-sign: "\e1d5",
    icon-mandalorian: "\f50f",
    icon-mandolin: "\f6f9",
    icon-mango: "\e30f",
    icon-manhole: "\e1d6",
    icon-map-location-dot: "\f5a0",
    icon-map-location: "\f59f",
    icon-map-marked-alt: "\f5a0",
    icon-map-marked: "\f59f",
    icon-map-marker-alt-slash: "\f605",
    icon-map-marker-alt: "\f3c5",
    icon-map-marker-check: "\f606",
    icon-map-marker-edit: "\f607",
    icon-map-marker-exclamation: "\f608",
    icon-map-marker-minus: "\f609",
    icon-map-marker-plus: "\f60a",
    icon-map-marker-question: "\f60b",
    icon-map-marker-slash: "\f60c",
    icon-map-marker-smile: "\f60d",
    icon-map-marker-times: "\f60e",
    icon-map-marker-xmark: "\f60e",
    icon-map-marker: "\f041",
    icon-map-pin: "\f276",
    icon-map-signs: "\f277",
    icon-map: "\f279",
    icon-markdown: "\f60f",
    icon-marker: "\f5a1",
    icon-mars-and-venus-burst: "\e523",
    icon-mars-and-venus: "\f224",
    icon-mars-double: "\f227",
    icon-mars-stroke-h: "\f22b",
    icon-mars-stroke-right: "\f22b",
    icon-mars-stroke-up: "\f22a",
    icon-mars-stroke-v: "\f22a",
    icon-mars-stroke: "\f229",
    icon-mars: "\f222",
    icon-martini-glass-citrus: "\f561",
    icon-martini-glass-empty: "\f000",
    icon-martini-glass: "\f57b",
    icon-mask-face: "\e1d7",
    icon-mask-luchador: "\f455",
    icon-mask-snorkel: "\e3b7",
    icon-mask-ventilator: "\e524",
    icon-mask: "\f6fa",
    icon-masks-theater: "\f630",
    icon-mastodon: "\f4f6",
    icon-mattress-pillow: "\e525",
    icon-maxcdn: "\f136",
    icon-maximize: "\f31e",
    icon-mdb: "\f8ca",
    icon-meat: "\f814",
    icon-medal: "\f5a2",
    icon-medapps: "\f3c6",
    icon-medium-m: "\f23a",
    icon-medium: "\f23a",
    icon-medkit: "\f0fa",
    icon-medrt: "\f3c8",
    icon-meetup: "\f2e0",
    icon-megaphone: "\f675",
    icon-megaport: "\f5a3",
    icon-meh-blank: "\f5a4",
    icon-meh-rolling-eyes: "\f5a5",
    icon-meh: "\f11a",
    icon-melon-slice: "\e311",
    icon-melon: "\e310",
    icon-memo-circle-check: "\e1d9",
    icon-memo-circle-info: "\e49a",
    icon-memo-pad: "\e1da",
    icon-memo: "\e1d8",
    icon-memory: "\f538",
    icon-mendeley: "\f7b3",
    icon-menorah: "\f676",
    icon-mercury: "\f223",
    icon-merge: "\e526",
    icon-message-arrow-down: "\e1db",
    icon-message-arrow-up-right: "\e1dd",
    icon-message-arrow-up: "\e1dc",
    icon-message-bot: "\e3b8",
    icon-message-captions: "\e1de",
    icon-message-check: "\f4a2",
    icon-message-code: "\e1df",
    icon-message-dollar: "\f650",
    icon-message-dots: "\f4a3",
    icon-message-edit: "\f4a4",
    icon-message-exclamation: "\f4a5",
    icon-message-heart: "\e5c9",
    icon-message-image: "\e1e0",
    icon-message-lines: "\f4a6",
    icon-message-medical: "\f7f4",
    icon-message-middle-top: "\e1e2",
    icon-message-middle: "\e1e1",
    icon-message-minus: "\f4a7",
    icon-message-music: "\f8af",
    icon-message-pen: "\f4a4",
    icon-message-plus: "\f4a8",
    icon-message-question: "\e1e3",
    icon-message-quote: "\e1e4",
    icon-message-slash: "\f4a9",
    icon-message-smile: "\f4aa",
    icon-message-sms: "\e1e5",
    icon-message-text: "\e1e6",
    icon-message-times: "\f4ab",
    icon-message-xmark: "\f4ab",
    icon-message: "\f27a",
    icon-messages-dollar: "\f652",
    icon-messages-question: "\e1e7",
    icon-messages: "\f4b6",
    icon-messaging: "\f4a3",
    icon-meta: "\e49b",
    icon-meteor: "\f753",
    icon-meter-bolt: "\e1e9",
    icon-meter-droplet: "\e1ea",
    icon-meter-fire: "\e1eb",
    icon-meter: "\e1e8",
    icon-microblog: "\e01a",
    icon-microchip-ai: "\e1ec",
    icon-microchip: "\f2db",
    icon-microphone-alt-slash: "\f539",
    icon-microphone-alt: "\f3c9",
    icon-microphone-circle-alt: "\e117",
    icon-microphone-circle: "\e116",
    icon-microphone-lines-slash: "\f539",
    icon-microphone-lines: "\f3c9",
    icon-microphone-slash: "\f131",
    icon-microphone-stand: "\f8cb",
    icon-microphone: "\f130",
    icon-microscope: "\f610",
    icon-microsoft: "\f3ca",
    icon-microwave: "\e01b",
    icon-mill-sign: "\e1ed",
    icon-mind-share: "\f677",
    icon-minimize: "\f78c",
    icon-minus-circle: "\f056",
    icon-minus-hexagon: "\f307",
    icon-minus-large: "\e404",
    icon-minus-octagon: "\f308",
    icon-minus-square: "\f146",
    icon-minus: "\f068",
    icon-mistletoe: "\f7b4",
    icon-mitten: "\f7b5",
    icon-mix: "\f3cb",
    icon-mixcloud: "\f289",
    icon-mixer: "\e056",
    icon-mizuni: "\f3cc",
    icon-mobile-alt: "\f3cd",
    icon-mobile-android-alt: "\f3cf",
    icon-mobile-android: "\f3ce",
    icon-mobile-button: "\f10b",
    icon-mobile-iphone: "\e1ee",
    icon-mobile-notch: "\e1ee",
    icon-mobile-phone: "\f3ce",
    icon-mobile-retro: "\e527",
    icon-mobile-screen-button: "\f3cd",
    icon-mobile-screen: "\f3cf",
    icon-mobile-signal-out: "\e1f0",
    icon-mobile-signal: "\e1ef",
    icon-mobile: "\f3ce",
    icon-modx: "\f285",
    icon-monero: "\f3d0",
    icon-money-bill-1-wave: "\f53b",
    icon-money-bill-1: "\f3d1",
    icon-money-bill-alt: "\f3d1",
    icon-money-bill-simple-wave: "\e1f2",
    icon-money-bill-simple: "\e1f1",
    icon-money-bill-transfer: "\e528",
    icon-money-bill-trend-up: "\e529",
    icon-money-bill-wave-alt: "\f53b",
    icon-money-bill-wave: "\f53a",
    icon-money-bill-wheat: "\e52a",
    icon-money-bill: "\f0d6",
    icon-money-bills-alt: "\e1f4",
    icon-money-bills-simple: "\e1f4",
    icon-money-bills: "\e1f3",
    icon-money-check-alt: "\f53d",
    icon-money-check-dollar-pen: "\f873",
    icon-money-check-dollar: "\f53d",
    icon-money-check-edit-alt: "\f873",
    icon-money-check-edit: "\f872",
    icon-money-check-pen: "\f872",
    icon-money-check: "\f53c",
    icon-money-from-bracket: "\e312",
    icon-money-simple-from-bracket: "\e313",
    icon-monitor-heart-rate: "\f611",
    icon-monitor-waveform: "\f611",
    icon-monkey: "\f6fb",
    icon-monument: "\f5a6",
    icon-moon-cloud: "\f754",
    icon-moon-over-sun: "\f74a",
    icon-moon-stars: "\f755",
    icon-moon: "\f186",
    icon-moped: "\e3b9",
    icon-mortar-board: "\f19d",
    icon-mortar-pestle: "\f5a7",
    icon-mosque: "\f678",
    icon-mosquito-net: "\e52c",
    icon-mosquito: "\e52b",
    icon-motorcycle: "\f21c",
    icon-mound: "\e52d",
    icon-mountain-city: "\e52e",
    icon-mountain-sun: "\e52f",
    icon-mountain: "\f6fc",
    icon-mountains: "\f6fd",
    icon-mouse-alt: "\f8cd",
    icon-mouse-field: "\e5a8",
    icon-mouse-pointer: "\f245",
    icon-mouse: "\f8cc",
    icon-mp3-player: "\f8ce",
    icon-mug-hot: "\f7b6",
    icon-mug-marshmallows: "\f7b7",
    icon-mug-saucer: "\f0f4",
    icon-mug-tea-saucer: "\e1f5",
    icon-mug-tea: "\f875",
    icon-mug: "\f874",
    icon-multiply: "\f00d",
    icon-museum: "\f19c",
    icon-mushroom: "\e425",
    icon-music-alt-slash: "\f8d0",
    icon-music-alt: "\f8cf",
    icon-music-note-slash: "\f8d0",
    icon-music-note: "\f8cf",
    icon-music-slash: "\f8d1",
    icon-music: "\f001",
    icon-mustache: "\e5bc",
    icon-n: "\4e",
    icon-naira-sign: "\e1f6",
    icon-napster: "\f3d2",
    icon-narwhal: "\f6fe",
    icon-navicon: "\f0c9",
    icon-neos: "\f612",
    icon-nesting-dolls: "\e3ba",
    icon-network-wired: "\f6ff",
    icon-neuter: "\f22c",
    icon-newspaper: "\f1ea",
    icon-nfc-directional: "\e530",
    icon-nfc-lock: "\e1f8",
    icon-nfc-magnifying-glass: "\e1f9",
    icon-nfc-pen: "\e1fa",
    icon-nfc-signal: "\e1fb",
    icon-nfc-slash: "\e1fc",
    icon-nfc-symbol: "\e531",
    icon-nfc-trash: "\e1fd",
    icon-nfc: "\e1f7",
    icon-nigiri: "\e48a",
    icon-nimblr: "\f5a8",
    icon-node-js: "\f3d3",
    icon-node: "\f419",
    icon-nose: "\e5bd",
    icon-not-equal: "\f53e",
    icon-notdef: "\e1fe",
    icon-note-medical: "\e200",
    icon-note-sticky: "\f249",
    icon-note: "\e1ff",
    icon-notebook: "\e201",
    icon-notes-medical: "\f481",
    icon-notes: "\e202",
    icon-npm: "\f3d4",
    icon-ns8: "\f3d5",
    icon-nutritionix: "\f3d6",
    icon-o: "\4f",
    icon-object-exclude: "\e49c",
    icon-object-group: "\f247",
    icon-object-intersect: "\e49d",
    icon-object-subtract: "\e49e",
    icon-object-ungroup: "\f248",
    icon-object-union: "\e49f",
    icon-objects-align-bottom: "\e3bb",
    icon-objects-align-center-horizontal: "\e3bc",
    icon-objects-align-center-vertical: "\e3bd",
    icon-objects-align-left: "\e3be",
    icon-objects-align-right: "\e3bf",
    icon-objects-align-top: "\e3c0",
    icon-objects-column: "\e3c1",
    icon-octagon-check: "\e426",
    icon-octagon-divide: "\e203",
    icon-octagon-exclamation: "\e204",
    icon-octagon-minus: "\f308",
    icon-octagon-plus: "\f301",
    icon-octagon-xmark: "\f2f0",
    icon-octagon: "\f306",
    icon-octopus-deploy: "\e082",
    icon-odnoklassniki-square: "\f264",
    icon-odnoklassniki: "\f263",
    icon-odysee: "\e5c6",
    icon-oil-can-drip: "\e205",
    icon-oil-can: "\f613",
    icon-oil-temp: "\f614",
    icon-oil-temperature: "\f614",
    icon-oil-well: "\e532",
    icon-old-republic: "\f510",
    icon-olive-branch: "\e317",
    icon-olive: "\e316",
    icon-om: "\f679",
    icon-omega: "\f67a",
    icon-onion: "\e427",
    icon-opencart: "\f23d",
    icon-openid: "\f19b",
    icon-opera: "\f26a",
    icon-optin-monster: "\f23c",
    icon-option: "\e318",
    icon-orcid: "\f8d2",
    icon-ornament: "\f7b8",
    icon-osi: "\f41a",
    icon-otter: "\f700",
    icon-outdent: "\f03b",
    icon-outlet: "\e01c",
    icon-oven: "\e01d",
    icon-overline: "\f876",
    icon-p: "\50",
    icon-padlet: "\e4a0",
    icon-page-break: "\f877",
    icon-page-caret-down: "\e429",
    icon-page-caret-up: "\e42a",
    icon-page4: "\f3d7",
    icon-page: "\e428",
    icon-pagelines: "\f18c",
    icon-pager: "\f815",
    icon-paint-brush-alt: "\f5a9",
    icon-paint-brush-fine: "\f5a9",
    icon-paint-brush: "\f1fc",
    icon-paint-roller: "\f5aa",
    icon-paintbrush-alt: "\f5a9",
    icon-paintbrush-fine: "\f5a9",
    icon-paintbrush-pencil: "\e206",
    icon-paintbrush: "\f1fc",
    icon-palette-boxes: "\f483",
    icon-palette: "\f53f",
    icon-palfed: "\f3d8",
    icon-pallet-alt: "\f483",
    icon-pallet-box: "\e208",
    icon-pallet-boxes: "\f483",
    icon-pallet: "\f482",
    icon-pan-food: "\e42b",
    icon-pan-frying: "\e42c",
    icon-pancakes: "\e42d",
    icon-panel-ews: "\e42e",
    icon-panel-fire: "\e42f",
    icon-panorama: "\e209",
    icon-paper-plane-alt: "\e20a",
    icon-paper-plane-top: "\e20a",
    icon-paper-plane: "\f1d8",
    icon-paperclip-vertical: "\e3c2",
    icon-paperclip: "\f0c6",
    icon-parachute-box: "\f4cd",
    icon-paragraph-left: "\f878",
    icon-paragraph-rtl: "\f878",
    icon-paragraph: "\f1dd",
    icon-parentheses: "\e0c5",
    icon-parenthesis: "\28",
    icon-parking-circle-slash: "\f616",
    icon-parking-circle: "\f615",
    icon-parking-slash: "\f617",
    icon-parking: "\f540",
    icon-party-back: "\e45c",
    icon-party-bell: "\e31a",
    icon-party-horn: "\e31b",
    icon-passport: "\f5ab",
    icon-pastafarianism: "\f67b",
    icon-paste: "\f0ea",
    icon-patreon: "\f3d9",
    icon-pause-circle: "\f28b",
    icon-pause: "\f04c",
    icon-paw-alt: "\f701",
    icon-paw-claws: "\f702",
    icon-paw-simple: "\f701",
    icon-paw: "\f1b0",
    icon-paypal: "\f1ed",
    icon-peace: "\f67c",
    icon-peach: "\e20b",
    icon-peanut: "\e430",
    icon-peanuts: "\e431",
    icon-peapod: "\e31c",
    icon-pear: "\e20c",
    icon-pedestal: "\e20d",
    icon-pegasus: "\f703",
    icon-pen-alt-slash: "\e20f",
    icon-pen-alt: "\f305",
    icon-pen-circle: "\e20e",
    icon-pen-clip-slash: "\e20f",
    icon-pen-clip: "\f305",
    icon-pen-fancy-slash: "\e210",
    icon-pen-fancy: "\f5ac",
    icon-pen-field: "\e211",
    icon-pen-line: "\e212",
    icon-pen-nib-slash: "\e4a1",
    icon-pen-nib: "\f5ad",
    icon-pen-paintbrush: "\f618",
    icon-pen-ruler: "\f5ae",
    icon-pen-slash: "\e213",
    icon-pen-square: "\f14b",
    icon-pen-swirl: "\e214",
    icon-pen-to-square: "\f044",
    icon-pen: "\f304",
    icon-pencil-alt: "\f303",
    icon-pencil-mechanical: "\e5ca",
    icon-pencil-paintbrush: "\f618",
    icon-pencil-ruler: "\f5ae",
    icon-pencil-slash: "\e215",
    icon-pencil-square: "\f14b",
    icon-pencil: "\f303",
    icon-pennant: "\f456",
    icon-people-arrows-left-right: "\e068",
    icon-people-arrows: "\e068",
    icon-people-carry-box: "\f4ce",
    icon-people-carry: "\f4ce",
    icon-people-dress-simple: "\e218",
    icon-people-dress: "\e217",
    icon-people-group: "\e533",
    icon-people-line: "\e534",
    icon-people-pants-simple: "\e21a",
    icon-people-pants: "\e219",
    icon-people-pulling: "\e535",
    icon-people-robbery: "\e536",
    icon-people-roof: "\e537",
    icon-people-simple: "\e21b",
    icon-people: "\e216",
    icon-pepper-hot: "\f816",
    icon-pepper: "\e432",
    icon-perbyte: "\e083",
    icon-percent: "\25",
    icon-percentage: "\25",
    icon-period: "\2e",
    icon-periscope: "\f3da",
    icon-person-arrow-down-to-line: "\e538",
    icon-person-arrow-up-from-line: "\e539",
    icon-person-biking-mountain: "\f84b",
    icon-person-biking: "\f84a",
    icon-person-booth: "\f756",
    icon-person-breastfeeding: "\e53a",
    icon-person-burst: "\e53b",
    icon-person-cane: "\e53c",
    icon-person-carry-box: "\f4cf",
    icon-person-carry: "\f4cf",
    icon-person-chalkboard: "\e53d",
    icon-person-circle-check: "\e53e",
    icon-person-circle-exclamation: "\e53f",
    icon-person-circle-minus: "\e540",
    icon-person-circle-plus: "\e541",
    icon-person-circle-question: "\e542",
    icon-person-circle-xmark: "\e543",
    icon-person-digging: "\f85e",
    icon-person-dolly-empty: "\f4d1",
    icon-person-dolly: "\f4d0",
    icon-person-dots-from-line: "\f470",
    icon-person-dress-burst: "\e544",
    icon-person-dress-simple: "\e21c",
    icon-person-dress: "\f182",
    icon-person-drowning: "\e545",
    icon-person-falling-burst: "\e547",
    icon-person-falling: "\e546",
    icon-person-from-portal: "\e023",
    icon-person-half-dress: "\e548",
    icon-person-harassing: "\e549",
    icon-person-hiking: "\f6ec",
    icon-person-military-pointing: "\e54a",
    icon-person-military-rifle: "\e54b",
    icon-person-military-to-person: "\e54c",
    icon-person-pinball: "\e21d",
    icon-person-praying: "\f683",
    icon-person-pregnant: "\e31e",
    icon-person-rays: "\e54d",
    icon-person-rifle: "\e54e",
    icon-person-running: "\f70c",
    icon-person-seat-reclined: "\e21f",
    icon-person-seat: "\e21e",
    icon-person-shelter: "\e54f",
    icon-person-sign: "\f757",
    icon-person-simple: "\e220",
    icon-person-skating: "\f7c5",
    icon-person-ski-jumping: "\f7c7",
    icon-person-ski-lift: "\f7c8",
    icon-person-skiing-nordic: "\f7ca",
    icon-person-skiing: "\f7c9",
    icon-person-sledding: "\f7cb",
    icon-person-snowboarding: "\f7ce",
    icon-person-snowmobiling: "\f7d1",
    icon-person-swimming: "\f5c4",
    icon-person-through-window: "\e5a9",
    icon-person-to-door: "\e433",
    icon-person-to-portal: "\e022",
    icon-person-walking-arrow-loop-left: "\e551",
    icon-person-walking-arrow-right: "\e552",
    icon-person-walking-dashed-line-arrow-right: "\e553",
    icon-person-walking-luggage: "\e554",
    icon-person-walking-with-cane: "\f29d",
    icon-person-walking: "\f554",
    icon-person: "\f183",
    icon-peseta-sign: "\e221",
    icon-peso-sign: "\e222",
    icon-phabricator: "\f3db",
    icon-phoenix-framework: "\f3dc",
    icon-phoenix-squadron: "\f511",
    icon-phone-alt: "\f879",
    icon-phone-arrow-down-left: "\e223",
    icon-phone-arrow-down: "\e223",
    icon-phone-arrow-right: "\e5be",
    icon-phone-arrow-up-right: "\e224",
    icon-phone-arrow-up: "\e224",
    icon-phone-circle-alt: "\e11c",
    icon-phone-circle-down: "\e11d",
    icon-phone-circle: "\e11b",
    icon-phone-flip: "\f879",
    icon-phone-hangup: "\e225",
    icon-phone-incoming: "\e223",
    icon-phone-intercom: "\e434",
    icon-phone-laptop: "\f87a",
    icon-phone-missed: "\e226",
    icon-phone-office: "\f67d",
    icon-phone-outgoing: "\e224",
    icon-phone-plus: "\f4d2",
    icon-phone-rotary: "\f8d3",
    icon-phone-slash: "\f3dd",
    icon-phone-square-alt: "\f87b",
    icon-phone-square-down: "\e27a",
    icon-phone-square: "\f098",
    icon-phone-volume: "\f2a0",
    icon-phone-xmark: "\e227",
    icon-phone: "\f095",
    icon-photo-film-music: "\e228",
    icon-photo-film: "\f87c",
    icon-photo-video: "\f87c",
    icon-php: "\f457",
    icon-pi: "\f67e",
    icon-piano-keyboard: "\f8d5",
    icon-piano: "\f8d4",
    icon-pickaxe: "\e5bf",
    icon-pickleball: "\e435",
    icon-pie-chart: "\f200",
    icon-pie: "\f705",
    icon-pied-piper-alt: "\f1a8",
    icon-pied-piper-hat: "\f4e5",
    icon-pied-piper-pp: "\f1a7",
    icon-pied-piper-square: "\e01e",
    icon-pied-piper: "\f2ae",
    icon-pig: "\f706",
    icon-piggy-bank: "\f4d3",
    icon-pills: "\f484",
    icon-pinata: "\e3c3",
    icon-pinball: "\e229",
    icon-pineapple: "\e31f",
    icon-ping-pong-paddle-ball: "\f45d",
    icon-pinterest-p: "\f231",
    icon-pinterest-square: "\f0d3",
    icon-pinterest: "\f0d2",
    icon-pipe-circle-check: "\e436",
    icon-pipe-collar: "\e437",
    icon-pipe-section: "\e438",
    icon-pipe-smoking: "\e3c4",
    icon-pipe-valve: "\e439",
    icon-pipe: "\7c",
    icon-pix: "\e43a",
    icon-pizza-slice: "\f818",
    icon-pizza: "\f817",
    icon-place-of-worship: "\f67f",
    icon-plane-alt: "\f3de",
    icon-plane-arrival: "\f5af",
    icon-plane-circle-check: "\e555",
    icon-plane-circle-exclamation: "\e556",
    icon-plane-circle-xmark: "\e557",
    icon-plane-departure: "\f5b0",
    icon-plane-engines: "\f3de",
    icon-plane-lock: "\e558",
    icon-plane-prop: "\e22b",
    icon-plane-slash: "\e069",
    icon-plane-tail: "\e22c",
    icon-plane-up-slash: "\e22e",
    icon-plane-up: "\e22d",
    icon-plane: "\f072",
    icon-planet-moon: "\e01f",
    icon-planet-ringed: "\e020",
    icon-plant-wilt: "\e5aa",
    icon-plate-utensils: "\e43b",
    icon-plate-wheat: "\e55a",
    icon-play-circle: "\f144",
    icon-play-pause: "\e22f",
    icon-play: "\f04b",
    icon-playstation: "\f3df",
    icon-plug-circle-bolt: "\e55b",
    icon-plug-circle-check: "\e55c",
    icon-plug-circle-exclamation: "\e55d",
    icon-plug-circle-minus: "\e55e",
    icon-plug-circle-plus: "\e55f",
    icon-plug-circle-xmark: "\e560",
    icon-plug: "\f1e6",
    icon-plus-circle: "\f055",
    icon-plus-hexagon: "\f300",
    icon-plus-large: "\e59e",
    icon-plus-minus: "\e43c",
    icon-plus-octagon: "\f301",
    icon-plus-square: "\f0fe",
    icon-plus: "\2b",
    icon-podcast: "\f2ce",
    icon-podium-star: "\f758",
    icon-podium: "\f680",
    icon-police-box: "\e021",
    icon-poll-h: "\f682",
    icon-poll-people: "\f759",
    icon-poll: "\f681",
    icon-pompebled: "\e43d",
    icon-poo-bolt: "\f75a",
    icon-poo-storm: "\f75a",
    icon-poo: "\f2fe",
    icon-pool-8-ball: "\e3c5",
    icon-poop: "\f619",
    icon-popcorn: "\f819",
    icon-popsicle: "\e43e",
    icon-portal-enter: "\e022",
    icon-portal-exit: "\e023",
    icon-portrait: "\f3e0",
    icon-pot-food: "\e43f",
    icon-potato: "\e440",
    icon-pound-sign: "\f154",
    icon-power-off: "\f011",
    icon-pray: "\f683",
    icon-praying-hands: "\f684",
    icon-prescription-bottle-alt: "\f486",
    icon-prescription-bottle-medical: "\f486",
    icon-prescription-bottle-pill: "\e5c0",
    icon-prescription-bottle: "\f485",
    icon-prescription: "\f5b1",
    icon-presentation-screen: "\f685",
    icon-presentation: "\f685",
    icon-pretzel: "\e441",
    icon-print-magnifying-glass: "\f81a",
    icon-print-search: "\f81a",
    icon-print-slash: "\f686",
    icon-print: "\f02f",
    icon-pro: "\e235",
    icon-procedures: "\f487",
    icon-product-hunt: "\f288",
    icon-project-diagram: "\f542",
    icon-projector: "\f8d6",
    icon-pump-medical: "\e06a",
    icon-pump-soap: "\e06b",
    icon-pump: "\e442",
    icon-pumpkin: "\f707",
    icon-pushed: "\f3e1",
    icon-puzzle-piece-alt: "\e231",
    icon-puzzle-piece-simple: "\e231",
    icon-puzzle-piece: "\f12e",
    icon-puzzle: "\e443",
    icon-python: "\f3e2",
    icon-q: "\51",
    icon-qq: "\f1d6",
    icon-qrcode: "\f029",
    icon-question-circle: "\f059",
    icon-question-square: "\f2fd",
    icon-question: "\3f",
    icon-quidditch-broom-ball: "\f458",
    icon-quidditch: "\f458",
    icon-quinscape: "\f459",
    icon-quora: "\f2c4",
    icon-quote-left-alt: "\f10d",
    icon-quote-left: "\f10d",
    icon-quote-right-alt: "\f10e",
    icon-quote-right: "\f10e",
    icon-quotes: "\e234",
    icon-quran: "\f687",
    icon-r-project: "\f4f7",
    icon-r: "\52",
    icon-rabbit-fast: "\f709",
    icon-rabbit-running: "\f709",
    icon-rabbit: "\f708",
    icon-racquet: "\f45a",
    icon-radar: "\e024",
    icon-radiation-alt: "\f7ba",
    icon-radiation: "\f7b9",
    icon-radio-alt: "\f8d8",
    icon-radio-tuner: "\f8d8",
    icon-radio: "\f8d7",
    icon-rainbow: "\f75b",
    icon-raindrops: "\f75c",
    icon-ram: "\f70a",
    icon-ramp-loading: "\f4d4",
    icon-random: "\f074",
    icon-ranking-star: "\e561",
    icon-raspberry-pi: "\f7bb",
    icon-ravelry: "\f2d9",
    icon-raygun: "\e025",
    icon-react: "\f41b",
    icon-reacteurope: "\f75d",
    icon-readme: "\f4d5",
    icon-rebel: "\f1d0",
    icon-receipt: "\f543",
    icon-record-vinyl: "\f8d9",
    icon-rectangle-ad: "\f641",
    icon-rectangle-barcode: "\f463",
    icon-rectangle-code: "\e322",
    icon-rectangle-hd: "\e1ae",
    icon-rectangle-history-circle-plus: "\e4a3",
    icon-rectangle-history-circle-user: "\e4a4",
    icon-rectangle-history: "\e4a2",
    icon-rectangle-landscape: "\f2fa",
    icon-rectangle-list: "\f022",
    icon-rectangle-portrait: "\f2fb",
    icon-rectangle-pro: "\e235",
    icon-rectangle-sd: "\e28a",
    icon-rectangle-terminal: "\e236",
    icon-rectangle-times: "\f410",
    icon-rectangle-vertical-history: "\e237",
    icon-rectangle-vertical: "\f2fb",
    icon-rectangle-wide: "\f2fc",
    icon-rectangle-xmark: "\f410",
    icon-rectangle: "\f2fa",
    icon-rectangles-mixed: "\e323",
    icon-recycle: "\f1b8",
    icon-red-river: "\f3e3",
    icon-reddit-alien: "\f281",
    icon-reddit-square: "\f1a2",
    icon-reddit: "\f1a1",
    icon-redhat: "\f7bc",
    icon-redo-alt: "\f2f9",
    icon-redo: "\f01e",
    icon-reel: "\e238",
    icon-refresh: "\f021",
    icon-refrigerator: "\e026",
    icon-registered: "\f25d",
    icon-remove-format: "\f87d",
    icon-remove: "\f00d",
    icon-rendact: "\f3e4",
    icon-renren: "\f18b",
    icon-reorder: "\f550",
    icon-repeat-1-alt: "\f366",
    icon-repeat-1: "\f365",
    icon-repeat-alt: "\f364",
    icon-repeat: "\f363",
    icon-reply-all: "\f122",
    icon-reply-clock: "\e239",
    icon-reply-time: "\e239",
    icon-reply: "\f3e5",
    icon-replyd: "\f3e6",
    icon-republican: "\f75e",
    icon-researchgate: "\f4f8",
    icon-resolving: "\f3e7",
    icon-restroom-simple: "\e23a",
    icon-restroom: "\f7bd",
    icon-retweet-alt: "\f361",
    icon-retweet: "\f079",
    icon-rev: "\f5b2",
    icon-rhombus: "\e23b",
    icon-ribbon: "\f4d6",
    icon-right-from-bracket: "\f2f5",
    icon-right-from-line: "\f347",
    icon-right-left: "\f362",
    icon-right-long-to-line: "\e444",
    icon-right-long: "\f30b",
    icon-right-to-bracket: "\f2f6",
    icon-right-to-line: "\f34c",
    icon-right: "\f356",
    icon-ring-diamond: "\e5ab",
    icon-ring: "\f70b",
    icon-rings-wedding: "\f81b",
    icon-rmb: "\f157",
    icon-road-barrier: "\e562",
    icon-road-bridge: "\e563",
    icon-road-circle-check: "\e564",
    icon-road-circle-exclamation: "\e565",
    icon-road-circle-xmark: "\e566",
    icon-road-lock: "\e567",
    icon-road-spikes: "\e568",
    icon-road: "\f018",
    icon-robot-astromech: "\e2d2",
    icon-robot: "\f544",
    icon-rocket-launch: "\e027",
    icon-rocket: "\f135",
    icon-rocketchat: "\f3e8",
    icon-rockrms: "\f3e9",
    icon-rod-asclepius: "\e579",
    icon-rod-snake: "\e579",
    icon-roller-coaster: "\e324",
    icon-rotate-back: "\f2ea",
    icon-rotate-backward: "\f2ea",
    icon-rotate-exclamation: "\e23c",
    icon-rotate-forward: "\f2f9",
    icon-rotate-left: "\f2ea",
    icon-rotate-right: "\f2f9",
    icon-rotate: "\f2f1",
    icon-rouble: "\f158",
    icon-route-highway: "\f61a",
    icon-route-interstate: "\f61b",
    icon-route: "\f4d7",
    icon-router: "\f8da",
    icon-rows: "\e292",
    icon-rss-square: "\f143",
    icon-rss: "\f09e",
    icon-rub: "\f158",
    icon-ruble-sign: "\f158",
    icon-ruble: "\f158",
    icon-rug: "\e569",
    icon-rugby-ball: "\e3c6",
    icon-ruler-combined: "\f546",
    icon-ruler-horizontal: "\f547",
    icon-ruler-triangle: "\f61c",
    icon-ruler-vertical: "\f548",
    icon-ruler: "\f545",
    icon-running: "\f70c",
    icon-rupee-sign: "\f156",
    icon-rupee: "\f156",
    icon-rupiah-sign: "\e23d",
    icon-rust: "\e07a",
    icon-rv: "\f7be",
    icon-s: "\53",
    icon-sack-dollar: "\f81d",
    icon-sack-xmark: "\e56a",
    icon-sack: "\f81c",
    icon-sad-cry: "\f5b3",
    icon-sad-tear: "\f5b4",
    icon-safari: "\f267",
    icon-sailboat: "\e445",
    icon-salad: "\f81e",
    icon-salesforce: "\f83b",
    icon-salt-shaker: "\e446",
    icon-sandwich: "\f81f",
    icon-sass: "\f41e",
    icon-satellite-dish: "\f7c0",
    icon-satellite: "\f7bf",
    icon-sausage: "\f820",
    icon-save-circle-arrow-right: "\e180",
    icon-save-circle-xmark: "\e181",
    icon-save-times: "\e181",
    icon-save: "\f0c7",
    icon-sax-hot: "\f8db",
    icon-saxophone-fire: "\f8db",
    icon-saxophone: "\f8dc",
    icon-scale-balanced: "\f24e",
    icon-scale-unbalanced-flip: "\f516",
    icon-scale-unbalanced: "\f515",
    icon-scalpel-line-dashed: "\f61e",
    icon-scalpel-path: "\f61e",
    icon-scalpel: "\f61d",
    icon-scanner-gun: "\f488",
    icon-scanner-image: "\f8f3",
    icon-scanner-keyboard: "\f489",
    icon-scanner-touchscreen: "\f48a",
    icon-scanner: "\f488",
    icon-scarecrow: "\f70d",
    icon-scarf: "\f7c1",
    icon-schlix: "\f3ea",
    icon-school-circle-check: "\e56b",
    icon-school-circle-exclamation: "\e56c",
    icon-school-circle-xmark: "\e56d",
    icon-school-flag: "\e56e",
    icon-school-lock: "\e56f",
    icon-school: "\f549",
    icon-scissors: "\f0c4",
    icon-screen-users: "\f63d",
    icon-screencast: "\e23e",
    icon-screenpal: "\e570",
    icon-screenshot: "\e0da",
    icon-screwdriver-wrench: "\f7d9",
    icon-screwdriver: "\f54a",
    icon-scribble: "\e23f",
    icon-scribd: "\f28a",
    icon-scroll-old: "\f70f",
    icon-scroll-ribbon: "\f5ea",
    icon-scroll-torah: "\f6a0",
    icon-scroll: "\f70e",
    icon-scrubber: "\f2f8",
    icon-scythe: "\f710",
    icon-sd-card: "\f7c2",
    icon-sd-cards: "\e240",
    icon-seal-exclamation: "\e242",
    icon-seal-question: "\e243",
    icon-seal: "\e241",
    icon-search-dollar: "\f688",
    icon-search-location: "\f689",
    icon-search-minus: "\f010",
    icon-search-plus: "\f00e",
    icon-search: "\f002",
    icon-searchengin: "\f3eb",
    icon-seat-airline: "\e244",
    icon-section: "\e447",
    icon-seedling: "\f4d8",
    icon-sellcast: "\f2da",
    icon-sellsy: "\f213",
    icon-semicolon: "\3b",
    icon-send-back: "\f87e",
    icon-send-backward: "\f87f",
    icon-send: "\e20a",
    icon-sensor-alert: "\e029",
    icon-sensor-cloud: "\e02c",
    icon-sensor-fire: "\e02a",
    icon-sensor-on: "\e02b",
    icon-sensor-smoke: "\e02c",
    icon-sensor-triangle-exclamation: "\e029",
    icon-sensor: "\e028",
    icon-server: "\f233",
    icon-servicestack: "\f3ec",
    icon-shapes: "\f61f",
    icon-share-all: "\f367",
    icon-share-alt-square: "\f1e1",
    icon-share-alt: "\f1e0",
    icon-share-from-square: "\f14d",
    icon-share-nodes: "\f1e0",
    icon-share-square: "\f14d",
    icon-share: "\f064",
    icon-sheep: "\f711",
    icon-sheet-plastic: "\e571",
    icon-shekel-sign: "\f20b",
    icon-shekel: "\f20b",
    icon-shelves-empty: "\e246",
    icon-shelves: "\f480",
    icon-sheqel-sign: "\f20b",
    icon-sheqel: "\f20b",
    icon-shield-alt: "\f3ed",
    icon-shield-blank: "\f132",
    icon-shield-cat: "\e572",
    icon-shield-check: "\f2f7",
    icon-shield-cross: "\f712",
    icon-shield-dog: "\e573",
    icon-shield-exclamation: "\e247",
    icon-shield-halved: "\f3ed",
    icon-shield-heart: "\e574",
    icon-shield-keyhole: "\e248",
    icon-shield-minus: "\e249",
    icon-shield-plus: "\e24a",
    icon-shield-quartered: "\e575",
    icon-shield-slash: "\e24b",
    icon-shield-times: "\e24c",
    icon-shield-virus: "\e06c",
    icon-shield-xmark: "\e24c",
    icon-shield: "\f132",
    icon-ship: "\f21a",
    icon-shipping-fast: "\f48b",
    icon-shipping-timed: "\f48c",
    icon-shirt-long-sleeve: "\e3c7",
    icon-shirt-running: "\e3c8",
    icon-shirt-tank-top: "\e3c9",
    icon-shirt: "\f553",
    icon-shirtsinbulk: "\f214",
    icon-shish-kebab: "\f821",
    icon-shoe-prints: "\f54b",
    icon-shop-lock: "\e4a5",
    icon-shop-slash: "\e070",
    icon-shop: "\f54f",
    icon-shopify: "\e057",
    icon-shopping-bag: "\f290",
    icon-shopping-basket-alt: "\e0af",
    icon-shopping-basket: "\f291",
    icon-shopping-cart: "\f07a",
    icon-shopware: "\f5b5",
    icon-shortcake: "\e3e5",
    icon-shovel-snow: "\f7c3",
    icon-shovel: "\f713",
    icon-shower-alt: "\e24d",
    icon-shower-down: "\e24d",
    icon-shower: "\f2cc",
    icon-shredder: "\f68a",
    icon-shrimp: "\e448",
    icon-shuffle: "\f074",
    icon-shutters: "\e449",
    icon-shuttle-space: "\f197",
    icon-shuttle-van: "\f5b6",
    icon-shuttlecock: "\f45b",
    icon-sickle: "\f822",
    icon-sidebar-flip: "\e24f",
    icon-sidebar: "\e24e",
    icon-sigma: "\f68b",
    icon-sign-hanging: "\f4d9",
    icon-sign-in-alt: "\f2f6",
    icon-sign-in: "\f090",
    icon-sign-language: "\f2a7",
    icon-sign-out-alt: "\f2f5",
    icon-sign-out: "\f08b",
    icon-sign: "\f4d9",
    icon-signal-1: "\f68c",
    icon-signal-2: "\f68d",
    icon-signal-3: "\f68e",
    icon-signal-4: "\f68f",
    icon-signal-5: "\f012",
    icon-signal-alt-1: "\f691",
    icon-signal-alt-2: "\f692",
    icon-signal-alt-3: "\f693",
    icon-signal-alt-4: "\f690",
    icon-signal-alt-slash: "\f694",
    icon-signal-alt: "\f690",
    icon-signal-bars-fair: "\f692",
    icon-signal-bars-good: "\f693",
    icon-signal-bars-slash: "\f694",
    icon-signal-bars-strong: "\f690",
    icon-signal-bars-weak: "\f691",
    icon-signal-bars: "\f690",
    icon-signal-fair: "\f68d",
    icon-signal-good: "\f68e",
    icon-signal-perfect: "\f012",
    icon-signal-slash: "\f695",
    icon-signal-stream-slash: "\e250",
    icon-signal-stream: "\f8dd",
    icon-signal-strong: "\f68f",
    icon-signal-weak: "\f68c",
    icon-signal: "\f012",
    icon-signature-lock: "\e3ca",
    icon-signature-slash: "\e3cb",
    icon-signature: "\f5b7",
    icon-signing: "\f2a7",
    icon-signs-post: "\f277",
    icon-sim-card: "\f7c4",
    icon-sim-cards: "\e251",
    icon-simplybuilt: "\f215",
    icon-sink: "\e06d",
    icon-siren-on: "\e02e",
    icon-siren: "\e02d",
    icon-sistrix: "\f3ee",
    icon-sitemap: "\f0e8",
    icon-sith: "\f512",
    icon-sitrox: "\e44a",
    icon-skating: "\f7c5",
    icon-skeleton-ribs: "\e5cb",
    icon-skeleton: "\f620",
    icon-sketch: "\f7c6",
    icon-ski-boot-ski: "\e3cd",
    icon-ski-boot: "\e3cc",
    icon-ski-jump: "\f7c7",
    icon-ski-lift: "\f7c8",
    icon-skiing-nordic: "\f7ca",
    icon-skiing: "\f7c9",
    icon-skull-cow: "\f8de",
    icon-skull-crossbones: "\f714",
    icon-skull: "\f54c",
    icon-skyatlas: "\f216",
    icon-skype: "\f17e",
    icon-slack-hash: "\f198",
    icon-slack: "\f198",
    icon-slash-back: "\5c",
    icon-slash-forward: "\2f",
    icon-slash: "\f715",
    icon-sledding: "\f7cb",
    icon-sleigh: "\f7cc",
    icon-slider: "\e252",
    icon-sliders-h-square: "\f3f0",
    icon-sliders-h: "\f1de",
    icon-sliders-simple: "\e253",
    icon-sliders-up: "\f3f1",
    icon-sliders-v-square: "\f3f2",
    icon-sliders-v: "\f3f1",
    icon-sliders: "\f1de",
    icon-slideshare: "\f1e7",
    icon-slot-machine: "\e3ce",
    icon-smile-beam: "\f5b8",
    icon-smile-plus: "\f5b9",
    icon-smile-wink: "\f4da",
    icon-smile: "\f118",
    icon-smog: "\f75f",
    icon-smoke: "\f760",
    icon-smoking-ban: "\f54d",
    icon-smoking: "\f48d",
    icon-sms: "\f7cd",
    icon-snake: "\f716",
    icon-snapchat-ghost: "\f2ab",
    icon-snapchat-square: "\f2ad",
    icon-snapchat: "\f2ab",
    icon-snooze: "\f880",
    icon-snow-blowing: "\f761",
    icon-snowboarding: "\f7ce",
    icon-snowflake-droplets: "\e5c1",
    icon-snowflake: "\f2dc",
    icon-snowflakes: "\f7cf",
    icon-snowman-head: "\f79b",
    icon-snowman: "\f7d0",
    icon-snowmobile: "\f7d1",
    icon-snowplow: "\f7d2",
    icon-soap: "\e06e",
    icon-soccer-ball: "\f1e3",
    icon-socks: "\f696",
    icon-soft-serve: "\e400",
    icon-solar-panel: "\f5ba",
    icon-solar-system: "\e02f",
    icon-sort-alpha-asc: "\f15d",
    icon-sort-alpha-desc: "\f881",
    icon-sort-alpha-down-alt: "\f881",
    icon-sort-alpha-down: "\f15d",
    icon-sort-alpha-up-alt: "\f882",
    icon-sort-alpha-up: "\f15e",
    icon-sort-alt: "\f883",
    icon-sort-amount-asc: "\f160",
    icon-sort-amount-desc: "\f884",
    icon-sort-amount-down-alt: "\f884",
    icon-sort-amount-down: "\f160",
    icon-sort-amount-up-alt: "\f885",
    icon-sort-amount-up: "\f161",
    icon-sort-asc: "\f0de",
    icon-sort-circle-down: "\e031",
    icon-sort-circle-up: "\e032",
    icon-sort-circle: "\e030",
    icon-sort-desc: "\f0dd",
    icon-sort-down: "\f0dd",
    icon-sort-numeric-asc: "\f162",
    icon-sort-numeric-desc: "\f886",
    icon-sort-numeric-down-alt: "\f886",
    icon-sort-numeric-down: "\f162",
    icon-sort-numeric-up-alt: "\f887",
    icon-sort-numeric-up: "\f163",
    icon-sort-shapes-down-alt: "\f889",
    icon-sort-shapes-down: "\f888",
    icon-sort-shapes-up-alt: "\f88b",
    icon-sort-shapes-up: "\f88a",
    icon-sort-size-down-alt: "\f88d",
    icon-sort-size-down: "\f88c",
    icon-sort-size-up-alt: "\f88f",
    icon-sort-size-up: "\f88e",
    icon-sort-up-down: "\e099",
    icon-sort-up: "\f0de",
    icon-sort: "\f0dc",
    icon-soundcloud: "\f1be",
    icon-soup: "\f823",
    icon-sourcetree: "\f7d3",
    icon-spa: "\f5bb",
    icon-space-awesome: "\e5ac",
    icon-space-shuttle: "\f197",
    icon-space-station-moon-alt: "\e034",
    icon-space-station-moon-construction: "\e034",
    icon-space-station-moon: "\e033",
    icon-spade: "\f2f4",
    icon-spaghetti-monster-flying: "\f67b",
    icon-sparkle: "\e5d6",
    icon-sparkles: "\f890",
    icon-speakap: "\f3f3",
    icon-speaker-deck: "\f83c",
    icon-speaker: "\f8df",
    icon-speakers: "\f8e0",
    icon-spell-check: "\f891",
    icon-spider-black-widow: "\f718",
    icon-spider-web: "\f719",
    icon-spider: "\f717",
    icon-spinner-third: "\f3f4",
    icon-spinner: "\f110",
    icon-split: "\e254",
    icon-splotch: "\f5bc",
    icon-spoon: "\f2e5",
    icon-sportsball: "\e44b",
    icon-spotify: "\f1bc",
    icon-spray-can-sparkles: "\f5d0",
    icon-spray-can: "\f5bd",
    icon-sprinkler-ceiling: "\e44c",
    icon-sprinkler: "\e035",
    icon-sprout: "\f4d8",
    icon-square-0: "\e255",
    icon-square-1: "\e256",
    icon-square-2: "\e257",
    icon-square-3: "\e258",
    icon-square-4: "\e259",
    icon-square-5: "\e25a",
    icon-square-6: "\e25b",
    icon-square-7: "\e25c",
    icon-square-8: "\e25d",
    icon-square-9: "\e25e",
    icon-square-a-lock: "\e44d",
    icon-square-a: "\e25f",
    icon-square-ampersand: "\e260",
    icon-square-arrow-down-left: "\e261",
    icon-square-arrow-down-right: "\e262",
    icon-square-arrow-down: "\f339",
    icon-square-arrow-left: "\f33a",
    icon-square-arrow-right: "\f33b",
    icon-square-arrow-up-left: "\e263",
    icon-square-arrow-up-right: "\f14c",
    icon-square-arrow-up: "\f33c",
    icon-square-b: "\e264",
    icon-square-behance: "\f1b5",
    icon-square-bolt: "\e265",
    icon-square-c: "\e266",
    icon-square-caret-down: "\f150",
    icon-square-caret-left: "\f191",
    icon-square-caret-right: "\f152",
    icon-square-caret-up: "\f151",
    icon-square-check: "\f14a",
    icon-square-chevron-down: "\f329",
    icon-square-chevron-left: "\f32a",
    icon-square-chevron-right: "\f32b",
    icon-square-chevron-up: "\f32c",
    icon-square-code: "\e267",
    icon-square-d: "\e268",
    icon-square-dashed-circle-plus: "\e5c2",
    icon-square-dashed: "\e269",
    icon-square-divide: "\e26a",
    icon-square-dollar: "\f2e9",
    icon-square-down-left: "\e26b",
    icon-square-down-right: "\e26c",
    icon-square-down: "\f350",
    icon-square-dribbble: "\f397",
    icon-square-e: "\e26d",
    icon-square-ellipsis-vertical: "\e26f",
    icon-square-ellipsis: "\e26e",
    icon-square-envelope: "\f199",
    icon-square-exclamation: "\f321",
    icon-square-f: "\e270",
    icon-square-facebook: "\f082",
    icon-square-font-awesome-stroke: "\f35c",
    icon-square-font-awesome: "\e5ad",
    icon-square-fragile: "\f49b",
    icon-square-full: "\f45c",
    icon-square-g: "\e271",
    icon-square-git: "\f1d2",
    icon-square-github: "\f092",
    icon-square-gitlab: "\e5ae",
    icon-square-google-plus: "\f0d4",
    icon-square-h: "\f0fd",
    icon-square-hacker-news: "\f3af",
    icon-square-heart: "\f4c8",
    icon-square-i: "\e272",
    icon-square-info: "\f30f",
    icon-square-instagram: "\e055",
    icon-square-j: "\e273",
    icon-square-js: "\f3b9",
    icon-square-k: "\e274",
    icon-square-kanban: "\e488",
    icon-square-l: "\e275",
    icon-square-lastfm: "\f203",
    icon-square-left: "\f351",
    icon-square-list: "\e489",
    icon-square-m: "\e276",
    icon-square-minus: "\f146",
    icon-square-n: "\e277",
    icon-square-nfi: "\e576",
    icon-square-o: "\e278",
    icon-square-odnoklassniki: "\f264",
    icon-square-p: "\e279",
    icon-square-parking-slash: "\f617",
    icon-square-parking: "\f540",
    icon-square-pen: "\f14b",
    icon-square-person-confined: "\e577",
    icon-square-phone-flip: "\f87b",
    icon-square-phone-hangup: "\e27a",
    icon-square-phone: "\f098",
    icon-square-pied-piper: "\e01e",
    icon-square-pinterest: "\f0d3",
    icon-square-plus: "\f0fe",
    icon-square-poll-horizontal: "\f682",
    icon-square-poll-vertical: "\f681",
    icon-square-q: "\e27b",
    icon-square-quarters: "\e44e",
    icon-square-question: "\f2fd",
    icon-square-quote: "\e329",
    icon-square-r: "\e27c",
    icon-square-reddit: "\f1a2",
    icon-square-right: "\f352",
    icon-square-ring: "\e44f",
    icon-square-root-alt: "\f698",
    icon-square-root-variable: "\f698",
    icon-square-root: "\f697",
    icon-square-rss: "\f143",
    icon-square-s: "\e27d",
    icon-square-share-nodes: "\f1e1",
    icon-square-sliders-vertical: "\f3f2",
    icon-square-sliders: "\f3f0",
    icon-square-small: "\e27e",
    icon-square-snapchat: "\f2ad",
    icon-square-star: "\e27f",
    icon-square-steam: "\f1b7",
    icon-square-t: "\e280",
    icon-square-terminal: "\e32a",
    icon-square-this-way-up: "\f49f",
    icon-square-tumblr: "\f174",
    icon-square-twitter: "\f081",
    icon-square-u: "\e281",
    icon-square-up-left: "\e282",
    icon-square-up-right: "\f360",
    icon-square-up: "\f353",
    icon-square-user: "\e283",
    icon-square-v: "\e284",
    icon-square-viadeo: "\f2aa",
    icon-square-vimeo: "\f194",
    icon-square-virus: "\e578",
    icon-square-w: "\e285",
    icon-square-whatsapp: "\f40c",
    icon-square-wine-glass-crack: "\f49b",
    icon-square-x: "\e286",
    icon-square-xing: "\f169",
    icon-square-xmark: "\f2d3",
    icon-square-y: "\e287",
    icon-square-youtube: "\f431",
    icon-square-z: "\e288",
    icon-square: "\f0c8",
    icon-squarespace: "\f5be",
    icon-squid: "\e450",
    icon-squirrel: "\f71a",
    icon-stack-exchange: "\f18d",
    icon-stack-overflow: "\f16c",
    icon-stackpath: "\f842",
    icon-staff-aesculapius: "\e579",
    icon-staff-snake: "\e579",
    icon-staff: "\f71b",
    icon-stairs: "\e289",
    icon-stamp: "\f5bf",
    icon-standard-definition: "\e28a",
    icon-stapler: "\e5af",
    icon-star-and-crescent: "\f699",
    icon-star-christmas: "\f7d4",
    icon-star-circle: "\e123",
    icon-star-exclamation: "\f2f3",
    icon-star-half-alt: "\f5c0",
    icon-star-half-stroke: "\f5c0",
    icon-star-half: "\f089",
    icon-star-of-david: "\f69a",
    icon-star-of-life: "\f621",
    icon-star-sharp-half-alt: "\e28d",
    icon-star-sharp-half-stroke: "\e28d",
    icon-star-sharp-half: "\e28c",
    icon-star-sharp: "\e28b",
    icon-star-shooting: "\e036",
    icon-star: "\f005",
    icon-starfighter-alt-advanced: "\e28e",
    icon-starfighter-alt: "\e038",
    icon-starfighter-twin-ion-engine-advanced: "\e28e",
    icon-starfighter-twin-ion-engine: "\e038",
    icon-starfighter: "\e037",
    icon-stars: "\f762",
    icon-starship-freighter: "\e03a",
    icon-starship: "\e039",
    icon-staylinked: "\f3f5",
    icon-steak: "\f824",
    icon-steam-square: "\f1b7",
    icon-steam-symbol: "\f3f6",
    icon-steam: "\f1b6",
    icon-steering-wheel: "\f622",
    icon-step-backward: "\f048",
    icon-step-forward: "\f051",
    icon-sterling-sign: "\f154",
    icon-stethoscope: "\f0f1",
    icon-sticker-mule: "\f3f7",
    icon-sticky-note: "\f249",
    icon-stocking: "\f7d5",
    icon-stomach: "\f623",
    icon-stop-circle: "\f28d",
    icon-stop: "\f04d",
    icon-stopwatch-20: "\e06f",
    icon-stopwatch: "\f2f2",
    icon-store-alt-slash: "\e070",
    icon-store-alt: "\f54f",
    icon-store-lock: "\e4a6",
    icon-store-slash: "\e071",
    icon-store: "\f54e",
    icon-strava: "\f428",
    icon-strawberry: "\e32b",
    icon-stream: "\f550",
    icon-street-view: "\f21d",
    icon-stretcher: "\f825",
    icon-strikethrough: "\f0cc",
    icon-stripe-s: "\f42a",
    icon-stripe: "\f429",
    icon-stroopwafel: "\f551",
    icon-stubber: "\e5c7",
    icon-studiovinari: "\f3f8",
    icon-stumbleupon-circle: "\f1a3",
    icon-stumbleupon: "\f1a4",
    icon-subscript: "\f12c",
    icon-subtract: "\f068",
    icon-subway-tunnel: "\e2a3",
    icon-subway: "\f239",
    icon-suitcase-medical: "\f0fa",
    icon-suitcase-rolling: "\f5c1",
    icon-suitcase: "\f0f2",
    icon-sun-alt: "\e28f",
    icon-sun-bright: "\e28f",
    icon-sun-cloud: "\f763",
    icon-sun-dust: "\f764",
    icon-sun-haze: "\f765",
    icon-sun-plant-wilt: "\e57a",
    icon-sun: "\f185",
    icon-sunglasses: "\f892",
    icon-sunrise: "\f766",
    icon-sunset: "\f767",
    icon-superpowers: "\f2dd",
    icon-superscript: "\f12b",
    icon-supple: "\f3f9",
    icon-surprise: "\f5c2",
    icon-suse: "\f7d6",
    icon-sushi-roll: "\e48b",
    icon-sushi: "\e48a",
    icon-swatchbook: "\f5c3",
    icon-swift: "\f8e1",
    icon-swimmer: "\f5c4",
    icon-swimming-pool: "\f5c5",
    icon-sword-laser-alt: "\e03c",
    icon-sword-laser: "\e03b",
    icon-sword: "\f71c",
    icon-swords-laser: "\e03d",
    icon-swords: "\f71d",
    icon-symbols: "\f86e",
    icon-symfony: "\f83d",
    icon-synagogue: "\f69b",
    icon-sync-alt: "\f2f1",
    icon-sync: "\f021",
    icon-syringe: "\f48e",
    icon-t-shirt: "\f553",
    icon-t: "\54",
    icon-table-cells-large: "\f009",
    icon-table-cells: "\f00a",
    icon-table-columns: "\f0db",
    icon-table-layout: "\e290",
    icon-table-list: "\f00b",
    icon-table-picnic: "\e32d",
    icon-table-pivot: "\e291",
    icon-table-rows: "\e292",
    icon-table-tennis-paddle-ball: "\f45d",
    icon-table-tennis: "\f45d",
    icon-table-tree: "\e293",
    icon-table: "\f0ce",
    icon-tablet-alt: "\f3fa",
    icon-tablet-android-alt: "\f3fc",
    icon-tablet-android: "\f3fb",
    icon-tablet-button: "\f10a",
    icon-tablet-rugged: "\f48f",
    icon-tablet-screen-button: "\f3fa",
    icon-tablet-screen: "\f3fc",
    icon-tablet: "\f3fb",
    icon-tablets: "\f490",
    icon-tachograph-digital: "\f566",
    icon-tachometer-alt-average: "\f624",
    icon-tachometer-alt-fast: "\f625",
    icon-tachometer-alt-fastest: "\f626",
    icon-tachometer-alt-slow: "\f627",
    icon-tachometer-alt-slowest: "\f628",
    icon-tachometer-alt: "\f625",
    icon-tachometer-average: "\f629",
    icon-tachometer-fast: "\f62a",
    icon-tachometer-fastest: "\f62b",
    icon-tachometer-slow: "\f62c",
    icon-tachometer-slowest: "\f62d",
    icon-tachometer: "\f62a",
    icon-taco: "\f826",
    icon-tag: "\f02b",
    icon-tags: "\f02c",
    icon-tally-1: "\e294",
    icon-tally-2: "\e295",
    icon-tally-3: "\e296",
    icon-tally-4: "\e297",
    icon-tally-5: "\f69c",
    icon-tally: "\f69c",
    icon-tamale: "\e451",
    icon-tanakh: "\f827",
    icon-tank-water: "\e452",
    icon-tape: "\f4db",
    icon-tarp-droplet: "\e57c",
    icon-tarp: "\e57b",
    icon-tasks-alt: "\f828",
    icon-tasks: "\f0ae",
    icon-taxi-bus: "\e298",
    icon-taxi: "\f1ba",
    icon-teamspeak: "\f4f9",
    icon-teddy-bear: "\e3cf",
    icon-teeth-open: "\f62f",
    icon-teeth: "\f62e",
    icon-telegram-plane: "\f2c6",
    icon-telegram: "\f2c6",
    icon-telescope: "\e03e",
    icon-teletype-answer: "\e2b9",
    icon-teletype: "\f1e4",
    icon-television: "\f26c",
    icon-temperature-0: "\f2cb",
    icon-temperature-1: "\f2ca",
    icon-temperature-2: "\f2c9",
    icon-temperature-3: "\f2c8",
    icon-temperature-4: "\f2c7",
    icon-temperature-arrow-down: "\e03f",
    icon-temperature-arrow-up: "\e040",
    icon-temperature-down: "\e03f",
    icon-temperature-empty: "\f2cb",
    icon-temperature-frigid: "\f768",
    icon-temperature-full: "\f2c7",
    icon-temperature-half: "\f2c9",
    icon-temperature-high: "\f769",
    icon-temperature-hot: "\f76a",
    icon-temperature-list: "\e299",
    icon-temperature-low: "\f76b",
    icon-temperature-quarter: "\f2ca",
    icon-temperature-snow: "\f768",
    icon-temperature-sun: "\f76a",
    icon-temperature-three-quarters: "\f2c8",
    icon-temperature-up: "\e040",
    icon-tencent-weibo: "\f1d5",
    icon-tenge-sign: "\f7d7",
    icon-tenge: "\f7d7",
    icon-tennis-ball: "\f45e",
    icon-tent-arrow-down-to-line: "\e57e",
    icon-tent-arrow-left-right: "\e57f",
    icon-tent-arrow-turn-left: "\e580",
    icon-tent-arrows-down: "\e581",
    icon-tent: "\e57d",
    icon-tents: "\e582",
    icon-terminal: "\f120",
    icon-text-height: "\f034",
    icon-text-size: "\f894",
    icon-text-slash: "\f87d",
    icon-text-width: "\f035",
    icon-text: "\f893",
    icon-th-large: "\f009",
    icon-th-list: "\f00b",
    icon-th: "\f00a",
    icon-the-red-yeti: "\f69d",
    icon-theater-masks: "\f630",
    icon-themeco: "\f5c6",
    icon-themeisle: "\f2b2",
    icon-thermometer-0: "\f2cb",
    icon-thermometer-1: "\f2ca",
    icon-thermometer-2: "\f2c9",
    icon-thermometer-3: "\f2c8",
    icon-thermometer-4: "\f2c7",
    icon-thermometer-empty: "\f2cb",
    icon-thermometer-full: "\f2c7",
    icon-thermometer-half: "\f2c9",
    icon-thermometer-quarter: "\f2ca",
    icon-thermometer-three-quarters: "\f2c8",
    icon-thermometer: "\f491",
    icon-theta: "\f69e",
    icon-think-peaks: "\f731",
    icon-thought-bubble: "\e32e",
    icon-thumb-tack: "\f08d",
    icon-thumbs-down: "\f165",
    icon-thumbs-up: "\f164",
    icon-thumbtack: "\f08d",
    icon-thunderstorm-moon: "\f76d",
    icon-thunderstorm-sun: "\f76e",
    icon-thunderstorm: "\f76c",
    icon-tick: "\e32f",
    icon-ticket-airline: "\e29a",
    icon-ticket-alt: "\f3ff",
    icon-ticket-simple: "\f3ff",
    icon-ticket: "\f145",
    icon-tickets-airline: "\e29b",
    icon-tiktok: "\e07b",
    icon-tilde: "\7e",
    icon-timeline-arrow: "\e29d",
    icon-timeline: "\e29c",
    icon-timer: "\e29e",
    icon-times-circle: "\f057",
    icon-times-hexagon: "\f2ee",
    icon-times-octagon: "\f2f0",
    icon-times-rectangle: "\f410",
    icon-times-square: "\f2d3",
    icon-times-to-slot: "\f771",
    icon-times: "\f00d",
    icon-tint-slash: "\f5c7",
    icon-tint: "\f043",
    icon-tire-flat: "\f632",
    icon-tire-pressure-warning: "\f633",
    icon-tire-rugged: "\f634",
    icon-tire: "\f631",
    icon-tired: "\f5c8",
    icon-toggle-large-off: "\e5b0",
    icon-toggle-large-on: "\e5b1",
    icon-toggle-off: "\f204",
    icon-toggle-on: "\f205",
    icon-toilet-paper-alt: "\f71f",
    icon-toilet-paper-blank-under: "\e29f",
    icon-toilet-paper-blank: "\f71f",
    icon-toilet-paper-check: "\e5b2",
    icon-toilet-paper-reverse-alt: "\e29f",
    icon-toilet-paper-reverse-slash: "\e2a1",
    icon-toilet-paper-reverse: "\e2a0",
    icon-toilet-paper-slash: "\e072",
    icon-toilet-paper-under-slash: "\e2a1",
    icon-toilet-paper-under: "\e2a0",
    icon-toilet-paper-xmark: "\e5b3",
    icon-toilet-paper: "\f71e",
    icon-toilet-portable: "\e583",
    icon-toilet: "\f7d8",
    icon-toilets-portable: "\e584",
    icon-tomato: "\e330",
    icon-tombstone-alt: "\f721",
    icon-tombstone-blank: "\f721",
    icon-tombstone: "\f720",
    icon-toolbox: "\f552",
    icon-tools: "\f7d9",
    icon-tooth: "\f5c9",
    icon-toothbrush: "\f635",
    icon-torah: "\f6a0",
    icon-torii-gate: "\f6a1",
    icon-tornado: "\f76f",
    icon-tower-broadcast: "\f519",
    icon-tower-cell: "\e585",
    icon-tower-control: "\e2a2",
    icon-tower-observation: "\e586",
    icon-tractor: "\f722",
    icon-trade-federation: "\f513",
    icon-trademark: "\f25c",
    icon-traffic-cone: "\f636",
    icon-traffic-light-go: "\f638",
    icon-traffic-light-slow: "\f639",
    icon-traffic-light-stop: "\f63a",
    icon-traffic-light: "\f637",
    icon-trailer: "\e041",
    icon-train-subway-tunnel: "\e2a3",
    icon-train-subway: "\f239",
    icon-train-track: "\e453",
    icon-train-tram: "\e5b4",
    icon-train-tunnel: "\e454",
    icon-train: "\f238",
    icon-tram: "\f7da",
    icon-transformer-bolt: "\e2a4",
    icon-transgender-alt: "\f225",
    icon-transgender: "\f225",
    icon-transporter-1: "\e043",
    icon-transporter-2: "\e044",
    icon-transporter-3: "\e045",
    icon-transporter-4: "\e2a5",
    icon-transporter-5: "\e2a6",
    icon-transporter-6: "\e2a7",
    icon-transporter-7: "\e2a8",
    icon-transporter-empty: "\e046",
    icon-transporter: "\e042",
    icon-trash-alt-slash: "\e2ad",
    icon-trash-alt: "\f2ed",
    icon-trash-arrow-turn-left: "\f895",
    icon-trash-arrow-up: "\f829",
    icon-trash-can-arrow-turn-left: "\f896",
    icon-trash-can-arrow-up: "\f82a",
    icon-trash-can-check: "\e2a9",
    icon-trash-can-clock: "\e2aa",
    icon-trash-can-list: "\e2ab",
    icon-trash-can-plus: "\e2ac",
    icon-trash-can-slash: "\e2ad",
    icon-trash-can-undo: "\f896",
    icon-trash-can-xmark: "\e2ae",
    icon-trash-can: "\f2ed",
    icon-trash-check: "\e2af",
    icon-trash-circle: "\e126",
    icon-trash-clock: "\e2b0",
    icon-trash-list: "\e2b1",
    icon-trash-plus: "\e2b2",
    icon-trash-restore-alt: "\f82a",
    icon-trash-restore: "\f829",
    icon-trash-slash: "\e2b3",
    icon-trash-undo-alt: "\f896",
    icon-trash-undo: "\f895",
    icon-trash-xmark: "\e2b4",
    icon-trash: "\f1f8",
    icon-treasure-chest: "\f723",
    icon-tree-alt: "\f400",
    icon-tree-christmas: "\f7db",
    icon-tree-city: "\e587",
    icon-tree-deciduous: "\f400",
    icon-tree-decorated: "\f7dc",
    icon-tree-large: "\f7dd",
    icon-tree-palm: "\f82b",
    icon-tree: "\f1bb",
    icon-trees: "\f724",
    icon-trello: "\f181",
    icon-trian-balbot: "\e45c",
    icon-triangle-circle-square: "\f61f",
    icon-triangle-exclamation: "\f071",
    icon-triangle-instrument: "\f8e2",
    icon-triangle-music: "\f8e2",
    icon-triangle-person-digging: "\f85d",
    icon-triangle: "\f2ec",
    icon-tricycle-adult: "\e5c4",
    icon-tricycle: "\e5c3",
    icon-trillium: "\e588",
    icon-trophy-alt: "\f2eb",
    icon-trophy-star: "\f2eb",
    icon-trophy: "\f091",
    icon-trowel-bricks: "\e58a",
    icon-trowel: "\e589",
    icon-truck-arrow-right: "\e58b",
    icon-truck-bolt: "\e3d0",
    icon-truck-clock: "\f48c",
    icon-truck-container-empty: "\e2b5",
    icon-truck-container: "\f4dc",
    icon-truck-couch: "\f4dd",
    icon-truck-droplet: "\e58c",
    icon-truck-fast: "\f48b",
    icon-truck-field-un: "\e58e",
    icon-truck-field: "\e58d",
    icon-truck-flatbed: "\e2b6",
    icon-truck-front: "\e2b7",
    icon-truck-loading: "\f4de",
    icon-truck-medical: "\f0f9",
    icon-truck-monster: "\f63b",
    icon-truck-moving: "\f4df",
    icon-truck-pickup: "\f63c",
    icon-truck-plane: "\e58f",
    icon-truck-plow: "\f7de",
    icon-truck-ramp-box: "\f4de",
    icon-truck-ramp-couch: "\f4dd",
    icon-truck-ramp: "\f4e0",
    icon-truck-tow: "\e2b8",
    icon-truck: "\f0d1",
    icon-trumpet: "\f8e3",
    icon-try: "\e2bb",
    icon-tshirt: "\f553",
    icon-tty-answer: "\e2b9",
    icon-tty: "\f1e4",
    icon-tugrik-sign: "\e2ba",
    icon-tumblr-square: "\f174",
    icon-tumblr: "\f173",
    icon-turkey: "\f725",
    icon-turkish-lira-sign: "\e2bb",
    icon-turkish-lira: "\e2bb",
    icon-turn-down-left: "\e331",
    icon-turn-down-right: "\e455",
    icon-turn-down: "\f3be",
    icon-turn-up: "\f3bf",
    icon-turntable: "\f8e4",
    icon-turtle: "\f726",
    icon-tv-alt: "\f26c",
    icon-tv-music: "\f8e6",
    icon-tv-retro: "\f401",
    icon-tv: "\f26c",
    icon-twitch: "\f1e8",
    icon-twitter-square: "\f081",
    icon-twitter: "\f099",
    icon-typewriter: "\f8e7",
    icon-typo3: "\f42b",
    icon-u: "\55",
    icon-uber: "\f402",
    icon-ubuntu: "\f7df",
    icon-ufo-beam: "\e048",
    icon-ufo: "\e047",
    icon-uikit: "\f403",
    icon-umbraco: "\f8e8",
    icon-umbrella-alt: "\e2bc",
    icon-umbrella-beach: "\f5ca",
    icon-umbrella-simple: "\e2bc",
    icon-umbrella: "\f0e9",
    icon-uncharted: "\e084",
    icon-underline: "\f0cd",
    icon-undo-alt: "\f2ea",
    icon-undo: "\f0e2",
    icon-unicorn: "\f727",
    icon-uniform-martial-arts: "\e3d1",
    icon-union: "\f6a2",
    icon-uniregistry: "\f404",
    icon-unity: "\e049",
    icon-universal-access: "\f29a",
    icon-university: "\f19c",
    icon-unlink: "\f127",
    icon-unlock-alt: "\f13e",
    icon-unlock-keyhole: "\f13e",
    icon-unlock: "\f09c",
    icon-unsorted: "\f0dc",
    icon-unsplash: "\e07c",
    icon-untappd: "\f405",
    icon-up-down-left-right: "\f0b2",
    icon-up-down: "\f338",
    icon-up-from-bracket: "\e590",
    icon-up-from-dotted-line: "\e456",
    icon-up-from-line: "\f346",
    icon-up-left: "\e2bd",
    icon-up-long: "\f30c",
    icon-up-right-and-down-left-from-center: "\f424",
    icon-up-right-from-square: "\f35d",
    icon-up-right: "\e2be",
    icon-up-to-dotted-line: "\e457",
    icon-up-to-line: "\f34d",
    icon-up: "\f357",
    icon-upload: "\f093",
    icon-ups: "\f7e0",
    icon-usb-drive: "\f8e9",
    icon-usb: "\f287",
    icon-usd-circle: "\f2e8",
    icon-usd-square: "\f2e9",
    icon-usd: "\24",
    icon-user-alien: "\e04a",
    icon-user-alt-slash: "\f4fa",
    icon-user-alt: "\f406",
    icon-user-astronaut: "\f4fb",
    icon-user-bounty-hunter: "\e2bf",
    icon-user-chart: "\f6a3",
    icon-user-check: "\f4fc",
    icon-user-chef: "\e3d2",
    icon-user-circle: "\f2bd",
    icon-user-clock: "\f4fd",
    icon-user-cog: "\f4fe",
    icon-user-construction: "\f82c",
    icon-user-cowboy: "\f8ea",
    icon-user-crown: "\f6a4",
    icon-user-doctor-hair-long: "\e459",
    icon-user-doctor-hair: "\e458",
    icon-user-doctor-message: "\f82e",
    icon-user-doctor: "\f0f0",
    icon-user-edit: "\f4ff",
    icon-user-friends: "\f500",
    icon-user-gear: "\f4fe",
    icon-user-graduate: "\f501",
    icon-user-group-crown: "\f6a5",
    icon-user-group: "\f500",
    icon-user-hair-buns: "\e3d3",
    icon-user-hair-long: "\e45b",
    icon-user-hair-mullet: "\e45c",
    icon-user-hair: "\e45a",
    icon-user-hard-hat: "\f82c",
    icon-user-headset: "\f82d",
    icon-user-helmet-safety: "\f82c",
    icon-user-injured: "\f728",
    icon-user-large-slash: "\f4fa",
    icon-user-large: "\f406",
    icon-user-lock: "\f502",
    icon-user-magnifying-glass: "\e5c5",
    icon-user-md-chat: "\f82e",
    icon-user-md: "\f0f0",
    icon-user-minus: "\f503",
    icon-user-music: "\f8eb",
    icon-user-ninja: "\f504",
    icon-user-nurse-hair-long: "\e45e",
    icon-user-nurse-hair: "\e45d",
    icon-user-nurse: "\f82f",
    icon-user-pen: "\f4ff",
    icon-user-pilot-tie: "\e2c1",
    icon-user-pilot: "\e2c0",
    icon-user-plus: "\f234",
    icon-user-police-tie: "\e334",
    icon-user-police: "\e333",
    icon-user-robot-xmarks: "\e4a7",
    icon-user-robot: "\e04b",
    icon-user-secret: "\f21b",
    icon-user-shakespeare: "\e2c2",
    icon-user-shield: "\f505",
    icon-user-slash: "\f506",
    icon-user-tag: "\f507",
    icon-user-tie-hair-long: "\e460",
    icon-user-tie-hair: "\e45f",
    icon-user-tie: "\f508",
    icon-user-times: "\f235",
    icon-user-unlock: "\e058",
    icon-user-visor: "\e04c",
    icon-user-vneck-hair-long: "\e463",
    icon-user-vneck-hair: "\e462",
    icon-user-vneck: "\e461",
    icon-user-xmark: "\f235",
    icon-user: "\f007",
    icon-users-between-lines: "\e591",
    icon-users-class: "\f63d",
    icon-users-cog: "\f509",
    icon-users-crown: "\f6a5",
    icon-users-gear: "\f509",
    icon-users-line: "\e592",
    icon-users-medical: "\f830",
    icon-users-rays: "\e593",
    icon-users-rectangle: "\e594",
    icon-users-slash: "\e073",
    icon-users-viewfinder: "\e595",
    icon-users: "\f0c0",
    icon-groups: "\e594",
    icon-usps: "\f7e1",
    icon-ussunnah: "\f407",
    icon-utensil-fork: "\f2e3",
    icon-utensil-knife: "\f2e4",
    icon-utensil-spoon: "\f2e5",
    icon-utensils-alt: "\f2e6",
    icon-utensils-slash: "\e464",
    icon-utensils: "\f2e7",
    icon-utility-pole-double: "\e2c4",
    icon-utility-pole: "\e2c3",
    icon-v: "\56",
    icon-vaadin: "\f408",
    icon-vacuum-robot: "\e04e",
    icon-vacuum: "\e04d",
    icon-value-absolute: "\f6a6",
    icon-van-shuttle: "\f5b6",
    icon-vault: "\e2c5",
    icon-vcard: "\f2bb",
    icon-vector-circle: "\e2c6",
    icon-vector-polygon: "\e2c7",
    icon-vector-square: "\f5cb",
    icon-vent-damper: "\e465",
    icon-venus-double: "\f226",
    icon-venus-mars: "\f228",
    icon-venus: "\f221",
    icon-vest-patches: "\e086",
    icon-vest: "\e085",
    icon-vhs: "\f8ec",
    icon-viacoin: "\f237",
    icon-viadeo-square: "\f2aa",
    icon-viadeo: "\f2a9",
    icon-vial-circle-check: "\e596",
    icon-vial-virus: "\e597",
    icon-vial: "\f492",
    icon-vials: "\f493",
    icon-viber: "\f409",
    icon-video-arrow-down-left: "\e2c8",
    icon-video-arrow-up-right: "\e2c9",
    icon-video-camera: "\f03d",
    icon-video-circle: "\e12b",
    icon-video-handheld: "\f8a8",
    icon-video-plus: "\f4e1",
    icon-video-slash: "\f4e2",
    icon-video: "\f03d",
    icon-vihara: "\f6a7",
    icon-vimeo-square: "\f194",
    icon-vimeo-v: "\f27d",
    icon-vimeo: "\f40a",
    icon-vine: "\f1ca",
    icon-violin: "\f8ed",
    icon-virus-covid-slash: "\e4a9",
    icon-virus-covid: "\e4a8",
    icon-virus-slash: "\e075",
    icon-virus: "\e074",
    icon-viruses: "\e076",
    icon-vk: "\f189",
    icon-vnv: "\f40b",
    icon-voicemail: "\f897",
    icon-volcano: "\f770",
    icon-volleyball-ball: "\f45f",
    icon-volleyball: "\f45f",
    icon-volume-control-phone: "\f2a0",
    icon-volume-down: "\f027",
    icon-volume-high: "\f028",
    icon-volume-low: "\f027",
    icon-volume-medium: "\f6a8",
    icon-volume-mute: "\f6a9",
    icon-volume-off: "\f026",
    icon-volume-slash: "\f2e2",
    icon-volume-times: "\f6a9",
    icon-volume-up: "\f028",
    icon-volume-xmark: "\f6a9",
    icon-volume: "\f6a8",
    icon-vote-nay: "\f771",
    icon-vote-yea: "\f772",
    icon-vr-cardboard: "\f729",
    icon-vuejs: "\f41f",
    icon-w: "\57",
    icon-waffle: "\e466",
    icon-wagon-covered: "\f8ee",
    icon-walker: "\f831",
    icon-walkie-talkie: "\f8ef",
    icon-walking: "\f554",
    icon-wall-brick: "\e3db",
    icon-wallet: "\f555",
    icon-wand-magic-sparkles: "\e2ca",
    icon-wand-magic: "\f0d0",
    icon-wand-sparkles: "\f72b",
    icon-wand: "\f72a",
    icon-warehouse-alt: "\f495",
    icon-warehouse-full: "\f495",
    icon-warehouse: "\f494",
    icon-warning: "\f071",
    icon-washer: "\f898",
    icon-washing-machine: "\f898",
    icon-watch-apple: "\e2cb",
    icon-watch-calculator: "\f8f0",
    icon-watch-fitness: "\f63e",
    icon-watch-smart: "\e2cc",
    icon-watch: "\f2e1",
    icon-watchman-monitoring: "\e087",
    icon-water-arrow-down: "\f774",
    icon-water-arrow-up: "\f775",
    icon-water-ladder: "\f5c5",
    icon-water-lower: "\f774",
    icon-water-rise: "\f775",
    icon-water: "\f773",
    icon-watermelon-slice: "\e337",
    icon-wave-pulse: "\f5f8",
    icon-wave-sine: "\f899",
    icon-wave-square: "\f83e",
    icon-wave-triangle: "\f89a",
    icon-waveform-circle: "\e12d",
    icon-waveform-lines: "\f8f2",
    icon-waveform-path: "\f8f2",
    icon-waveform: "\f8f1",
    icon-waze: "\f83f",
    icon-webcam-slash: "\f833",
    icon-webcam: "\f832",
    icon-webhook: "\e5d5",
    icon-weebly: "\f5cc",
    icon-weibo: "\f18a",
    icon-weight-hanging: "\f5cd",
    icon-weight-scale: "\f496",
    icon-weight: "\f496",
    icon-weixin: "\f1d7",
    icon-whale: "\f72c",
    icon-whatsapp-square: "\f40c",
    icon-whatsapp: "\f232",
    icon-wheat-alt: "\e2cd",
    icon-wheat-awn-circle-exclamation: "\e598",
    icon-wheat-awn-slash: "\e338",
    icon-wheat-awn: "\e2cd",
    icon-wheat-slash: "\e339",
    icon-wheat: "\f72d",
    icon-wheelchair-alt: "\e2ce",
    icon-wheelchair-move: "\e2ce",
    icon-wheelchair: "\f193",
    icon-whiskey-glass-ice: "\f7a1",
    icon-whiskey-glass: "\f7a0",
    icon-whistle: "\f460",
    icon-whmcs: "\f40d",
    icon-wifi-1: "\f6aa",
    icon-wifi-2: "\f6ab",
    icon-wifi-3: "\f1eb",
    icon-wifi-exclamation: "\e2cf",
    icon-wifi-fair: "\f6ab",
    icon-wifi-slash: "\f6ac",
    icon-wifi-strong: "\f1eb",
    icon-wifi-weak: "\f6aa",
    icon-wifi: "\f1eb",
    icon-wikipedia-w: "\f266",
    icon-wind-circle-exclamation: "\f776",
    icon-wind-turbine: "\f89b",
    icon-wind-warning: "\f776",
    icon-wind: "\f72e",
    icon-window-alt: "\f40f",
    icon-window-close: "\f410",
    icon-window-flip: "\f40f",
    icon-window-frame-open: "\e050",
    icon-window-frame: "\e04f",
    icon-window-maximize: "\f2d0",
    icon-window-minimize: "\f2d1",
    icon-window-restore: "\f2d2",
    icon-window: "\f40e",
    icon-windows: "\f17a",
    icon-windsock: "\f777",
    icon-wine-bottle: "\f72f",
    icon-wine-glass-alt: "\f5ce",
    icon-wine-glass-crack: "\f4bb",
    icon-wine-glass-empty: "\f5ce",
    icon-wine-glass: "\f4e3",
    icon-wirsindhandwerk: "\e2d0",
    icon-wix: "\f5cf",
    icon-wizards-of-the-coast: "\f730",
    icon-wodu: "\e088",
    icon-wolf-pack-battalion: "\f514",
    icon-won-sign: "\f159",
    icon-won: "\f159",
    icon-wordpress-simple: "\f411",
    icon-wordpress: "\f19a",
    icon-worm: "\e599",
    icon-wpbeginner: "\f297",
    icon-wpexplorer: "\f2de",
    icon-wpforms: "\f298",
    icon-wpressr: "\f3e4",
    icon-wreath-laurel: "\e5d2",
    icon-wreath: "\f7e2",
    icon-wrench-simple: "\e2d1",
    icon-wrench: "\f0ad",
    icon-wsh: "\e2d0",
    icon-x-ray: "\f497",
    icon-x: "\58",
    icon-xbox: "\f412",
    icon-xing-square: "\f169",
    icon-xing: "\f168",
    icon-xmark-circle: "\f057",
    icon-xmark-hexagon: "\f2ee",
    icon-xmark-large: "\e59b",
    icon-xmark-octagon: "\f2f0",
    icon-xmark-square: "\f2d3",
    icon-xmark-to-slot: "\f771",
    icon-xmark: "\f00d",
    icon-xmarks-lines: "\e59a",
    icon-y-combinator: "\f23b",
    icon-y: "\59",
    icon-yahoo: "\f19e",
    icon-yammer: "\f840",
    icon-yandex-international: "\f414",
    icon-yandex: "\f413",
    icon-yarn: "\f7e3",
    icon-yelp: "\f1e9",
    icon-yen-sign: "\f157",
    icon-yen: "\f157",
    icon-yin-yang: "\f6ad",
    icon-yoast: "\f2b1",
    icon-youtube-square: "\f431",
    icon-youtube: "\f167",
    icon-z: "\5a",
    icon-zap: "\f0e7",
    icon-zhihu: "\f63f",
    icon-zzz: "\f880",
);
/* stylelint-enable unit-disallowed-list */
