@mixin plate {
    filter: drop-shadow(0 0.4rem 1rem rgb(0 0 0 / 0.04));
}

@mixin bottom-plate {
    filter: drop-shadow(0 -0.4rem 1rem rgb(0 0 0 / 0.06));
}

@mixin button {
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 0.06);
}

@mixin shadow-plate {
    box-shadow: 0 0.1rem 1.1rem 0 rgba(0 0 0 / 0.08);
}
